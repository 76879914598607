import React, {useEffect, useState} from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box, Flex, VStack, HStack, Link, PinInput, PinInputField, useToast,
} from "@chakra-ui/react";
import {handleCheckVerificationCode, handleSignUp} from "../../../utils/auth-helpers";
import {useAuth} from "../../../redux/AuthProvider";
import {maxOutUsage} from "../../../utils/redux-helpers";
import {useTranslation} from "react-i18next";

const SignUpScreen2 = ({ isOpen, onClose, email, firstName, lastName }) => {
    const toast = useToast();
    const [verificationCode, setVerificationCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [isResendLoading, setIsResendLoading] = useState(false);
    const auth = useAuth();
    const {t} = useTranslation();

    const resendCode = async () => {
        setIsResendLoading(true)
        const username = email
        await handleSignUp(email.toLowerCase(), username, firstName, lastName).then((response) => {
            setIsResendLoading(false)
            setIsResendDisabled(true)
            if (response === null) {
                toast({
                    title: t("Oops, something went wrong. Please try again."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 400) {
                toast({
                    title: t("Email already exists. Please try again."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 200) {
                toast({
                    title: t("Code resent! Please check your email."),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
            }
        });
    }

    const checkVerificationCode = async () => {
        setIsLoading(true)
        await handleCheckVerificationCode(email.toString().toLowerCase(), verificationCode.split('').join(' '), "signup").then((response) => {
            setIsLoading(false)
            if (response === null) {
                toast({
                    title: t("Oops, something went wrong. Please try again."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 401) {
                toast({
                    title: t("Incorrect verification code. Please try again."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 200) {
                window.gtag('event', 'sign_up', {
                    'event_category': 'Sign Up',
                    'event_label': 'Sign Up Method',
                    'value': 'success'
                });

                //set local storage userID equal to response.userID
                localStorage.setItem('email', response.email.toString());
                localStorage.setItem('firstName', response.firstName.toString());
                localStorage.setItem('lastName', response.lastName.toString());
                auth.login(response.userID);
                auth.setIsLoggedIn(true);
                setVerificationCode('')
                onClose();

                const wasUploadExceeded = localStorage.getItem('isUploadExceeded') ?
                    JSON.parse(localStorage.getItem('isUploadExceeded')) : null;
                const wasChatExceeded = localStorage.getItem('isChatExceeded') ?
                    JSON.parse(localStorage.getItem('isChatExceeded')) : null;

                let keysToMax = [];
                if (wasUploadExceeded) {
                    keysToMax.push("pdf_uploads");
                }
                if (wasChatExceeded) {
                    keysToMax.push("chats");
                }
                if (keysToMax.length > 0) {
                    maxOutUsage(response.userID, keysToMax).then(r => {
                        if (r.status === 200) {
                            console.log('maxed out usage success');
                        }
                    });
                }

            }
        });
    };

    useEffect(() => {
        if (verificationCode.length === 5) {
            checkVerificationCode().then(r => {});
        }
    }, [verificationCode]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
            <ModalOverlay />
            <ModalContent borderRadius="1rem" p="0.75rem" h="30.5rem" w="26rem" overflowY="auto" position="relative">
                <Box
                    position="absolute"
                    top="10px"
                    right="10px"
                    zIndex={999}
                >
                    <CloseButton
                        size="md"
                        onClick={onClose}
                        bg="transparent"
                        borderRadius="50%"
                        _hover={{
                            bg: "gray.200",
                            borderRadius: "50%",
                        }}
                    />
                </Box>
                <Flex alignItems="center" justifyContent="space-between" p={4} >
                    <VStack align="center" width={'100%'} spacing={4}>
                        <Text fontSize="xl" fontWeight="bold">{t("Enter Verification Code")}</Text>
                        <Box w={'full'} h={'1px'} bg={'gray.300'} />
                        <VStack align="center" width={'100%'} spacing={3}>
                            <Text fontSize="sm" fontWeight="normal" textAlign={"center"}>{t("check your email:")} {email}</Text>
                            <Text fontSize="xs" fontWeight="normal" textAlign={"center"}>{t("(it might be in spam)")}</Text>
                        </VStack>
                        <Button
                            colorScheme="blue"
                            variant="link"
                            isLoading={isResendLoading}
                            loadingText={"Resending..."}
                            isDisabled={isResendDisabled}
                            fontSize="sm"
                            fontWeight="normal"
                            textAlign={"center"}
                            onClick={resendCode}
                        >
                            {t("Resend code")}
                        </Button>
                    </VStack>
                </Flex>
                <ModalBody>
                    <Stack spacing={4}>
                        <HStack my={'3rem'} justifyContent={'center'}>
                            <PinInput size='lg' autoFocus={true} value={verificationCode} onChange={(value)=>{
                                setVerificationCode(value);
                            }}>
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                            </PinInput>
                        </HStack>
                        <Stack spacing={10} pt={2}>
                            <Button
                                onClick={() => {
                                    checkVerificationCode().then(r => {});
                                }}
                                loadingText="Submitting"
                                isLoading={isLoading}
                                size="lg"
                                bg={'orange.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'orange.500',
                                }}>
                                {t("Submit")}
                            </Button>
                        </Stack>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default SignUpScreen2;
