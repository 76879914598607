import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import {useSelector} from "react-redux";
import Navbar from "../components/TopNavbar/Navbar";
import {useAuth} from "../redux/AuthProvider";
import SidePanelDrawer from "../components/TopNavbar/SidePanelDrawer";
import LargeWithNewsletter from "../components/Reuseable/LargeWithNewsletter";

const FullScreenTemplate = ({ children }) => {
    const isFullScreen = useSelector(state => state.settings.isFullScreen)
    const sidebarWidth = useSelector(state => state.sidePanel.sidebarWidth)
    const isSidePanelOpen = useSelector(state => state.sidePanel.isSidePanelOpen)
    const auth = useAuth();

    return (
        <Box>
            {!isFullScreen && isSidePanelOpen && (
                <SidePanelDrawer />
            )}
            {!isFullScreen && (
                <Navbar
                    profileImage="https://bit.ly/sage-adebayo"
                    firstName={localStorage.getItem("firstName")}
                    lastName={localStorage.getItem("lastName")}
                />
            )}
            {!isFullScreen && (
                <Flex borderBottomWidth={0} position="relative" width='100%' px={'0.8rem'}
                      as='header' backgroundColor={'white'}
                      height='4rem' alignItems={"center"}>
                </Flex>
            )}
            <Box
                backgroundColor={"white"}
                width="100vw"
                height={!isFullScreen ? "calc(100vh - 7rem)" : "calc(100vh - 3rem)"}
            >
                <Flex direction="column" align="center" bg={'white'} height={"100%"} width={"100%"}>
                    {children}
                </Flex>
            </Box>
        </Box>
    );
};

export default FullScreenTemplate;
