import axios from "axios";
import {SERVER_IP} from "./constants";

export const createCheckoutSession = async (userID, userEmail) => {


    let requestBody = {};
    // console.log('Rewardful referral outside if', window.Rewardful)
    if (window.Rewardful !== undefined && window.Rewardful !== null
        && window.Rewardful.referral !== undefined && window.Rewardful.referral !== null
        && window.Rewardful.referral !== "") {
        console.log('Rewardful referral', window.Rewardful.referral)
        requestBody = {
            "user_id" : userID,
            "user_email" : userEmail,
            "client_reference_id" : window.Rewardful.referral
        }
    } else {
        requestBody = {
            "user_id" : userID,
            "user_email" : userEmail,
        }
    }
    console.log("/subscription/create-checkout-session body", requestBody)

    try {
        const response = await axios.post(SERVER_IP + '/subscription/create-checkout-session', requestBody);
        console.log("response for /subscription/create-checkout-session", response)

        if (response.data.status === 200) {
            return {
                "status" : 200,
                "url" : response.data.message.url,
            }
        }

    } catch (error) {
        console.log("/subscription/create-checkout-session error", error);
        return null;
    }
}

