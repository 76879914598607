import React, {useEffect, useState, useRef, useCallback} from 'react';
import {
    Box,
    Button,
    useToast,
    VStack,
    Text,
    useDisclosure,
    Stack,
    Heading,
    Flex,
    Container,
    useColorModeValue,
    Icon,
    useBreakpointValue,
    InputGroup,
    Tooltip,
    Image,
    InputLeftAddon,
    InputRightAddon,
    Input,
    HStack,
    Badge,
    Link,
    SimpleGrid,
    Grid,
    List,
    ListItem,
    ListIcon,
    Divider,
    GridItem,
} from '@chakra-ui/react';
import axios from 'axios';
import DocumentGrid from './DocumentGrid';
import {createThumbnail} from '../../utils/pdf-page-helpers';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../redux/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import {
    addDocument, removeDocument, setCurrentChatInput,
    setDocuments, setIsPricingModalOpen,
    setIsUploadLoading, setScanError, setScanEstimatedSeconds, setScanFileID
} from '../../redux/reducers';
import { addDocumentInStorage, getDocumentsInStorage } from '../../utils/local-storage-helpers';
import {influencerMap, SERVER_IP} from "../../utils/constants";
import {useDropzone} from "react-dropzone";
import { motion } from 'framer-motion';
import SignUpScreen1 from "../../components/Modals/SignUp/SignUpScreen1";
import SignInScreen1 from "../../components/Modals/SignIn/SignInScreen1";
import LargeWithNewsletter from "../../components/Reuseable/LargeWithNewsletter";
import LoadingBar from "./LoadingBar";
import FullScreenTemplateHomePage from "../../templates/FullScreenTemplateHomePage";
import {
    FcAddImage,
    FcDocument, FcLock, FcMultipleInputs, FcQuestions, FcReading, FcSportsMode, FcViewDetails,
} from 'react-icons/fc'
import ReactPlayer from "react-player";
import lightTheme from "../../utils/styles";
import {fetchDocuments, manageSubscription} from "../../utils/redux-helpers";
import ScanModal1 from "../../components/Modals/Scans/ScanModal1";
import ScanModalIntro1 from "../../components/Modals/Scans/ScanModalIntro1";
import WithSpeechBubbles from "./WithSpeechBubbles";
import PricingScreen from "../../components/Modals/Payments/PricingScreen";
import '../../fonts.css';
import {useTranslation} from "react-i18next";
import LogoCarousel from "../../components/Reuseable/LogoCarousel";
import LogoCarouselSpin from "../../components/Reuseable/LogoCarouselSpin";
import {FaFacebook, FaInstagram, FaLock, FaPhotoVideo, FaRegComments, FaShieldAlt, FaUsers} from "react-icons/fa";
import animationDataCheckmark from "../../components/Modals/Scans/lottie-checkmark.json";
import Lottie from "lottie-react";
import animationData from './step2_headshot.json';
import LogoCarouselSpin2 from "../../components/Reuseable/LogoCarouselSpin2";
import {IoMdCheckmark} from "react-icons/io";
import {createCheckoutSession} from "../../utils/payments";
import FAQ from "../Payments/FAQ";
import encryptionData from './encryption.json';
import { Global } from "@emotion/react";
import {createCheckoutSessionPhoto, createFormID} from "../../utils/localStoragePhoto";
import LogoCarouselSpin1 from "../../components/Reuseable/LogoCarouselSpin1";
const MotionBox = motion(Box);

const UnifiedHomePage = () => {
    const {t} = useTranslation();

    const Card = ({ heading, description, icon }) => {
        return (
            <Box
                maxW={{ base: 'full', md: '275px' }}
                w={'full'}
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                p={5}>
                <Stack align={'start'} spacing={4}>
                    <Flex
                        w={16}
                        h={16}
                        align={'center'}
                        justify={'center'}
                        color={'white'}
                        rounded={'full'}
                        bg={useColorModeValue('gray.100', 'gray.700')}>
                        {icon}
                    </Flex>
                    <Box >
                        <Heading size="md">{heading}</Heading>
                    </Box>
                    <VStack alignItems={"flex-start"} spacing={4} height={'6rem'} justify={'space-between'}>
                        <Text fontSize={'sm'} textAlign={"left"}>
                            {description}
                        </Text>
                        {heading !== t("Long-form Writing") ?
                            <Button variant={'link'} colorScheme={'blue'} size={'sm'} onClick={() => {
                                fileInput.current.click();
                            }}>
                                {t("Try it Out!")}
                            </Button> :
                            <Button variant={'link'} colorScheme={'blue'} size={'sm'} onClick={() => {
                                if (auth.isLoggedIn) {
                                    navigate('/generation');
                                } else {
                                    onOpenSignUpScreen1();
                                }
                            }}>
                                {t("Try it Out!")}
                            </Button>}
                    </VStack>
                </Stack>
            </Box>
        )
    }

    const FeatureCard = ({ icon, title, description }) => (
        <Box
            borderWidth="1px"
            borderRadius="lg"
            borderColor={'gray.800'}
            overflow="hidden"
            height={'auto'}
            p={6}
            bg="black"
            color="white"
            textAlign="center"
            position="relative"
            _hover={{ bg: 'transparent' }}
            display="flex"
            justifyContent={'space-between'}
            alignItems={'space-between'}
            flexDirection={'column'}
            // borderColor={'gray.700'} borderWidth={0.25}
            // shadow={`1.5px 1.8px 0px gray.700`}
        >
            <Box
                bg="purple.700"
                borderRadius="full"
                p={9}
                mx="auto"
                mb={8}
                mt={4}
                width="fit-content"
            >
                {icon}
            </Box>
            <VStack spacing={4} px={6}>
                <Heading as="h2" fontSize={27} fontWeight={700} color={'white'}>
                    {title}
                </Heading>
                <Text fontSize={16.5} color={'gray.100'}>{description}</Text>
            </VStack>
        </Box>
    );

    function PriceWrapper(props) {
        const { children } = props

        return (
            <Box
                mb={0}
                shadow="base"
                borderWidth="1px"
                height={'27rem'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                borderColor={useColorModeValue('gray.200', 'gray.500')}
                borderRadius={'xl'}>
                {children}
            </Box>
        )
    }

    const featuresRef = useRef(null);
    const testimonialsRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const fileInput = useRef();
    const { isOpen: isOpenSignUpScreen1, onOpen: onOpenSignUpScreen1, onClose: onCloseSignUpScreen1 } = useDisclosure();
    const { isOpen: isOpenSignInScreen1, onOpen: onOpenSignInScreen1, onClose: onCloseSignInScreen1 } = useDisclosure();
    const { isOpen: isOpenScanModal1, onOpen: onOpenScanModal1, onClose: onCloseScanModal1 } = useDisclosure();
    const { isOpen: isOpenScanModalIntro1, onOpen: onOpenScanModalIntro1, onClose: onCloseScanModalIntro1 } = useDisclosure();
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();
    const isUploadLoading = useSelector(state => state.settings.isUploadLoading);
    const [uploadProgress, setUploadProgress] = useState(0);
    const auth = useAuth();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const pricingRef = useRef(null);

    const navigateToTestimonials = () => {
        if (testimonialsRef.current) {
            const offsetTop = testimonialsRef.current.getBoundingClientRect().top + window.pageYOffset;
            const offset = 50; // The amount of pixels you want to stop above the element
            window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
        }
    }

    const navigateToFeatures = () => {
        if (featuresRef.current) {
            const offsetTop = featuresRef.current.getBoundingClientRect().top + window.pageYOffset;
            const offset = 50; // The amount of pixels you want to stop above the element
            window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
        }
    };

    const navigateToPricing = () => {
        if (pricingRef.current) {
            const offsetTop = pricingRef.current.getBoundingClientRect().top + window.pageYOffset;
            const offset = 50; // The amount of pixels you want to stop above the element
            window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const hash = window.location.hash.substring(1);
        if (hash) {
            if (hash === 'faqs' && featuresRef.current) {
                const offsetTop = featuresRef.current.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({ top: offsetTop - 50, behavior: 'smooth' });
            } else if (hash === 'privacy' && testimonialsRef.current) {
                const offsetTop = testimonialsRef.current.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({ top: offsetTop - 50, behavior: 'smooth' });
            } else if (hash === 'pricing' && pricingRef.current) {
                const offsetTop = pricingRef.current.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({ top: offsetTop - 50, behavior: 'smooth' });
            }
        }
    }, []);

    const onDrop = useCallback((acceptedFiles) => {
        if (!JSON.parse(localStorage.getItem("isProPlan")) && localStorage.getItem("isUploadExceeded") === "true") {
            toast({
                title: t("(Free Plan) PDF Upload Limit Exceeded!"),
                status: "info",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
            dispatch(setIsPricingModalOpen(true));
            return;
        }

        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            handleFileUpload({target: {files: [file]}}).then(r =>{});
        }
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'application/pdf',
        multiple: false,
    });

    // Define the upload function based on the user authentication state
    const handleFileUpload = async (event) => {
        console.log("USERID BELOW: ")
        //console.log(localStorage.getItem("userID"));

        const document = event.target.files[0];
        if (localStorage.getItem("userID") !== null) {
            if (document) {
                if (document.type === 'application/pdf') {
                    dispatch(setIsUploadLoading(true));
                    const formData = new FormData();
                    formData.append('file', document);
                    formData.append('user_id', localStorage.getItem("userID"));
                    formData.append('file_name', document.name);
                    console.log("FORM DATA BELOW: ")
                    //console.log(formData.forEach((value, key) => console.log(key + ' ' + value)));

                    try {
                        const response = await axios.post(SERVER_IP + '/pdf', formData, {
                            onUploadProgress: (progressEvent) => {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                setUploadProgress(percentCompleted);
                            }
                        });
                        console.log('Uploaded PDF Document Response:', response);


                        if (response.data.status === 207) {
                            dispatch(setScanFileID(response.data.message.file_id));
                            dispatch(setScanEstimatedSeconds(response.data.message.time_estimate_seconds));
                            dispatch(setIsUploadLoading(false));
                            setUploadProgress(0);
                            onOpenScanModal1();
                            dispatch(setScanError(false));
                            dispatch(addDocument({
                                id: response.data.message.file_id,
                                name: response.data.message.filename,
                                url: response.data.message.url,
                                highlights: [],
                                messages: [],
                                messagesGeneration: [],
                            }));
                            return;
                        }



                        dispatch(setIsUploadLoading(false));
                        setUploadProgress(0);
                        if (response.data.status === 400) {
                            toast({
                                title: t("PDF is too large."),
                                description: t("We support up to 512 MB or 147,000 words"),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        } else if (response.data.status === 401) {
                            toast({
                                title: t("Oops, this PDF failed."),
                                description: t("Please try again or upload a different PDF."),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        } else if (response.data.status === 402 && response.data.message === "free tier exceeded. Please subscribe to continue using the service") {
                            console.log("pricing_modal_shown")
                            dispatch(setIsPricingModalOpen(true));
                            localStorage.setItem("isUploadExceeded", "true");
                            toast({
                                title: t("(Free Plan) PDF Upload Limit Exceeded!"),
                                status: "info",
                                duration: 9000,
                                isClosable: true,
                                position: "top",
                            });
                        } else if (response.data.status === 403) {
                            toast({
                                title: t("Free Plan supports <= 20 pages for scanned PDFs"),
                                description: t("Visit honeybear.ai/pricing to view Pro Plan pricing"),
                                status: "error",
                                duration: null,
                                isClosable: true,
                            })
                        }
                        else if (response.data.status === 404) {
                            toast({
                                title: t("Scanned PDF Too Large"),
                                description: t("Pro Plan supports up to 200 pages for scanned PDFs"),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        else {
                            dispatch(addDocument({
                                id: response.data.file_id,
                                name: response.data.filename,
                                url: response.data.url,
                                highlights: [],
                                messages: [],
                                messagesGeneration: [],
                            }));
                            toast({
                                title: t("Document uploaded."),
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                            });
                        }
                    } catch (error) {
                        dispatch(setIsUploadLoading(false));
                        setUploadProgress(0);
                        toast({
                            title: t("Oops, this PDF failed."),
                            description: t("Please try again or upload a different PDF."),
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                        console.error('Error uploading the document:', error);
                    }
                } else {
                    toast({
                        title: t("Upload failed."),
                        description: t("We only support PDF documents."),
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                }
            }
        } else {
            if (document) {
                if (getDocumentsInStorage().length === 1) {
                    toast({
                        title: t("You've reached your document limit in guest mode!"),
                        description: t("Please sign up to continue uploading documents."),
                        status: "info",
                        duration: 9000,
                        isClosable: true,
                        position: "top",
                    });
                    onOpenSignUpScreen1()
                    return;
                }
                if (!JSON.parse(localStorage.getItem("isProPlan")) && localStorage.getItem("isUploadExceeded") === "true") {
                    toast({
                        title: t("(Free Plan) PDF Upload Limit Exceeded!"),
                        status: "info",
                        duration: 9000,
                        isClosable: true,
                        position: "top",
                    });
                    dispatch(setIsPricingModalOpen(true));
                    return;
                }
                if (document.type === 'application/pdf') {
                    dispatch(setIsUploadLoading(true));
                    const formData = new FormData();
                    formData.append('file', document);
                    formData.append('user_id', "11111111-1111-1111-1111-111111111111");
                    formData.append('file_name', document.name);
                    try {
                        const response = await axios.post(SERVER_IP + '/pdf', formData, {
                            onUploadProgress: (progressEvent) => {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                setUploadProgress(percentCompleted);
                            }
                        });



                        if (response.data.status === 207) {
                            dispatch(setScanFileID(response.data.message.file_id));
                            dispatch(setScanEstimatedSeconds(response.data.message.time_estimate_seconds));
                            dispatch(setIsUploadLoading(false));
                            setUploadProgress(0);
                            onOpenScanModal1();
                            dispatch(setScanError(false));
                            dispatch(addDocument({
                                id: response.data.message.file_id,
                                name: response.data.message.filename,
                                url: response.data.message.url,
                                highlights: [],
                                messages: [],
                                messagesGeneration: [],
                            }));
                            return;
                        }



                        dispatch(setIsUploadLoading(false));
                        setUploadProgress(0);
                        if (response.data.status === 400) {
                            toast({
                                title: t("PDF is too large."),
                                description: t("We support up to 512 MB or 147,000 words"),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        else if (response.data.status === 401) {
                            toast({
                                title: t("Oops, this PDF failed."),
                                description: t("Please try again or upload a different PDF."),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        else if (response.data.status === 402) {
                            toast({
                                title: t("Oops, this PDF failed."),
                                description: t("Please try again or upload a different PDF."),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        } else if (response.data.status === 403) {
                            toast({
                                title: t("Free Plan supports <= 20 pages for scanned PDFs"),
                                description: t("Visit honeybear.ai/pricing to view Pro Plan pricing"),
                                status: "error",
                                duration: null,
                                isClosable: true,
                            })
                        }
                        else if (response.data.status === 404) {
                            toast({
                                title: t("Scanned PDF Too Large"),
                                description: t("Pro Plan supports up to 200 pages for scanned PDFs"),
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        else {
                            addDocumentInStorage({
                                id: response.data.file_id,
                                name: response.data.filename,
                                url: response.data.url,
                                highlights: [],
                                messages: [],
                                messagesGeneration: [],
                            })
                            auth.setPlaygroundDocuments(getDocumentsInStorage());
                            dispatch(addDocument({
                                id: response.data.file_id,
                                name: response.data.filename,
                                url: response.data.url,
                                highlights: [],
                                messages: [],
                                messagesGeneration: [],
                            }));
                            toast({
                                title: t("Document uploaded."),
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                            })
                        }

                    } catch (error) {
                        dispatch(setIsUploadLoading(false));
                        setUploadProgress(0);
                        toast({
                            title: t("Oops, this PDF failed."),
                            description: t("Please try again or upload a different PDF."),
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                        console.error('Error uploading the document:', error);
                    }
                } else {
                    toast({
                        title: t("Upload failed."),
                        description: t("We only support PDF documents."),
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                }
            }
        }
    };

    // Select the correct document list based on authentication
    const documents = useSelector(state => state.documents.savedDocuments);

    useEffect(() => {
        const tempUserID = localStorage.getItem("userID");
        if (tempUserID !== null) {
            fetchDocuments(tempUserID).then(r => {
                dispatch(setDocuments(r.documents));
            })
        }
    }, []);

    const placeholders = ["https://techcrunch.com/2023/12/22/etsy-layoffs-no-surprise/",
        "https://arxiv.org/pdf/1706.03762.pdf"];
    const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);
    const [index, setIndex] = useState(0);
    const [fade, setFade] = useState(true); // State to manage fade in and out
    const [urlInput, setUrlInput] = useState("");
    const isGridStacked = useBreakpointValue({ base: true, sm: true, md: false, lg: false });
    const [mainButtonLoading, setMainButtonLoading] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFade(false); // Fade out the current placeholder

            setTimeout(() => {
                // After fade out, change the placeholder and fade it in
                setIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
                setCurrentPlaceholder(placeholders[index]);
                setFade(true);
            }, 750); // Half the interval time to allow for fade in and fade out
        }, 1500);

        return () => clearInterval(intervalId);
    }, [index]);

    return (
        <div>
            <FullScreenTemplateHomePage navigateToTestimonials={navigateToTestimonials}
                                        navigateToFeatures={navigateToFeatures} navigateToPricing={navigateToPricing}>
                <VStack width={'100%'} height={'100%'} justifyContent={'center'} display={'flex'} spacing={'4rem'}
                        mt={documents.length === 0 ? '3.5rem' : '0rem'}>
                    {documents.length !== 0 ?
                        isUploadLoading ?
                            <Box width={'60%'} pt={'3.5rem'}>
                                <LoadingBar progress={uploadProgress} isMarginBottom={true}/>
                            </Box> :
                            <VStack width={"100%"} height={'21.75rem'} mb={'1rem'} spacing={6} py={'1.75rem'}
                                    bg={lightTheme.colors.lightGray}>
                                <VStack width={{
                                    base: '80%', // 100% width on smallest screens
                                    sm: '70%', // 80% width when the screen is larger than 'sm'
                                    md: '60%', // 70% width when the screen is larger than 'md'
                                    lg: '55%', // 60% width when the screen is larger than 'lg'
                                    xl: '50%', // 50% width when the screen is larger than 'xl'
                                }} bg={'white'} height={'18rem'} p={'1.75rem'}
                                        borderRadius={'15px'} spacing={4} borderColor={'lightgray'} borderWidth={0.25}
                                        shadow={`1.5px 1.8px 0px lightgray`}>
                                    <MotionBox
                                        {...getRootProps()}
                                        border="2px"
                                        borderColor="orange.400"
                                        borderRadius="md"
                                        borderStyle="dashed"
                                        textAlign="center"
                                        p="3"
                                        pt={auth.isLoggedIn ? '0.73rem' : '3rem'}
                                        cursor="pointer"
                                        _hover={{ bg: 'gray.100' }}
                                        animate={isDragActive ? { scale: 1.05 } : { scale: 1 }}
                                        transition={{ duration: 0.2 }}
                                        width="100%"
                                        height="100%"
                                        onClick={() => {
                                            if (getDocumentsInStorage().length === 1 && !auth.isLoggedIn) {
                                                toast({
                                                    title: t("You've reached your document limit in guest mode!"),
                                                    description: t("Please sign up to continue uploading documents."),
                                                    status: "info",
                                                    duration: 9000,
                                                    isClosable: true,
                                                    position: "top",
                                                });
                                                onOpenSignUpScreen1();
                                            }
                                            else if (!JSON.parse(localStorage.getItem("isProPlan")) && localStorage.getItem("isUploadExceeded") === "true") {
                                                toast({
                                                    title: t("(Free Plan) PDF Upload Limit Exceeded!"),
                                                    status: "info",
                                                    duration: 9000,
                                                    isClosable: true,
                                                    position: "top",
                                                });
                                                dispatch(setIsPricingModalOpen(true));
                                            }
                                            else {
                                                fileInput.current.click();
                                            }
                                        }}
                                    >
                                        <input {...getInputProps({ accept: 'application/pdf' })} ref={fileInput}/>
                                        <Box as="span" color="gray.400" fontSize="3xl">
                                            ⇧
                                        </Box>
                                        <Text fontWeight="bold" color="gray.400" fontSize={15}>
                                            {t("Drag & Drop")}
                                        </Text>
                                        <Text color="gray.500" fontSize={15}>{t("or click to browse")}</Text>
                                    </MotionBox>

                                    {auth.isLoggedIn ? <Text fontSize={'sm'} color={'gray.500'}>{t("or")}</Text> : null}

                                    {auth.isLoggedIn ?
                                        <Box position="relative" display="inline-block" mt={1}>
                                            <Button
                                                alignSelf={"center"}
                                                borderRadius={'15px'}
                                                px={6}
                                                colorScheme={'orange'}
                                                bg={'orange.400'}
                                                _hover={{ bg: 'orange.500' }}
                                                onClick={() => {
                                                    navigate('/generation');
                                                }}>
                                                {t("AI Generate Writing")}
                                            </Button>
                                        </Box> : null}
                                </VStack>
                            </VStack>
                        : null}

                    {
                        documents.length === 0 ? (
                            <Container maxW={'100%'} mt={'-10rem'} bg={'white'} align={'center'}>
                                <Stack
                                    textAlign={'center'}
                                    align={'center'}
                                    spacing={{ base: 8, md: 10 }}
                                    maxW={'5xl'}
                                    py={{ base: 20, md: 28 }}>
                                    {Object.keys(influencerMap).includes(localStorage.getItem('referralCodeInfluencer')) ?
                                        <Text className="caveat-influencer" mb={-2}>{influencerMap[localStorage.getItem('referralCodeInfluencer')]}</Text>
                                        :
                                        null}
                                    <Flex w={'100%'} justifyContent={"center"} display={"flex"} flexDirection={'column'}
                                          alignItems={'center'} mb={-12}>
                                        <LogoCarouselSpin/>
                                    </Flex>
                                    <Box
                                        border="0px solid"
                                        boxSizing="border-box"
                                        borderColor="hsl(240, 5.9%, 90%)"
                                        scrollPaddingTop="max(8vh, 40px)"
                                        lineHeight="1.5"
                                        textSizeAdjust="100%"
                                        tabSize={4}
                                        fontFamily='"__Inter_c67b7c","__Inter_Fallback_c67b7c",system-ui,sans-serif'
                                        fontFeatureSettings="normal"
                                        fontVariationSettings="normal"
                                        backgroundColor="rgb(255, 255, 255)"
                                        scrollBehavior="smooth"
                                        webkitFontSmoothing="antialiased"
                                        textRendering="optimizeLegibility"
                                        color="rgb(17, 17, 17)"
                                        display="flex"
                                        flexDirection="column"
                                        p={0}
                                    >
                                        <Heading
                                            className="hero-heading-test-b"
                                            border="0px solid"
                                            boxSizing="border-box"
                                            borderColor="hsl(240, 5.9%, 90%)"
                                            scrollPaddingTop="max(8vh, 40px)"
                                            m={0}
                                            pb={0}
                                            maxWidth="10em"
                                            letterSpacing="-0.032em"
                                            mx="auto"
                                            fontWeight={700}
                                            fontFamily='var(--font-ppmori),"__Inter_c67b7c","__Inter_Fallback_c67b7c",system-ui,sans-serif'
                                            lineHeight="1.1"
                                            fontSize={isMobile ? "2rem" : "3.6rem"}
                                            textAlign="center"
                                        >
                                            The Most Realistic AI Headshots
                                        </Heading>
                                    </Box>



                                    <Text color={'gray.600'} maxW={'3xl'} fontSize={isMobile ? 15 : 18}
                                          fontWeight={isMobile ? '500' : '500'} mt={-2.5}>
                                        Get professional AI headshots with our cutting-edge AI generator. Built by AI researchers.
                                        Save hours of your time, and hundreds of dollars.
                                    </Text>
                                    <Stack spacing={6} direction={'row'}>
                                        <Button
                                            rounded={'full'}
                                            fontSize={18}
                                            px={7}
                                            py={7}
                                            colorScheme={'orange'}
                                            bg={'orange.400'}
                                            _hover={{ bg: 'orange.500' }}
                                            isLoading={mainButtonLoading}
                                            onClick={() => {
                                                setMainButtonLoading(true);
                                                if (localStorage.getItem("formIDPhoto") !== null) {
                                                    navigate('/form');
                                                    setMainButtonLoading(false);
                                                } else {
                                                    createFormID().then(r => {
                                                        setMainButtonLoading(false);
                                                        localStorage.setItem("formIDPhoto", r.form_completion_id)
                                                        navigate('/form');
                                                    });
                                                }
                                            }}>
                                            {t('Create your Headshots')}
                                        </Button>
                                    </Stack>

                                    <Flex w={'100%'} justifyContent={"center"} display={"flex"} flexDirection={'column'}
                                          alignItems={'center'}>


                                        <LogoCarouselSpin1/>
                                        <LogoCarousel/>





                                        <VStack width={'100%'} bg={'white'} spacing={8} align={'start'} mt={8} mb={0}>
                                            <Grid templateColumns={isGridStacked ? "1fr" : "repeat(3, 1fr)"} gap={10} width="100%">
                                                <GridItem>
                                                    <VStack align={'start'}>
                                                        <Heading as="h3" fontSize="xl" fontWeight="bold">
                                                            {t("STEP ONE")}
                                                        </Heading>
                                                        <Box borderBottom="2px" borderColor="black" width="100%" mb={2}></Box>
                                                        <Text fontSize="2xl" fontWeight="bold">
                                                            {t("Upload some photos")}
                                                        </Text>
                                                        <Text fontSize="md" color={'gray.600'} textAlign={'left'}>
                                                            {t("Upload 6 photos of yourself, and that's all we'll need.")}
                                                        </Text>
                                                    </VStack>
                                                </GridItem>
                                                <GridItem>
                                                    <VStack align={'start'}>
                                                        <Heading as="h3" fontSize="xl" fontWeight="bold">
                                                            {t("STEP TWO")}
                                                        </Heading>
                                                        <Box borderBottom="2px" borderColor="black" width="100%" mb={2}></Box>
                                                        <Text fontSize="2xl" fontWeight="bold">
                                                            {t("Our AI works its magic")}
                                                        </Text>
                                                        <Text fontSize="md" color={'gray.600'} textAlign={'left'}>
                                                            {t("We use state of the art AI technology created by researchers and software engineers.")}
                                                        </Text>
                                                    </VStack>
                                                </GridItem>
                                                <GridItem>
                                                    <VStack align={'start'}>
                                                        <Heading as="h3" fontSize="xl" fontWeight="bold">
                                                            {t("STEP THREE")}
                                                        </Heading>
                                                        <Box borderBottom="2px" borderColor="black" width="100%" mb={2}></Box>
                                                        <Text fontSize="2xl" fontWeight="bold">
                                                            {t("Get your headshots")}
                                                        </Text>
                                                        <Text fontSize="md" color={'gray.600'} textAlign={'left'}>
                                                            {t("Get self headshots in a variety of different backgrounds and styles. Plus even photos of " +
                                                                "you hiking or engaging in other activites!")}
                                                        </Text>
                                                    </VStack>
                                                </GridItem>
                                            </Grid>
                                            <Box width={'100%'} align={'center'} justify={'center'} mt={8}>
                                                {/*<LogoCarouselSpin2/>*/}
                                                {/*<LogoCarouselSpin2/>*/}
                                            </Box>
                                        </VStack>
















                                        <Box width="100vw" mt={'3rem'} justify={'center'} align={'center'} ref={testimonialsRef}>
                                            <Box bg={'black'}>
                                                <Container maxW={'7xl'} pt={14} pb={16} as={Stack} spacing={12} align={'center'}>
                                                    <Stack spacing={6} align={'center'} width={isMobile ? '100%' : '50%'} justify={'center'}>
                                                        <Heading as="h1" fontSize={'7xl'} fontWeight="800" color={'purple.400'}>
                                                            {t("Privacy First ")}
                                                            <Box
                                                                as="span"
                                                                bg={'white'}
                                                                bgClip="text"
                                                                fontSize={'6xl'}
                                                            >
                                                                {t("Protecting Your Data")}
                                                            </Box>
                                                        </Heading>
                                                        <Text color={'gray.200'} fontSize={23}>
                                                            {t("Your data is private and will never be sold.")}
                                                        </Text>
                                                        <Button
                                                            rounded={'full'}
                                                            fontSize={20}
                                                            px={7}
                                                            mt={4}
                                                            py={7}
                                                            color={'white'}
                                                            bg={'purple.500'}
                                                            _hover={{ bg: 'purple.600' }}
                                                            isLoading={mainButtonLoading}
                                                            onClick={() => {
                                                                setMainButtonLoading(true);
                                                                if (localStorage.getItem("formIDPhoto") !== null) {
                                                                    navigate('/form');
                                                                    setMainButtonLoading(false);
                                                                } else {
                                                                    createFormID().then(r => {
                                                                        setMainButtonLoading(false);
                                                                        localStorage.setItem("formIDPhoto", r.form_completion_id)
                                                                        navigate('/form');
                                                                    });
                                                                }
                                                            }}>
                                                            {t('Create your Headshots')}
                                                        </Button>
                                                    </Stack>



                                                    <Container maxW="85%" py={6}>
                                                        <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={5}>
                                                            <FeatureCard
                                                                icon={<FaLock size="100px" />}
                                                                title="Advanced Encryption"
                                                                description="Photomax.ai encrypts all user data because your data deserves the highest protection."
                                                            />
                                                            <FeatureCard
                                                                icon={<FaShieldAlt size="100px" />}
                                                                title="Your Data is Your Data"
                                                                description="Your data will NEVER be sold to any third party. Ever."
                                                            />
                                                            <FeatureCard
                                                                icon={<FaUsers size="100px" />}
                                                                title="You have Full Control"
                                                                description="Your photos will only be used to create your headshots.
                                                                You can email us at contact@photomax.ai and your photos will be permanently deleted."
                                                            />
                                                            <FeatureCard
                                                                icon={<FaRegComments size="100px" />}
                                                                title="Immediate Support"
                                                                description="Contact us anytime at contact@photomax.ai, and we'll respond right away."
                                                            />
                                                        </Grid>
                                                    </Container>


                                                    <VStack width={'22rem'} spacing={4}>
                                                        <Text color={'white'} fontSize={22} fontWeight={550} textAlign={'center'}>
                                                            "Photomax is the best. I even got photos of me holding a dog! Will be a customer for life."
                                                        </Text>
                                                        <HStack spacing={4}>
                                                            <Image
                                                                borderRadius={'full'}
                                                                boxSize="3rem"
                                                                src="/janelle.jpg"
                                                                alt="Janelle R."
                                                                objectFit="cover"
                                                            />
                                                            <VStack align={'start'} spacing={1}>
                                                                <Text color={'white'} fontSize={17} fontWeight={500}>Janelle R.</Text>
                                                                <Text color={'lightgray'} fontSize={14.5}>Brown University</Text>
                                                            </VStack>
                                                        </HStack>
                                                    </VStack>
                                                </Container>
                                            </Box>
                                        </Box>



                                        <VStack py={10} spacing={10} width={'100%'} ref={pricingRef}>
                                            <VStack spacing={2} textAlign="center">
                                                <Heading as="h1" fontSize="5xl" fontWeight="600">
                                                    {t("Less Expensive, ")}
                                                    <Box
                                                        as="span"
                                                        bgGradient="linear(to-r, purple.500, pink.500)"
                                                        bgClip="text"
                                                    >
                                                        {t("More Quality.")}
                                                    </Box>
                                                </Heading>
                                                <Text fontSize={21} color={'gray.700'} mt={4} fontWeight={450} width={'75%'}>
                                                    {t("Get the highest-quality AI headshots, for the lowest price on the market.")}
                                                </Text>
                                            </VStack>
                                            <Stack
                                                direction={{ base: 'column', md: 'row' }}
                                                textAlign="center"
                                                justify="center"
                                                spacing={{ base: 9, lg: 9 }}
                                                mt={-5}
                                                py={10}>
                                                <PriceWrapper>
                                                    <Box px={6} py={5} height={'40%'} textAlign="left">
                                                        <Text fontWeight="500" fontSize={28}>
                                                            Basic
                                                        </Text>
                                                        <HStack spacing={1} mt={-1.5}>
                                                            <Text fontSize="3xl" fontWeight="500">
                                                                $
                                                            </Text>
                                                            <Text fontSize="5xl" fontWeight="600">
                                                                10
                                                            </Text>
                                                        </HStack>
                                                        <Text fontWeight="400" color={'gray'} fontSize={16}>
                                                            {t("one-time payment")}
                                                        </Text>
                                                    </Box>
                                                    <VStack
                                                        bg={'white'}
                                                        borderTopColor={'lightgray'}
                                                        borderTopWidth={1}
                                                        py={4}
                                                        pt={7}
                                                        mx={6}
                                                        align={'left'}
                                                        width={isMobile ? '86%' : 'auto'}
                                                        justify={'space-between'}
                                                        height={'60%'}
                                                        borderBottomRadius={'xl'}>
                                                        <List spacing={3} textAlign="start">
                                                            <ListItem fontSize={15.5}>
                                                                <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                                {t("15 high-quality headshots")}
                                                            </ListItem>
                                                            <ListItem fontSize={15.5}>
                                                                <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                                {t("24-hour turnaround time")}
                                                            </ListItem>
                                                            {/*<ListItem fontSize={15.5}>*/}
                                                            {/*    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>*/}
                                                            {/*    {t("Up to 20 pages for scanned PDFs")}*/}
                                                            {/*</ListItem>*/}
                                                        </List>
                                                        {auth.isLoggedIn && JSON.parse(localStorage.getItem("isProPlan")) ?
                                                            null
                                                            :
                                                            <Box w="100%" pt={0}>
                                                                <Button w="full"
                                                                        bg="black"
                                                                        color="white"
                                                                        borderColor="black"
                                                                        variant="solid"
                                                                        _hover={{ bg: "gray.700" }}
                                                                        onClick={async () => {
                                                                            setMainButtonLoading(true);
                                                                            if (localStorage.getItem("formIDPhoto") !== null) {
                                                                                navigate('/form');
                                                                                setMainButtonLoading(false);
                                                                            } else {
                                                                                createFormID().then(r => {
                                                                                    setMainButtonLoading(false);
                                                                                    localStorage.setItem("formIDPhoto", r.form_completion_id)
                                                                                    navigate('/form');
                                                                                });
                                                                            }
                                                                        }}>
                                                                    {t("Get Started")}
                                                                </Button>
                                                            </Box>}
                                                    </VStack>
                                                </PriceWrapper>
                                                {/*/////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                                                <PriceWrapper>
                                                    <Box px={6} py={5} height={'40%'} textAlign="left">
                                                        <Text fontWeight="500" fontSize={28}>
                                                            Pro
                                                        </Text>
                                                        <HStack spacing={1} mt={-1.5}>
                                                            <Text fontSize="3xl" fontWeight="500">
                                                                $
                                                            </Text>
                                                            <Text fontSize="5xl" fontWeight="600">
                                                                20
                                                            </Text>
                                                        </HStack>
                                                        <Text fontWeight="400" color={'gray'} fontSize={16}>
                                                            {t("one-time payment")}
                                                        </Text>
                                                    </Box>
                                                    <VStack
                                                        bg={'white'}
                                                        borderTopColor={'lightgray'}
                                                        borderTopWidth={1}
                                                        py={4}
                                                        pt={7}
                                                        mx={6}
                                                        justify={'space-between'}
                                                        height={'60%'}
                                                        borderBottomRadius={'xl'}>
                                                        <List spacing={3} textAlign="start">
                                                            <ListItem fontSize={15.5}>
                                                                <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                                {t("30 high-quality headshots")}
                                                            </ListItem>
                                                            <ListItem fontSize={15.5}>
                                                                <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                                {t("12-hour turnaround time")}
                                                            </ListItem>
                                                            <ListItem fontSize={15.5}>
                                                                <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                                {t("More diverse backgrounds and poses, i.e. you holding a dog.")}
                                                            </ListItem>
                                                            {/*<ListItem fontSize={15.5}>*/}
                                                            {/*    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>*/}
                                                            {/*    {t("Access to future Pro features")}*/}
                                                            {/*</ListItem>*/}
                                                        </List>
                                                        {auth.isLoggedIn && JSON.parse(localStorage.getItem("isProPlan")) ?
                                                            <Box w="100%" pt={0}>
                                                                <Button w="full"
                                                                        bg="black"
                                                                        color="white"
                                                                        borderColor="black"
                                                                        variant="solid"
                                                                        _hover={{ bg: "gray.700" }}
                                                                    // isLoading={manageSubscriptionLoading}
                                                                    // isDisabled={manageSubscriptionLoading}
                                                                        onClick={async () => {
                                                                            // setManageSubscriptionLoading(true)
                                                                            // manageSubscription(localStorage.getItem("userID")).then(r => {
                                                                            //     setManageSubscriptionLoading(false)
                                                                            //     window.location.href = r.url;
                                                                            // })
                                                                        }}>
                                                                    {t("Manage Subscription")}
                                                                </Button>
                                                            </Box>
                                                            :
                                                            <Box w="100%" pt={0}>
                                                                <Button w="full"
                                                                        bg="black"
                                                                        color="white"
                                                                        borderColor="black"
                                                                        variant="solid"
                                                                        _hover={{ bg: "gray.700" }}
                                                                    // isLoading={premiumLoading}
                                                                    // isDisabled={premiumLoading}
                                                                        onClick={async () => {
                                                                            setMainButtonLoading(true);
                                                                            if (localStorage.getItem("formIDPhoto") !== null) {
                                                                                navigate('/form');
                                                                                setMainButtonLoading(false);
                                                                            } else {
                                                                                createFormID().then(r => {
                                                                                    setMainButtonLoading(false);
                                                                                    localStorage.setItem("formIDPhoto", r.form_completion_id)
                                                                                    navigate('/form');
                                                                                });
                                                                            }
                                                                        }}>
                                                                    {t("Get Started")}
                                                                </Button>
                                                            </Box>
                                                        }
                                                    </VStack>
                                                </PriceWrapper>
                                            </Stack>
                                            <LogoCarousel/>
                                            <Divider borderColor="gray.300" width={'100vw'} mb={4}/>
                                        </VStack>


                                        <Container maxW="100%" centerContent py={8} position="relative" height={'40rem'}>
                                            <Box position="relative" zIndex={1} bg="transparent" p={8} borderRadius="md"
                                                 align={'center'}>
                                                <Heading as="h1" fontSize="6xl" fontWeight={700}>
                                                    <Box
                                                        as="span"
                                                        bgGradient="linear(to-r, purple.500, pink.500)"
                                                        bgClip="text"
                                                    >
                                                        {t("Money-back")}
                                                    </Box>
                                                </Heading>
                                                <Heading as="h1" fontSize="6xl" fontWeight={700}>
                                                    Guarantee
                                                </Heading>
                                                <Text fontSize={19} color={'gray.700'} mt={6} mb={9} width={'50%'}>
                                                    Use Photomax.ai with peace of mind. Not fully satisfied? Contact us within 7 days of your purchase for a{' '}
                                                    <Text as="span" color="blue.500" fontWeight="bold">
                                                        full refund.
                                                    </Text>
                                                </Text>
                                                <Button
                                                    rounded={'full'}
                                                    fontSize={17}
                                                    px={7}
                                                    py={7}
                                                    color={'white'}
                                                    bg={'black'}
                                                    borderColor="black"
                                                    _hover={{ bg: 'gray.700' }}
                                                    onClick={() => {
                                                        setMainButtonLoading(true);
                                                        if (localStorage.getItem("formIDPhoto") !== null) {
                                                            navigate('/form');
                                                            setMainButtonLoading(false);
                                                        } else {
                                                            createFormID().then(r => {
                                                                setMainButtonLoading(false);
                                                                localStorage.setItem("formIDPhoto", r.form_completion_id)
                                                                navigate('/form');
                                                            });
                                                        }
                                                    }}>
                                                    {t('Create your Headshots')}
                                                </Button>
                                            </Box>

                                            {/* Surrounding images */}
                                            {!isMobile ?
                                                <Box position="absolute" top="5%" left="6%">
                                                    <Image src="/1.png" height={'8.5rem'} width={'7rem'} borderRadius="lg" objectFit={'cover'} />
                                                </Box> : null}
                                            {!isMobile ?
                                                <Box position="absolute" top="5%" right="6%">
                                                    <Image src="/6.png" height={'8.5rem'} width={'7rem'} borderRadius="lg" objectFit={'cover'} />
                                                </Box> : null}
                                            {!isMobile ?
                                                <Box position="absolute" bottom="20%" left="6%">
                                                    <Image src="/20.png" height={'8.5rem'} width={'7rem'} borderRadius="lg" objectFit={'cover'} />
                                                </Box> : null}
                                            {!isMobile ?
                                                <Box position="absolute" bottom="20%" right="6%">
                                                    <Image src="/10.png" height={'8.5rem'} width={'7rem'} borderRadius="lg" objectFit={'cover'} />
                                                </Box> : null}
                                            {!isMobile ?
                                                <Box position="absolute" top="30%" left="0%">
                                                    <Image src="/18.png" height={'8.5rem'} width={'7rem'} borderRadius="lg" objectFit={'cover'} />
                                                </Box> : null}
                                            {!isMobile ?
                                                <Box position="absolute" top="30%" right="0%">
                                                    <Image src="/14.png" height={'8.5rem'} width={'7rem'} borderRadius="lg" objectFit={'cover'} />
                                                </Box> : null}
                                        </Container>

                                        {/*<Divider borderColor="gray.300" width={'100vw'} mb={4}/>*/}

                                        <div ref={featuresRef}>
                                            <FAQ/>
                                        </div>


                                        {/*<Box width={'100%'} bgGradient="linear(to-b, blue.900, black)" borderRadius={30} py={20} textAlign="center" px={10}>*/}
                                        {/*    <VStack spacing={12}>*/}
                                        {/*        <Heading as="h2" fontSize="4xl" color="white" fontWeight={700}>*/}
                                        {/*            {t("Ready to get")} <Text as="span" color="gold">your AI</Text> {t("headshots?")}*/}
                                        {/*        </Heading>*/}
                                        {/*        <Button*/}
                                        {/*            rounded="full"*/}
                                        {/*            px={8}*/}
                                        {/*            mt={1}*/}
                                        {/*            colorScheme="whiteAlpha"*/}
                                        {/*            bg="white"*/}
                                        {/*            color="#0d1117"*/}
                                        {/*            onClick={() => {*/}
                                        {/*                window.location.href = 'https://honeybear-ai.getrewardful.com/signup'*/}
                                        {/*            }}*/}
                                        {/*            _hover={{ bg: 'gray.300' }}*/}
                                        {/*        >*/}
                                        {/*            {t("Create your Headshots")}*/}
                                        {/*        </Button>*/}
                                        {/*    </VStack>*/}
                                        {/*</Box>*/}
                                    </Flex>
                                </Stack>
                            </Container>
                        ) : (
                            <DocumentGrid navigate={navigate} />
                        )
                    }
                </VStack>
            </FullScreenTemplateHomePage>
            <LargeWithNewsletter navigateToPricing={navigateToPricing}/>
            <SignUpScreen1 isOpenSignUpScreen1={isOpenSignUpScreen1} onCloseSignUpScreen1={onCloseSignUpScreen1} onOpenSignInScreen1={onOpenSignInScreen1}/>
            <SignInScreen1 isOpenSignInScreen1={isOpenSignInScreen1} onCloseSignInScreen1={onCloseSignInScreen1} onOpenSignUpScreen1={onOpenSignUpScreen1}/>
            <ScanModal1 isOpenScanModal1={isOpenScanModal1} onCloseScanModal1={onCloseScanModal1} onOpenScanModal1={onOpenScanModal1}/>
            <ScanModalIntro1 isOpenScanModalIntro1={isOpenScanModalIntro1} onCloseScanModalIntro1={onCloseScanModalIntro1}
                             onOpenScanModalIntro1={onOpenScanModalIntro1}/>
            <PricingScreen isOpenPricingScreen={isOpenPricingScreen}
                           onClosePricingScreen={onClosePricingScreen}
                           onOpenPricingScreen={onOpenPricingScreen}/>
        </div>
    );
};

export default UnifiedHomePage;






