import React from 'react';
import lightTheme from "../../utils/styles";

const LoadingBar = ({ progress, isMarginBottom }) => {
    const barStyle = {
        width: `${progress}%`,
        height: '50px',
        backgroundColor: '#fb923c',
        transition: 'width 0.5s',
        borderRadius: '10px',
        display: 'flex', // Use flexbox to center the text
        alignItems: 'center', // Center the text vertically
        justifyContent: 'center', // Center the text horizontally
        color: 'white', // Set text color to white
        position: 'relative', // Position relative to the container
        overflow: 'hidden', // Prevent text from overflowing
        fontWeight: 'bold'
    };

    const containerStyle = {
        width: '100%',
        height: '50px',
        backgroundColor: lightTheme.colors.lightGray,
        borderRadius: '10px',
        marginBottom: isMarginBottom ? '4rem' : '0rem',
        overflow: 'hidden'
    };

    return (
        <div style={containerStyle}>
            <div style={barStyle}>
                {progress > 0 ? progress - 1 : progress}%
            </div>
        </div>
    );
};

export default LoadingBar;
