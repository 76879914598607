import axios from "axios";
import {SERVER_IP} from "./constants";

export const deleteDocument = async (userID, fileID) => {
    try {
        const response = await axios.post(SERVER_IP + '/pdf/delete', {
            "user_id" : userID,
            "file_id" : fileID,
        });
        console.log("response from /pdf/delete", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        } else if (response.data.status === 400) {
            return {
                "status" : 400,
            }
        }
    } catch (error) {
        console.log("/pdf/delete error", error);
        return null;
    }
}

export const deleteGeneration = async (userID, generationID) => {
    try {
        const response = await axios.post(SERVER_IP + '/pdf/delete_generation', {
            "user_id" : userID,
            "generation_id" : generationID,
        });
        console.log("response from /pdf/delete_generation", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        } else if (response.data.status === 401) {
            return {
                "status" : 401,
            }
        }
    } catch (error) {
        console.log("/pdf/delete_generation error", error);
        return null;
    }
}


export const renameDocument = async (newName, userID, fileID) => {
    try {
        const response = await axios.post(SERVER_IP + '/pdf/rename', {
            "new_name" : newName.toString(),
            "user_id" : userID.toString(),
            "file_id" : fileID.toString(),
        });
        console.log("response from /pdf/rename", response)
        if (response.status === 200) {
            return {
                "status" : 200,
            }
        } else if (response.status === 400) {
            return {
                "status" : 400,
            }
        }
    } catch (error) {
        console.log("/pdf/rename error", error);
        return null;
    }
}

export const renameGeneration = async (newName, userID, generationID) => {
    // console.log("renameGeneration newName payload", newName)
    // console.log("renameGeneration userID payload", userID)
    // console.log("renameGeneration generationID payload", generationID)
    try {
        const response = await axios.post(SERVER_IP + '/pdf/rename_generation', {
            "new_generation_title" : newName.toString(),
            "user_id" : userID.toString(),
            "generation_id" : generationID.toString(),
        });
        console.log("response from /pdf/rename_generation", response)
        if (response.status === 200) {
            return {
                "status" : 200,
            }
        } else if (response.status === 401) {
            return {
                "status" : 401,
            }
        }
    } catch (error) {
        console.log("/pdf/rename_generation error", error);
        return null;
    }
}

export const deleteAccount = async (userID) => {
    try {
        const response = await axios.post(SERVER_IP + '/delete_user', {
            "user_id" : userID.toString(),
        });
        console.log("response from /delete_user", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        } else if (response.data.status === 401) {
            return {
                "status" : 401,
            }
        }
    } catch (error) {
        console.log("/delete_user error", error);
        return null;
    }
}
