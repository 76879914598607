const lightTheme = {
    colors: {
        lightGray: 'rgba(241,241,241,1)',
        darkGray: 'rgba(226,226,226,1)',
        gray: 'rgba(233.5,233.5,233.5,1)',
        white: "rgba(255,255,255,1)",
        black: "rgba(0,0,0,1)",
        orange200: "#FBD38D",
        orange300: "#F6AD55",
        orange400: "#ED8936",
        orange500: "#DD6B20",
        orange600: "#C05621",
        darkYellow2: "#bf9000",
        blue400: "#4299E1",
    }
}

export default lightTheme;
