import {
    InputGroup,
    Input,
    InputLeftElement,
    Icon,
    InputRightElement,
    ListItem,
    List,
    Button,
    Flex
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {setDocuments} from "../../redux/reducers";
import {useTranslation} from "react-i18next";

export default function SearchBar() {
    const documents = useSelector(state => state.documents.savedDocuments)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation();

    const [searchValue, setSearchValue] = useState('');
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [isInputFocused, setIsInputFocused] = useState(false);

    // Function to handle input changes and update suggestions.
    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchValue(value); // Update the search value
        // Here, implement logic to filter documents based on the input and update suggestions.
        setSearchSuggestions(documents.filter((document) => document.name.toLowerCase().includes(searchValue.toLowerCase())));
    };

    // Function to clear the search input.
    const handleSearchClear = () => {
        setSearchValue('');
        setSearchSuggestions([]);
    };

    // Function to handle what happens when a suggestion is clicked.
    const handleSuggestionClick = (suggestion) => {
        navigate(`/pdf/${suggestion.id}`)
    };

    const handleSearchEnter = () => {
        // Function to calculate relevance of each document based on the search query.
        const calculateRelevance = (document) => {
            const index = document.name.toLowerCase().indexOf(searchValue.toLowerCase());
            // If the search query is not found, return a high index to sort these at the end.
            return index >= 0 ? index : 9999;
        };

        // Sort documents by relevance.
        const sortedDocuments = [...documents].sort((a, b) => {
            const relevanceA = calculateRelevance(a);
            const relevanceB = calculateRelevance(b);

            if (relevanceA !== relevanceB) {
                // If relevance is different, sort by it.
                return relevanceA - relevanceB;
            } else {
                // If relevance is the same, sort alphabetically as a secondary criterion.
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            }
        });
        dispatch(setDocuments(sortedDocuments));
    };

    return (
        <Flex direction="row" align="center" width={"93%"} justify={"center"}>
            <InputGroup width={'100%'} height={'76%'} position="relative" mb={1.5}>
                <InputLeftElement>
                    <Icon as={SearchIcon} color="gray.500" mt={1.5} cursor={"pointer"} onClick={() => {
                        handleSearchEnter()
                    }}/>
                </InputLeftElement>
                <Input
                    type="text"
                    value={searchValue}
                    onChange={handleSearchChange}
                    placeholder={t("Search for a PDF...")}
                    borderRadius="lg"
                    boxShadow="md"
                    py="1.33rem"
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => {
                        setTimeout(() => {
                            setIsInputFocused(false);
                        }, 125);
                    }}
                />
                {searchValue && (
                    <InputRightElement onClick={handleSearchClear}>
                        <Icon as={CloseIcon} color="gray.500" cursor={"pointer"} mt={1.5}/>
                    </InputRightElement>
                )}
                {searchSuggestions.length > 0 && isInputFocused && (
                    <List
                        position="absolute"
                        zIndex="1000"
                        top="100%"
                        left="0"
                        right="0"
                        mt="2"
                        maxH="26rem"
                        overflowY="auto"
                        boxShadow="xl"
                        borderRadius="md"
                        bg={'white'}
                    >
                        {searchSuggestions.map((suggestion) => (
                            <ListItem
                                key={suggestion.id}
                                px="4"
                                py="2"
                                cursor="pointer"
                                _hover={{ bg: 'gray.200' }}
                                onClick={() => {
                                    handleSuggestionClick(suggestion)
                                }}
                            >
                                {suggestion.name}
                            </ListItem>
                        ))}
                    </List>
                )}
            </InputGroup>
            {/*<Button*/}
            {/*    ml={2}*/}
            {/*    borderRadius="lg"*/}
            {/*    boxShadow="md"*/}
            {/*    onClick={() => {}}*/}
            {/*>*/}
            {/*    <Icon as={SearchIcon} />*/}
            {/*</Button>*/}
        </Flex>
    );
}



//    const handleSearchEnter = () => {
//         const sortedDocuments = [...documents].sort((a, b) => {
//             const relevanceA = calculateRelevance(a, searchValue);
//             const relevanceB = calculateRelevance(b, searchValue);
//             return relevanceA - relevanceB;
//         });
//
//         // Dispatch an action to set the sorted documents in Redux.
//         dispatch(setDocuments(sortedDocuments));
//
//
//     };
//
//     const levenshteinDistance = (a, b) => {
//         // Initialize matrix of zeros with the right dimensions
//         const distanceMatrix = Array(a.length + 1).fill(null).map(() => Array(b.length + 1).fill(null));
//
//         // Populate the first column of each row
//         for (let i = 0; i <= a.length; i += 1) distanceMatrix[i][0] = i;
//
//         // Populate the first row of each column
//         for (let j = 0; j <= b.length; j += 1) distanceMatrix[0][j] = j;
//
//         // Iterate through the grid calculating distances
//         for (let i = 1; i <= a.length; i += 1) {
//             for (let j = 1; j <= b.length; j += 1) {
//                 const indicator = a[i - 1] === b[j - 1] ? 0 : 1;
//                 distanceMatrix[i][j] = Math.min(
//                     distanceMatrix[i][j - 1] + 1, // deletion
//                     distanceMatrix[i - 1][j] + 1, // insertion
//                     distanceMatrix[i - 1][j - 1] + indicator, // substitution
//                 );
//             }
//         }
//
//         return distanceMatrix[a.length][b.length];
//     };
//
//     const calculateRelevance = (document, query) => {
//         const docNameLower = document.name.toLowerCase();
//         const queryLower = query.toLowerCase();
//
//         // Exact match
//         if (docNameLower === queryLower) {
//             return -1000; // give a large negative score to rank these highest
//         }
//
//         // Calculate Levenshtein distance
//         const distance = levenshteinDistance(docNameLower, queryLower);
//
//         // Find the position of the match
//         const index = docNameLower.indexOf(queryLower);
//         const lengthMatch = queryLower.length;
//
//         // Calculate the score
//         let score = distance * 100;
//
//         // Adjust score based on position and length of match (if any)
//         if (index >= 0) {
//             score += index; // lower index (closer to start) is better
//             score -= lengthMatch * 10; // longer match is better
//         }
//
//         return score;
//     };
