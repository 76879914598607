import React, { useCallback, useRef, useState } from 'react';
import {
    Box,
    HStack,
    Text,
    VStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Image,
    IconButton,
    Grid,
    Progress,
    Tooltip, Heading, Stack, List, ListItem, ListIcon, Button, Divider, useColorModeValue, GridItem, useBreakpointValue,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { DeleteIcon, InfoIcon } from '@chakra-ui/icons';
import lightTheme from "../../utils/styles";
import {IoMdCheckmark} from "react-icons/io";
import LogoCarousel from "../../components/Reuseable/LogoCarousel";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../redux/AuthProvider";
import {createCheckoutSessionPhoto, createFormID} from "../../utils/localStoragePhoto";
import {useNavigate} from "react-router-dom";


const PreviewStep = () => {
    const {t} = useTranslation();
    const [selectedPackage, setSelectedPackage] = useState('basic');
    const [continueLoading, setContinueLoading] = useState(false);
    const auth = useAuth();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const [freeSampleLoading, setFreeSampleLoading] = useState(false);
    const navigate = useNavigate();

    const images = [
        "/1.png",
        "/3.png",
        "/20.png",
        "/10.png",
        "/14.png",
        "/12.png",
        "/18.png",
        "/6.png",

    ]

    return (
        <Box bg="white" width="100%" height="100%" display="flex" justifyContent="center">
            {isMobile || breakpoint === 'md' ?
                localStorage.getItem('hasSeen') !== 'true' ?
                    <VStack align="center" p={4} spacing={4} pt={0} mt={-2} height={'230%'}>
                        <Text fontSize="2xl" fontWeight="bold" textAlign={'center'}>
                            Want to see some sample photos?
                        </Text>
                        <Button
                            rounded={'full'}
                            fontSize={16}
                            px={7}
                            py={7}
                            mt={1}
                            mb={3}
                            colorScheme={'orange'}
                            bg={'orange.400'}
                            _hover={{ bg: 'orange.500' }}
                            isLoading={freeSampleLoading}
                            onClick={() => {
                                setFreeSampleLoading(false);
                                navigate("/free-sample");
                            }}>
                            {t('Try a Free Sample')}
                        </Button>
                        <Divider width={'100%'} borderColor={'gray.300'}/>
                        <VStack spacing={1} align={'center'} mb={-2} mt={3}>
                            <Text fontWeight={500} fontSize={17} textAlign={'center'}>
                                Or select a package for full-quality photos
                            </Text>
                        </VStack>
                        <VStack py={0} spacing={0} width={'100%'}>
                            <Stack
                                direction={{ base: 'column', md: 'row' }}
                                textAlign="center"
                                justify="center"
                                spacing={{ base: 9, lg: 9 }}
                                mt={-5}
                                py={10}>


                                <Box
                                    mb={0}
                                    shadow="base"
                                    borderWidth="1px"
                                    height={'26rem'}
                                    width={breakpoint === 'lg' ? '17rem' : '18.5rem'}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'flex-start'}
                                    alignItems={'flex-start'}
                                    borderColor={selectedPackage === 'basic' ? 'orange.400' : 'gray.200'}
                                    _hover={{
                                        shadow: 'md',
                                        borderColor: 'orange.400',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setSelectedPackage('basic');
                                        localStorage.setItem('selectedPlanPhoto', 'basic');
                                    }}
                                    borderRadius={'xl'}>
                                    <Box px={6} py={5} height={'40%'} textAlign="left">
                                        <Text fontWeight="500" fontSize={28} color={selectedPackage === 'basic' ? 'orange.400' : 'black'}>
                                            Basic
                                        </Text>
                                        <HStack spacing={1} mt={-1.5}>
                                            <Text fontSize="3xl" fontWeight="500" color={selectedPackage === 'basic' ? 'orange.400' : 'black'}>
                                                $
                                            </Text>
                                            <Text fontSize="5xl" fontWeight="600" color={selectedPackage === 'basic' ? 'orange.400' : 'black'}>
                                                10
                                            </Text>
                                        </HStack>
                                        <Text fontWeight="400" color={'gray'} fontSize={16}>
                                            {t("one-time payment")}
                                        </Text>
                                    </Box>
                                    <VStack
                                        bg={'white'}
                                        borderTopColor={'lightgray'}
                                        borderTopWidth={1}
                                        py={4}
                                        pt={7}
                                        mx={6}
                                        justify={'space-between'}
                                        height={'60%'}
                                        borderBottomRadius={'xl'}>
                                        <List spacing={3} textAlign="start">
                                            <ListItem fontSize={15.5}>
                                                <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                {t("15 high-quality headshots")}
                                            </ListItem>
                                            <ListItem fontSize={15.5}>
                                                <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                {t("24-hour turnaround time")}
                                            </ListItem>
                                        </List>
                                    </VStack>
                                </Box>
                                {/*/////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                                <Box
                                    mb={0}
                                    shadow="base"
                                    borderWidth="1px"
                                    height={'26rem'}
                                    width={breakpoint === 'lg' ? '18rem' : '19.5rem'}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'flex-start'}
                                    alignItems={'flex-start'}
                                    borderColor={selectedPackage === 'pro' ? 'orange.400' : 'gray.200'}
                                    _hover={{
                                        shadow: 'md',
                                        borderColor: 'orange.400',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setSelectedPackage('pro')
                                        localStorage.setItem('selectedPlanPhoto', 'pro');
                                    }}
                                    borderRadius={'xl'}>
                                    <Box px={6} py={5} height={'40%'} textAlign="left">
                                        <Text fontWeight="500" fontSize={28} color={selectedPackage === 'pro' ? 'orange.400' : 'black'}>
                                            Pro
                                        </Text>
                                        <HStack spacing={1} mt={-1.5}>
                                            <Text fontSize="3xl" fontWeight="500" color={selectedPackage === 'pro' ? 'orange.400' : 'black'}>
                                                $
                                            </Text>
                                            <Text fontSize="5xl" fontWeight="600" color={selectedPackage === 'pro' ? 'orange.400' : 'black'}>
                                                20
                                            </Text>
                                        </HStack>
                                        <Text fontWeight="400" color={'gray'} fontSize={16}>
                                            {t("one-time payment")}
                                        </Text>
                                    </Box>
                                    <VStack
                                        bg={'white'}
                                        borderTopColor={'lightgray'}
                                        borderTopWidth={1}
                                        py={4}
                                        pt={7}
                                        mx={6}
                                        justify={'space-between'}
                                        height={'60%'}
                                        borderBottomRadius={'xl'}>
                                        <List spacing={3} textAlign="start">
                                            <ListItem fontSize={15.5}>
                                                <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                {t("30 high-quality headshots")}
                                            </ListItem>
                                            <ListItem fontSize={15.5}>
                                                <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                {t("12-hour turnaround time")}
                                            </ListItem>
                                            <ListItem fontSize={15.5}>
                                                <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                {t("More diverse backgrounds and poses, i.e. you holding a dog.")}
                                            </ListItem>
                                            {/*<ListItem fontSize={15.5}>*/}
                                            {/*    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>*/}
                                            {/*    {t("Access to future Pro features")}*/}
                                            {/*</ListItem>*/}
                                        </List>
                                    </VStack>
                                </Box>
                            </Stack>
                        </VStack>
                        <Box width={{ base: '100%', md: '45%' }} height="100%" mb={isMobile || breakpoint === 'md' ? '5rem' : '0rem'} display={'flex'}
                             flexDirection={'column'} bg={'white'}
                             alignItems={'center'} justifyContent={'center'}>

                            <Grid templateColumns="repeat(4, 1fr)" gap={3} mt={-20}>
                                {images.map((src, index) => (
                                    <GridItem key={index} colSpan={1}>
                                        <Image src={src} borderRadius="lg" />
                                    </GridItem>
                                ))}
                            </Grid>

                            <VStack mt={8}>
                                <Text fontSize={22} color={'black'} fontWeight={500} textAlign={'center'}>
                                    "Photomax is much less expensive than other services, but still offers the same quality—if not better!"
                                </Text>
                                <Text fontSize={'lg'} fontWeight={'normal'} color={'black'} mt={1.5}>
                                    - Janelle R.
                                </Text>
                            </VStack>

                        </Box>
                    </VStack> :


                <VStack mt={0} bg="white" spacing={10}>
                    <Box width={{ base: '100%', md: '65%' }} bg="white" borderRightWidth={1} borderRightColor={'lightgray'} pr={4}>
                        <VStack align="center" p={4} spacing={4} pt={0}>
                            <Text fontSize="2xl" fontWeight="bold" textAlign={'center'}>
                                Amazing headshots are waiting for you!
                            </Text>
                            <VStack spacing={1} align={'center'} mb={-2} mt={3}>
                                <Text fontWeight={500} fontSize={17} textAlign={"center"}>
                                    Select a package.
                                </Text>
                                <Text fontWeight={'normal'} fontSize={15} textAlign={'center'}>
                                    We offer a package for every budget. Pay once, no subscriptions or hidden fees.
                                </Text>
                            </VStack>
                            <VStack py={0} spacing={0} width={'100%'}>
                                <Stack
                                    direction={{ base: 'column', sm: 'column', md: 'column' }}
                                    textAlign="center"
                                    justify="center"
                                    spacing={{ base: 9, lg: 9 }}
                                    mt={-5}
                                    py={10}>


                                    <Box
                                        mb={0}
                                        shadow="base"
                                        borderWidth="1px"
                                        height={'24rem'}
                                        width={'18rem'}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        justifyContent={'flex-start'}
                                        alignItems={'flex-start'}
                                        borderColor={selectedPackage === 'basic' ? 'orange.400' : 'gray.200'}
                                        _hover={{
                                            shadow: 'md',
                                            borderColor: 'orange.400',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setSelectedPackage('basic');
                                            localStorage.setItem('selectedPlanPhoto', 'basic');
                                        }}
                                        borderRadius={'xl'}>
                                        <Box px={6} py={5} height={'40%'} textAlign="left" mb={4}>
                                            <Text fontWeight="500" fontSize={28} color={selectedPackage === 'basic' ? 'orange.400' : 'black'}>
                                                Basic
                                            </Text>
                                            <HStack spacing={1} mt={-1.5}>
                                                <Text fontSize="3xl" fontWeight="500" color={selectedPackage === 'basic' ? 'orange.400' : 'black'}>
                                                    $
                                                </Text>
                                                <Text fontSize="5xl" fontWeight="600" color={selectedPackage === 'basic' ? 'orange.400' : 'black'}>
                                                    10
                                                </Text>
                                            </HStack>
                                            <Text fontWeight="400" color={'gray'} fontSize={16}>
                                                {t("one-time payment")}
                                            </Text>
                                        </Box>
                                        <VStack
                                            bg={'white'}
                                            borderTopColor={'lightgray'}
                                            borderTopWidth={1}
                                            py={4}
                                            pt={7}
                                            mx={6}
                                            justify={'space-between'}
                                            height={'60%'}
                                            borderBottomRadius={'xl'}>
                                            <List spacing={3} textAlign="start">
                                                <ListItem fontSize={15.5}>
                                                    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                    {t("15 high-quality headshots")}
                                                </ListItem>
                                                <ListItem fontSize={15.5}>
                                                    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                    {t("24-hour turnaround time")}
                                                </ListItem>
                                            </List>
                                        </VStack>
                                    </Box>



                                    {/*/////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                                    <Box
                                        mb={0}
                                        shadow="base"
                                        borderWidth="1px"
                                        height={'24rem'}
                                        width={'20rem'}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        justifyContent={'flex-start'}
                                        alignItems={'flex-start'}
                                        borderColor={selectedPackage === 'pro' ? 'orange.400' : 'gray.200'}
                                        _hover={{
                                            shadow: 'md',
                                            borderColor: 'orange.400',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setSelectedPackage('pro')
                                            localStorage.setItem('selectedPlanPhoto', 'pro');
                                        }}
                                        borderRadius={'xl'}>
                                        <Box px={6} py={5} height={'40%'} textAlign="left" mb={4}>
                                            <Text fontWeight="500" fontSize={28} color={selectedPackage === 'pro' ? 'orange.400' : 'black'}>
                                                Pro
                                            </Text>
                                            <HStack spacing={1} mt={-1.5}>
                                                <Text fontSize="3xl" fontWeight="500" color={selectedPackage === 'pro' ? 'orange.400' : 'black'}>
                                                    $
                                                </Text>
                                                <Text fontSize="5xl" fontWeight="600" color={selectedPackage === 'pro' ? 'orange.400' : 'black'}>
                                                    20
                                                </Text>
                                            </HStack>
                                            <Text fontWeight="400" color={'gray'} fontSize={16}>
                                                {t("one-time payment")}
                                            </Text>
                                        </Box>
                                        <VStack
                                            bg={'white'}
                                            borderTopColor={'lightgray'}
                                            borderTopWidth={1}
                                            py={4}
                                            pt={7}
                                            mx={6}
                                            justify={'space-between'}
                                            height={'60%'}
                                            borderBottomRadius={'xl'}>
                                            <List spacing={3} textAlign="start">
                                                <ListItem fontSize={15.5}>
                                                    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                    {t("30 high-quality headshots")}
                                                </ListItem>
                                                <ListItem fontSize={15.5}>
                                                    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                    {t("12-hour turnaround time")}
                                                </ListItem>
                                                <ListItem fontSize={15.5}>
                                                    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                    {t("More diverse backgrounds and poses, i.e. you holding a dog.")}
                                                </ListItem>
                                                {/*<ListItem fontSize={15.5}>*/}
                                                {/*    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>*/}
                                                {/*    {t("Access to future Pro features")}*/}
                                                {/*</ListItem>*/}
                                            </List>
                                        </VStack>
                                    </Box>




                                </Stack>
                                {/*<LogoCarousel/>*/}
                            </VStack>








                        </VStack>
                    </Box>
                    <Box width={{ base: '100%', md: '45%' }} height="100%" mb={isMobile || breakpoint === 'md' ? '5rem' : '0rem'} display={'flex'}
                         flexDirection={'column'} bg={'white'}
                         alignItems={'center'} justifyContent={'center'}>

                        <Grid templateColumns="repeat(4, 1fr)" gap={3} mt={-16}>
                            {images.map((src, index) => (
                                <GridItem key={index} colSpan={1}>
                                    <Image src={src} borderRadius="lg" />
                                </GridItem>
                            ))}
                        </Grid>

                        <VStack mt={8}>
                            <Text fontSize={22} color={'black'} fontWeight={500} textAlign={'center'}>
                                "Photomax is much less expensive than other services, but still offers the same quality—if not better!"
                            </Text>
                            <Text fontSize={'lg'} fontWeight={'normal'} color={'black'} mt={1.5}>
                                - Janelle R.
                            </Text>
                        </VStack>

                    </Box>
                </VStack>

                :





                <HStack mt={0} bg="white" width={['100%', '100%', '100%', '100%', '90%', '85%']} height="100%" spacing={10}>
                    <Box width={{ base: '100%', md: '65%' }} height="100%" bg="white" borderRightWidth={1} borderRightColor={'lightgray'} pr={4}>
                        {localStorage.getItem("hasSeen") === 'true' ?
                            <VStack align="center" p={4} spacing={4} pt={0}>
                                <Text fontSize="2xl" fontWeight="bold">
                                    Amazing headshots are waiting for you!
                                </Text>
                                <VStack spacing={1} align={'center'}>
                                    <Text fontWeight={500} fontSize={17}>
                                        Select a package.
                                    </Text>
                                    <Text fontWeight={'normal'} fontSize={15}>
                                        We offer a package for every budget. Pay once, no subscriptions or hidden fees.
                                    </Text>
                                </VStack>
                                <VStack py={0} spacing={0} width={'100%'}>
                                    <Stack
                                        direction={{ base: 'column', md: 'row' }}
                                        textAlign="center"
                                        justify="center"
                                        spacing={{ base: 9, lg: 9 }}
                                        mt={-5}
                                        py={10}>
                                        <Box
                                            mb={0}
                                            shadow="base"
                                            borderWidth="1px"
                                            height={'26rem'}
                                            width={breakpoint === 'lg' ? '17rem' : '18.5rem'}
                                            display={'flex'}
                                            flexDirection={'column'}
                                            justifyContent={'flex-start'}
                                            alignItems={'flex-start'}
                                            borderColor={selectedPackage === 'basic' ? 'orange.400' : 'gray.200'}
                                            _hover={{
                                                shadow: 'md',
                                                borderColor: 'orange.400',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setSelectedPackage('basic');
                                                localStorage.setItem('selectedPlanPhoto', 'basic');
                                            }}
                                            borderRadius={'xl'}>
                                            <Box px={6} py={5} height={'40%'} textAlign="left">
                                                <Text fontWeight="500" fontSize={28} color={selectedPackage === 'basic' ? 'orange.400' : 'black'}>
                                                    Basic
                                                </Text>
                                                <HStack spacing={1} mt={-1.5}>
                                                    <Text fontSize="3xl" fontWeight="500" color={selectedPackage === 'basic' ? 'orange.400' : 'black'}>
                                                        $
                                                    </Text>
                                                    <Text fontSize="5xl" fontWeight="600" color={selectedPackage === 'basic' ? 'orange.400' : 'black'}>
                                                        10
                                                    </Text>
                                                </HStack>
                                                <Text fontWeight="400" color={'gray'} fontSize={16}>
                                                    {t("one-time payment")}
                                                </Text>
                                            </Box>
                                            <VStack
                                                bg={'white'}
                                                borderTopColor={'lightgray'}
                                                borderTopWidth={1}
                                                py={4}
                                                pt={7}
                                                mx={6}
                                                justify={'space-between'}
                                                height={'60%'}
                                                borderBottomRadius={'xl'}>
                                                <List spacing={3} textAlign="start">
                                                    <ListItem fontSize={15.5}>
                                                        <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                        {t("15 high-quality headshots")}
                                                    </ListItem>
                                                    <ListItem fontSize={15.5}>
                                                        <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                        {t("24-hour turnaround time")}
                                                    </ListItem>
                                                </List>
                                            </VStack>
                                        </Box>
                                        {/*/////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                                        <Box
                                            mb={0}
                                            shadow="base"
                                            borderWidth="1px"
                                            height={'26rem'}
                                            width={breakpoint === 'lg' ? '18rem' : '19.5rem'}
                                            display={'flex'}
                                            flexDirection={'column'}
                                            justifyContent={'flex-start'}
                                            alignItems={'flex-start'}
                                            borderColor={selectedPackage === 'pro' ? 'orange.400' : 'gray.200'}
                                            _hover={{
                                                shadow: 'md',
                                                borderColor: 'orange.400',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setSelectedPackage('pro')
                                                localStorage.setItem('selectedPlanPhoto', 'pro');
                                            }}
                                            borderRadius={'xl'}>
                                            <Box px={6} py={5} height={'40%'} textAlign="left">
                                                <Text fontWeight="500" fontSize={28} color={selectedPackage === 'pro' ? 'orange.400' : 'black'}>
                                                    Pro
                                                </Text>
                                                <HStack spacing={1} mt={-1.5}>
                                                    <Text fontSize="3xl" fontWeight="500" color={selectedPackage === 'pro' ? 'orange.400' : 'black'}>
                                                        $
                                                    </Text>
                                                    <Text fontSize="5xl" fontWeight="600" color={selectedPackage === 'pro' ? 'orange.400' : 'black'}>
                                                        20
                                                    </Text>
                                                </HStack>
                                                <Text fontWeight="400" color={'gray'} fontSize={16}>
                                                    {t("one-time payment")}
                                                </Text>
                                            </Box>
                                            <VStack
                                                bg={'white'}
                                                borderTopColor={'lightgray'}
                                                borderTopWidth={1}
                                                py={4}
                                                pt={7}
                                                mx={6}
                                                justify={'space-between'}
                                                height={'60%'}
                                                borderBottomRadius={'xl'}>
                                                <List spacing={3} textAlign="start">
                                                    <ListItem fontSize={15.5}>
                                                        <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                        {t("30 high-quality headshots")}
                                                    </ListItem>
                                                    <ListItem fontSize={15.5}>
                                                        <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                        {t("12-hour turnaround time")}
                                                    </ListItem>
                                                    <ListItem fontSize={15.5}>
                                                        <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                        {t("More diverse backgrounds and poses, i.e. you holding a dog.")}
                                                    </ListItem>
                                                    <ListItem fontSize={15.5}>
                                                        <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                        {t("Access to future Pro features")}
                                                    </ListItem>
                                                </List>
                                            </VStack>
                                        </Box>
                                    </Stack>
                                </VStack>
                            </VStack> :



                        <VStack align="center" p={4} spacing={4} pt={0} mt={-2}>
                            <Text fontSize="2xl" fontWeight="bold">
                                Want to see some sample photos?
                            </Text>
                            <Button
                                rounded={'full'}
                                fontSize={16}
                                px={7}
                                py={7}
                                mt={1}
                                mb={3}
                                colorScheme={'orange'}
                                bg={'orange.400'}
                                _hover={{ bg: 'orange.500' }}
                                isLoading={freeSampleLoading}
                                onClick={() => {
                                    setFreeSampleLoading(false);
                                    navigate("/free-sample");
                                }}>
                                {t('Try a Free Sample')}
                            </Button>
                            <Divider width={'100%'} borderColor={'gray.300'}/>
                            <VStack spacing={1} align={'center'} mb={-2} mt={3}>
                                <Text fontWeight={500} fontSize={17}>
                                    Or select a package for full-quality photos
                                </Text>
                            </VStack>
                            <VStack py={0} spacing={0} width={'100%'}>
                                <Stack
                                    direction={{ base: 'column', md: 'row' }}
                                    textAlign="center"
                                    justify="center"
                                    spacing={{ base: 9, lg: 9 }}
                                    mt={-5}
                                    py={10}>


                                    <Box
                                        mb={0}
                                        shadow="base"
                                        borderWidth="1px"
                                        height={'26rem'}
                                        width={breakpoint === 'lg' ? '17rem' : '18.5rem'}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        justifyContent={'flex-start'}
                                        alignItems={'flex-start'}
                                        borderColor={selectedPackage === 'basic' ? 'orange.400' : 'gray.200'}
                                        _hover={{
                                            shadow: 'md',
                                            borderColor: 'orange.400',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setSelectedPackage('basic');
                                            localStorage.setItem('selectedPlanPhoto', 'basic');
                                        }}
                                        borderRadius={'xl'}>
                                        <Box px={6} py={5} height={'40%'} textAlign="left">
                                            <Text fontWeight="500" fontSize={28} color={selectedPackage === 'basic' ? 'orange.400' : 'black'}>
                                                Basic
                                            </Text>
                                            <HStack spacing={1} mt={-1.5}>
                                                <Text fontSize="3xl" fontWeight="500" color={selectedPackage === 'basic' ? 'orange.400' : 'black'}>
                                                    $
                                                </Text>
                                                <Text fontSize="5xl" fontWeight="600" color={selectedPackage === 'basic' ? 'orange.400' : 'black'}>
                                                    10
                                                </Text>
                                            </HStack>
                                            <Text fontWeight="400" color={'gray'} fontSize={16}>
                                                {t("one-time payment")}
                                            </Text>
                                        </Box>
                                        <VStack
                                            bg={'white'}
                                            borderTopColor={'lightgray'}
                                            borderTopWidth={1}
                                            py={4}
                                            pt={7}
                                            mx={6}
                                            justify={'space-between'}
                                            height={'60%'}
                                            borderBottomRadius={'xl'}>
                                            <List spacing={3} textAlign="start">
                                                <ListItem fontSize={15.5}>
                                                    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                    {t("15 high-quality headshots")}
                                                </ListItem>
                                                <ListItem fontSize={15.5}>
                                                    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                    {t("24-hour turnaround time")}
                                                </ListItem>
                                            </List>
                                        </VStack>
                                    </Box>
                                    {/*/////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                                    <Box
                                        mb={0}
                                        shadow="base"
                                        borderWidth="1px"
                                        height={'26rem'}
                                        width={breakpoint === 'lg' ? '18rem' : '19.5rem'}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        justifyContent={'flex-start'}
                                        alignItems={'flex-start'}
                                        borderColor={selectedPackage === 'pro' ? 'orange.400' : 'gray.200'}
                                        _hover={{
                                            shadow: 'md',
                                            borderColor: 'orange.400',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setSelectedPackage('pro')
                                            localStorage.setItem('selectedPlanPhoto', 'pro');
                                        }}
                                        borderRadius={'xl'}>
                                        <Box px={6} py={5} height={'40%'} textAlign="left">
                                            <Text fontWeight="500" fontSize={28} color={selectedPackage === 'pro' ? 'orange.400' : 'black'}>
                                                Pro
                                            </Text>
                                            <HStack spacing={1} mt={-1.5}>
                                                <Text fontSize="3xl" fontWeight="500" color={selectedPackage === 'pro' ? 'orange.400' : 'black'}>
                                                    $
                                                </Text>
                                                <Text fontSize="5xl" fontWeight="600" color={selectedPackage === 'pro' ? 'orange.400' : 'black'}>
                                                    20
                                                </Text>
                                            </HStack>
                                            <Text fontWeight="400" color={'gray'} fontSize={16}>
                                                {t("one-time payment")}
                                            </Text>
                                        </Box>
                                        <VStack
                                            bg={'white'}
                                            borderTopColor={'lightgray'}
                                            borderTopWidth={1}
                                            py={4}
                                            pt={7}
                                            mx={6}
                                            justify={'space-between'}
                                            height={'60%'}
                                            borderBottomRadius={'xl'}>
                                            <List spacing={3} textAlign="start">
                                                <ListItem fontSize={15.5}>
                                                    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                    {t("30 high-quality headshots")}
                                                </ListItem>
                                                <ListItem fontSize={15.5}>
                                                    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                    {t("12-hour turnaround time")}
                                                </ListItem>
                                                <ListItem fontSize={15.5}>
                                                    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>
                                                    {t("More diverse backgrounds and poses, i.e. you holding a dog.")}
                                                </ListItem>
                                                {/*<ListItem fontSize={15.5}>*/}
                                                {/*    <ListIcon as={IoMdCheckmark} color="black" fontSize={18}/>*/}
                                                {/*    {t("Access to future Pro features")}*/}
                                                {/*</ListItem>*/}
                                            </List>
                                        </VStack>
                                    </Box>
                                </Stack>
                            </VStack>
                        </VStack>
                        }




                    </Box>
                    <Box width={{ base: '100%', md: '45%' }} display={'flex'} flexDirection={'column'} bg={'white'}
                         alignItems={'center'} justifyContent={'center'}>
                        {/*<Text fontSize={'3xl'} color={'orange.500'} fontWeight={650}>*/}
                        {/*    1 million+*/}
                        {/*</Text>*/}
                        {/*<Text fontSize={'lg'} fontWeight={600} color={'black'} mt={1}>*/}
                        {/*    AI headshots for happy customers*/}
                        {/*</Text>*/}

                        <Grid templateColumns="repeat(4, 1fr)" gap={3} mt={isMobile ? 2 : -16}>
                            {images.map((src, index) => (
                                <GridItem key={index} colSpan={1}>
                                    <Image src={src} borderRadius="lg" />
                                </GridItem>
                            ))}
                        </Grid>

                        <VStack mt={8}>
                            <Text fontSize={22} color={'black'} fontWeight={500} textAlign={'center'}>
                                "Photomax is much less expensive than other services, but still offers the same quality—if not better!"
                            </Text>
                            <Text fontSize={'lg'} fontWeight={'normal'} color={'black'} mt={1.5}>
                                - Janelle R.
                            </Text>
                        </VStack>

                    </Box>
                </HStack>}








            <Box
                position="fixed"
                bottom={0}
                width="100vw"
                right={0}
                height="13vh"
                bg="white"
                textAlign="center"
                pt={8}
                boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
            >
                <Button
                    onClick={() => {
                        setContinueLoading(true)
                        // then call the ROUTE TO GET TO STRIPE CHECKOUT PAGE
                        const formID = localStorage.getItem('formIDPhoto');
                        const email = localStorage.getItem('emailPhoto');
                        createCheckoutSessionPhoto(formID, email, selectedPackage).then(r => {
                            setContinueLoading(false)
                            window.location.href = r.url;
                        })




                    }}
                    isLoading={continueLoading}
                    size="lg"
                    width={isMobile ? '66%' : '15%'}
                    borderRadius={50}
                    bgGradient="linear(to-r, red.400,pink.400)"
                    color="white"
                    _hover={{
                        bgGradient: 'linear(to-r, red.400,pink.400)',
                        boxShadow: 'xl',
                    }}
                >
                    Continue
                </Button>
            </Box>
        </Box>
    );
};

export default PreviewStep;
