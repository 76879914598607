'use client'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Flex,
    Text,
    Container, Heading, Box, Link, HStack
} from '@chakra-ui/react'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {useState} from 'react';
import {useTranslation} from "react-i18next";

export default function FAQ() {
    const {t} = useTranslation();
    const [expandedIndex, setExpandedIndex] = useState(null);

    const faqData = [
        {
            question: t("What type of photos should I upload?"),
            answer: t("Please upload any high-quality photos that are clear and focused to ensure the best results."),
        },
        {
            question: t("What is the resolution of your pictures?"),
            answer: t("Our advanced AI headshot generator creates photos at a resolution of 1024×1024 pixels (72 dpi), surpassing the requirements for most applications. For instance, LinkedIn recommends photos of just 400×400 pixels."),
        },
        {
            question: t("What rights do I have over my photos?"),
            answer: t("You retain full rights to your photos. We will not use them without your consent."),
        },
        {
            question: t("How do you handle my data?"),
            answer: t("We prioritize data privacy. Your data is encrypted and securely stored. We do not share it with third parties."),
        },
        {
            question: t("I love your product! How can I help promote it?"),
            answer: t("Thank you! You can share your positive experience on social media and leave us a review."),
        },
    ];


    const handleAccordionToggle = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <HStack
            display={'flex'}
            alignItems={'flex-start'}
            // justifyContent={'space-between'}
            width={'110%'}
            flexDirection={{ base: 'column', md: 'row' }}
            mb={'5rem'}
            bg={'white'}
            px={4}
            py={10}
            spacing={20}
        >
            <Box>
                <Heading as="h1" fontSize="4xl" mb={'1rem'} fontWeight={650} textAlign={'left'}>
                    {t("Frequently asked questions")}
                </Heading>
                <Text fontSize="md" color="gray.600" mb={'1rem'} textAlign={'left'}>
                    If you have more questions, you can email us at <Link color="orange.400" href="mailto:contact@photomax.ai">contact@photomax.ai</Link>. We're here to help!
                </Text>
            </Box>
            <Container>
                <Accordion allowMultiple bg="white" rounded="lg" border="none">
                    {faqData.map((faq, index) => (
                        <AccordionItem key={index} border="none">
                            <h2>
                                <AccordionButton
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    px={4}
                                    py={5}
                                    _hover={{ bg: 'white' }}
                                    onClick={() => handleAccordionToggle(index)}
                                >
                                    <Text fontSize="lg" color="black" fontWeight={'500'} textAlign={'left'}>
                                        {faq.question}
                                    </Text>
                                    {expandedIndex === index ? (
                                        <ChevronUpIcon fontSize="24px" />
                                    ) : (
                                        <ChevronDownIcon fontSize="24px" />
                                    )}
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pt={2} pb={5} px={4} bg="white" borderBottom="1px solid" borderColor="gray.200">
                                <Text color="gray.700" fontSize="md" textAlign={'left'}>
                                    {faq.answer}
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </Container>
        </HStack>
    );
}
