'use client'

import {
    Box,
    chakra,
    Container,
    SimpleGrid,
    Stack,
    Text,
    VisuallyHidden,
    Input,
    IconButton,
    Link,
    useColorModeValue, useToast, Button, Image,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import { BiMailSend } from 'react-icons/bi'
import lightTheme from "../../utils/styles";
import {contactRoute} from "../../utils/api";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SocialButton = ({
                          children,
                          label,
                          href,
                      }: {
    children: ReactNode
    label: string
    href: string
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    )
}

const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    )
}

export default function LargeWithNewsletter({ navigateToPricing = () => {} }) {
    const toast = useToast()
    const [emailAddress, setEmailAddress] = React.useState("")
    const navigate = useNavigate()
    const {t} = useTranslation()

    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}>
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid
                    templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
                    spacing={8}>
                    <Stack spacing={6}>
                        <a href="https://www.photomax.ai" rel="noopener noreferrer">
                            <Image
                                src="/Official_Logo_Photomax_Transparent.jpg"
                                alt="Logo"
                                height="34px"
                                ml={-1}
                            />
                        </a>
                        <Text fontSize={'sm'}>© 2024 Photomax.ai. All rights reserved.</Text>
                        <Stack direction={'row'} spacing={6}>
                            <SocialButton label={'Twitter'} href={'https://x.com/photomax_ai'}>
                                <FaTwitter/>
                            </SocialButton>
                            {/*<SocialButton label={'YouTube'} href={'#'}>*/}
                            {/*    <FaYoutube />*/}
                            {/*</SocialButton>*/}
                            <SocialButton label={'Instagram'} href={'https://www.instagram.com/photomax.ai/'}>
                                <FaInstagram />
                            </SocialButton>
                        </Stack>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>{t("Company")}</ListHeader>
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    About us*/}
                        {/*</Box>*/}
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    Blog*/}
                        {/*</Box>*/}
                        <a href="https://forms.gle/E6tgVhHWWtGov4Lb7" target="_blank" rel="noopener noreferrer">
                            {t("Contact us")}
                        </a>
                        <Box as="a" cursor={"pointer"} onClick={navigateToPricing}>
                            {t("Pricing")}
                        </Box>
                        <a href="https://forms.gle/vLCTVAG3dwf338YA9" target="_blank" rel="noopener noreferrer">
                            {t("Share Feedback")}
                        </a>
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    Testimonials*/}
                        {/*</Box>*/}
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>{t("Support")}</ListHeader>
                        <a href="https://forms.gle/E6tgVhHWWtGov4Lb7" target="_blank" rel="noopener noreferrer">
                            {t("Help")}
                        </a>
                        <Box as="a" href={'https://docs.google.com/document/d/1Dcvvsb-2wq7776uhmrsyg6IcjAISG9mNQe_eE-0uDQc/edit?usp=sharing'}>
                            {t("Terms of Service")}
                        </Box>
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    Legal*/}
                        {/*</Box>*/}
                        <Box as="a" href={'https://docs.google.com/document/d/15T9IpSVVqQr6uyDUQRyTcJW6JPyaqsSstTeLLN77QoU/edit?usp=sharing'}>
                            {t("Privacy Policy")}
                        </Box>
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    Satus*/}
                        {/*</Box>*/}
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>{t("Get Updates")}</ListHeader>
                        <Stack direction={'row'}>
                            <Input
                                placeholder={t("Your email address")}
                                value={emailAddress}
                                onChange={(e) => setEmailAddress(e.target.value)}
                                bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                                border={0}
                                _focus={{
                                    bg: 'whiteAlpha.300',
                                }}
                            />
                            <IconButton
                                bg={useColorModeValue('orange.400', 'orange.800')}
                                color={useColorModeValue('white', 'gray.800')}
                                _hover={{
                                    bg: 'orange.600',
                                }}
                                aria-label="Subscribe"
                                onClick={() => {
                                    if (emailAddress === "") {
                                        toast({
                                            title: t("Please enter an email address"),
                                            status: "error",
                                            duration: 5000,
                                            isClosable: true,
                                        })
                                        return
                                    } else {
                                        contactRoute("email", emailAddress).then(r => {
                                            setEmailAddress("")
                                            toast({
                                                title: t("Thanks for subscribing!"),
                                                status: "success",
                                                duration: 5000,
                                                isClosable: true,
                                            })
                                        })
                                    }
                                }}
                                icon={<BiMailSend />}
                            />
                        </Stack>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    )
}
