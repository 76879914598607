'use client'

import {
    Box,
    VStack,
    HStack,
    Text,
    Image,
    useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Marquee from "react-fast-marquee";

export default function LogoCarousel() {
    const { t } = useTranslation();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';

    const logos = [
        { src: "/amazon_logo.jpg", alt: "amazon Logo", height: "3.65rem" },
        { src: "/harvard_logo.jpg", alt: "Harvard Logo", height: "4rem" },
        { src: "/princeton_logo.jpg", alt: "Princeton Logo", height: "2.9rem" },
        { src: "/brown_logo.jpg", alt: "Brown Logo", height: "2.3rem" },

        // { src: "/melbourne_logo.png", alt: "University of Melbourne Logo", height: "3.85rem" },
        // { src: "/mit_logo.png", alt: "MIT Logo", height: "3.2rem" },
        // { src: "/yale_logo.png", alt: "Yale Logo", height: "3.4rem" },
        // { src: "/stanford_logo.png", alt: "Stanford Logo", height: "2.8rem" },
        // { src: "/oxford_logo.svg", alt: "University of Oxford Logo", height: "3.8rem" },
    ];

    return (
        <Box
            width="100vw"
            mb="3.3rem"
            justify="center"
            align="center"
            flexDirection={isMobile ? 'column' : 'row'}
            mt={1}
            display="flex"
            justifyContent="center"
            alignItems={isMobile ? 'center' : 'flex-start'}
            py="0rem"
            overflow="hidden"
            position="relative"
        >
            <VStack spacing={8}>
                <Text fontSize={14} fontWeight={600} color={'gray'}>
                    {t("TRUSTED BY LEADING PROFESSIONALS")}
                </Text>
                {isMobile ?
                    <VStack spacing={8} bg={'white'}>
                        <Image src="/amazon_logo.png" alt="amazon Logo" height={'2.1rem'} mt={1}/>
                        <Image src="/slack_logo.jpg" alt="slack Logo" height={'2rem'} />
                        <Image src="/yale_logo.png" alt="yale Logo" height={'2.1rem'} />
                        <Image src="/brown_logo.jpg" alt="Brown Logo" height={'1.3rem'} />
                    </VStack> :
                    <HStack spacing={10} bg={'white'}>
                        <Image src="/amazon_logo.png" alt="amazon Logo" height={'2.1rem'} mt={1}/>
                        <Image src="/slack_logo.jpg" alt="slack Logo" height={'2rem'} />
                        {breakpoint !== 'base' && breakpoint !== 'sm' && breakpoint !== 'md' ? <Image
                            src="/yale_logo.png" alt="yale Logo" height={'2.1rem'} /> : null}
                        <Image src="/brown_logo.jpg" alt="Brown Logo" height={'1.3rem'} />
                    </HStack>}
            </VStack>
        </Box>
    );
}
