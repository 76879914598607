import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Flex,
    Radio,
    RadioGroup,
    Stack,
    Text,
    useBreakpointValue,
    useToast,
    Wrap,
    WrapItem
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import {saveFormData} from "../../utils/localStoragePhoto";

const GenderStep = ({ value, onChange }) => {
    const options = ["Man", "Woman", "Non Binary"];
    const [selectedOption, setSelectedOption] = React.useState(value);
    const navigate = useNavigate();
    const toast = useToast();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const [mainButtonLoading, setMainButtonLoading] = React.useState(false);

    useEffect(() => {
        // Initialize selectedOption state based on the value prop
        setSelectedOption(value);
    }, [value]);

    useEffect(() => {
        if (localStorage.getItem("genderPhoto")) {
            setSelectedOption(localStorage.getItem("genderPhoto"));
        }
    }, []);

    return (
        <Box textAlign="center" mt={8}>
            <Text fontSize={isMobile ? 28 : 46} fontWeight="bold" mb={4} color="black">
                What's your gender?
            </Text>
            <Text fontSize={17} color="gray.800" mb={16}>
                Your responses will help us create the perfect photos for you
            </Text>
            <RadioGroup onChange={(option) => {
                onChange(option);
                setSelectedOption(option);
                localStorage.setItem('genderPhoto', option);
                // navigate("?step=age");
            }} value={selectedOption}>
                <Wrap justify="center" spacing={4} mb={8}>
                    {options.map((option) => (
                        <WrapItem key={option}>
                            <Button
                                key={option}
                                shadow="base"
                                borderWidth="1px"
                                bg="white"
                                py={6}
                                borderColor={selectedOption === option ? 'orange.400' : 'gray.200'}
                                _hover={{
                                    shadow: 'md',
                                    borderColor: 'orange.400',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    onChange(option);
                                    setSelectedOption(option);
                                    localStorage.setItem('genderPhoto', option);
                                    // navigate("?step=age");
                                }}
                            >
                                <Flex justifyContent="space-between" alignItems="center" width="100%">
                                    <Text mr={4}>{option}</Text>
                                    <Radio value={option} />
                                </Flex>
                            </Button>
                        </WrapItem>
                    ))}
                </Wrap>
            </RadioGroup>

            <Box
                position="absolute"
                bottom={0}
                width="100vw"
                right={0}
                height="13vh"
                bg="white"
                textAlign="center"
                pt={8}
                boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
            >
                <Button
                    onClick={() => {
                        if (selectedOption === '') {
                            toast({
                                title: "Please choose your gender.",
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                            });
                            return;
                        }
                        localStorage.setItem('genderPhoto', selectedOption);
                        setMainButtonLoading(true);
                        saveFormData(localStorage.getItem("formIDPhoto"), {
                            age: "25",
                            eye_color: "Brown",
                            hair_color: "Black",
                            ethnicity: "White",
                            gender: localStorage.getItem("genderPhoto"),
                            first_name: localStorage.getItem("firstNamePhoto"),
                            last_name: localStorage.getItem("lastNamePhoto"),
                            email: localStorage.getItem("emailPhoto"),
                        }).then(r => {
                            setMainButtonLoading(false);
                            navigate("?step=preview");
                            console.log("saveFormData", r)
                        })

                    }}
                    size="lg"
                    width={isMobile ? '66%' : '15%'}
                    borderRadius={50}
                    isLoading={mainButtonLoading}
                    bgGradient="linear(to-r, red.400,pink.400)"
                    color="white"
                    _hover={{
                        bgGradient: 'linear(to-r, red.400,pink.400)',
                        boxShadow: 'xl',
                    }}
                >
                    Continue
                </Button>
            </Box>
        </Box>
    );
};

export default GenderStep;
