import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation, useParams} from 'react-router-dom';
import WrapperComponent from "./pages/PDFPage/WrapperComponent";
import ThreeTierPricing from "./pages/Payments/ThreeTierPricing";
import {useAuth} from "./redux/AuthProvider";
import {useDispatch, useSelector} from "react-redux";
import UnifiedHomePage from "./pages/HomePage/UnifiedHomePage";
import {getFeatureFlag, setFeatureFlag} from "./utils/api";
import {setIsNewFeatureOpen} from "./redux/reducers";
import {useToast} from "@chakra-ui/react";
import DocumentGridGeneration from "./pages/HomePage/DocumentGridGeneration";
import WrapperComponentGeneration from "./pages/PDFPage/WrapperComponentGeneration";
import UnifiedHomePageGeneration from "./pages/HomePage/UnifiedHomePageGeneration";
import CheckoutResponse from "./pages/Payments/CheckoutResponse";
import Affiliate from "./pages/Affiliate/Affiliate";
import axios from "axios";
import {useTranslation} from "react-i18next";
import './i18n';
import MultiStepForm from "./pages/MultiStepForm/MultiStepForm";
import FreeSample from "./pages/MultiStepForm/FreeSample"; // import the i18n configuration

function ReferralHandler() {
    const { referralCode } = useParams();

    React.useEffect(() => {
        if (referralCode) {
            // Store the referral code in localStorage
            localStorage.setItem("referralCode", referralCode);
            console.log(`Referral code ${referralCode} stored in localStorage`);
        }
        // Since we want the appearance of navigating to the home page without an actual redirect,
        // we don't need to use navigate() here. The component rendering is handled below.
    }, [referralCode]);

    // Render the same component as for the home page
    return <UnifiedHomePage />;
}

function CatchAllReferralHandler() {
    const location = useLocation();

    React.useEffect(() => {
        const referralCode = location.pathname.substring(1); // Remove the leading slash
        if (referralCode) {
            // Store the referral code in localStorage
            localStorage.setItem("referralCode", referralCode);
            localStorage.setItem("referralCodeInfluencer", referralCode);
            console.log(`Referral code ${referralCode} stored in localStorage`);
        }
    }, [location]);

    // Render the same component as for the home page or redirect as necessary
    return <UnifiedHomePage />;
}

function App() {
    const documents = useSelector(state => state.documents.savedDocuments);
    const [isLoading, setIsLoading] = React.useState(true);
    const auth = useAuth();
    const dispatch = useDispatch();
    const toast = useToast()
    const { i18n } = useTranslation();

    React.useEffect(() => {
        const fetchLocation = async () => {
            try {
                const response = await axios.get('https://api.country.is');
                if (response.data && response.data.country) {
                    const country = response.data.country;
                    console.log('Location country', country)
                    if (country === 'KR') {
                        i18n.changeLanguage('ko');
                    } else {
                        i18n.changeLanguage('en');
                    }
                }
            } catch (error) {
                console.error('Error fetching the location', error);
            }
        };

        fetchLocation();
    }, []);

    React.useEffect( () => {
        localStorage.setItem('hasSeen', "true");
// toast({
//             title: "We’re experiencing exceptionally high demand. Please hang tight as we work on scaling our systems.",
//             description: "We'll be fully operational very soon. Thank you for your patience!",
//             status: "info",
//             duration: null,
//             isClosable: true,
//             position: "top",
//         });
        async function getFeatureFlagHere() {
            return await getFeatureFlag(localStorage.getItem("userID"), "show_user_rewardful_popup_june_3");
        }

        setIsLoading(true);
        if (localStorage.getItem("userID") !== null) {
            console.log("User is logged in");

            getFeatureFlagHere()
                .then(response => {
                    console.log("GET FEATURE FLAG RESPONSE", response);
                    if (response !== null) {
                        dispatch(setIsNewFeatureOpen(response.feature_flag))
                        setIsLoading(false);
                    } else {
                        dispatch(setIsNewFeatureOpen(false));
                        setFeatureFlag(localStorage.getItem("userID"), "show_user_rewardful_popup_june_3",
                            true).then(r => {console.log("Feature flag set to true", r)})
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    console.error("Error fetching feature flag", error);
                    setIsLoading(false)
                })
                .finally(() => {
                    setIsLoading(false);
                });

        } else {
            if (localStorage.getItem("feature_flag") === null) {
                localStorage.setItem("feature_flag", "false")
            }
            console.log("User is not logged in");
        }
        setIsLoading(false);
    }, []);

    return (
        isLoading ? <div>Loading...</div> :
            <Router>
                <Routes>
                    {/*<Route path="/pdf/:id" element={<WrapperComponent /> } />*/}
                    <Route path="/" element={<UnifiedHomePage />} />
                    {/*<Route path="/pricing" element={<ThreeTierPricing />} />*/}
                    <Route path="/form" element={<MultiStepForm />} />
                    <Route path="/free-sample" element={<FreeSample />} />
                    <Route path="*" element={<Navigate to="/form?step=age" replace />} />
                    {/*<Route path="/affiliate" element={<Affiliate />} />*/}
                    {/*<Route path="/generation/:id" element={<WrapperComponentGeneration /> } />*/}
                    {/*<Route path="/generation" element={<UnifiedHomePageGeneration />} />*/}
                    <Route path="/checkout-response" element={<CheckoutResponse/>} />
                    <Route path="/u/:referralCode" element={<ReferralHandler />} />
                    <Route path="*" element={<CatchAllReferralHandler />} />
                </Routes>
            </Router>
    );
}

export default App;



