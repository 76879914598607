import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box,
    HStack,
    InputGroup,
    InputRightElement,
    Link,
    Flex,
    VStack, useToast, Image, ListItem, ListIcon, List, ModalFooter
} from "@chakra-ui/react";
import ScanModal2 from "./ScanModal2";
import {FaCheckCircle} from "react-icons/fa";
import {FcRight} from "react-icons/fc";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {SERVER_IP} from "../../../utils/constants";
import {
    addDocument,
    removeDocument,
    setCeleryTaskID,
    setIsScanActuallyInProgress, setScanError,
    setScanInProgress
} from "../../../redux/reducers";
import {addDocumentInStorage, getDocumentsInStorage} from "../../../utils/local-storage-helpers";
import {useAuth} from "../../../redux/AuthProvider";
import {useTranslation} from "react-i18next";

const ScanModal1 = ({ isOpenScanModal1, onCloseScanModal1, onOpenScanModal1 }) => {
    const [isOpenScanModal2, setIsOpenScanModal2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const scanEstimatedSeconds = useSelector(state => state.settings.scanEstimatedSeconds)
    const scanFileID = useSelector(state => state.settings.scanFileID)
    const dispatch = useDispatch()
    const [intervalHook, setIntervalHook] = useState(0)
    const auth = useAuth();
    const {t} = useTranslation();

    const startDocumentProcessing = async () => {
        setIsLoading(true)
        try {
            const response = await axios.post(SERVER_IP + '/pdf/ocr', {
                user_id: localStorage.getItem('userID') !== null ? localStorage.getItem('userID') : "11111111-1111-1111-1111-111111111111",
                file_id: scanFileID
            });
            console.log("response from /pdf/ocr", response)
            if (response.data.status === 200) {
                const start = new Date().getTime() / 1000;

                setIsOpenScanModal2(true);
                onCloseScanModal1();
                setIsLoading(false)
                dispatch(setCeleryTaskID(response.data.message.celery_task_id))
                dispatch(setIsScanActuallyInProgress(true))

                // start polling another endpoint to check if the document is ready
                const interval = setInterval(async () => {
                    const response = await axios.post(SERVER_IP + '/pdf/ocr_progress', {
                        user_id: localStorage.getItem('userID') !== null ? localStorage.getItem('userID') : "11111111-1111-1111-1111-111111111111",
                        file_id: scanFileID
                    });
                    console.log("response from /pdf/ocr_progress", response)
                    if (response.data.status === 200) {
                        const end = new Date().getTime() / 1000;
                        const duration = end - start;
                        console.log("DURATION OF SCAN", duration)
                        clearInterval(interval)
                        dispatch(setScanInProgress(false))
                        dispatch(setIsScanActuallyInProgress(false))
                        dispatch(setScanError(false))

                        if (localStorage.getItem("userID") === null) {
                            addDocumentInStorage({
                                id: response.data.message.file_id,
                                name: response.data.message.filename,
                                url: response.data.message.url,
                                highlights: [],
                                messages: [],
                            })
                            auth.setPlaygroundDocuments(getDocumentsInStorage());
                        }
                        dispatch(removeDocument(scanFileID))
                        dispatch(addDocument({
                            id: response.data.message.file_id,
                            name: response.data.message.filename,
                            url: response.data.message.url,
                            highlights: [],
                            messages: [],
                        }));
                    } else if (response.data.status === 205) {
                        console.log("still processing")
                    } else if (response.data.status === 400) {
                        dispatch(setScanError(true))
                        clearInterval(interval)
                        dispatch(setScanInProgress(false))
                        dispatch(setIsScanActuallyInProgress(false))
                        dispatch(removeDocument(scanFileID))
                    }
                }, 5000)
                setIntervalHook(interval)
            }
        } catch (error) {
            console.log("/pdf/ocr error", error);
        }
    };

    const stopPolling = () => {
        clearInterval(intervalHook)
    }

    return (
        <>
            <Modal isOpen={isOpenScanModal1} onClose={onCloseScanModal1} isCentered size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="1rem" p="0.75rem" h="37.5rem" w="35rem" overflowY="auto" position="relative">
                    <Box
                        position="absolute"
                        top="10px"
                        right="10px"
                        zIndex={999}
                    >
                        <CloseButton
                            size="md"
                            onClick={() => {
                                onCloseScanModal1();
                                dispatch(setScanInProgress(true))
                            }}
                            bg="transparent"
                            borderRadius="50%"
                            _hover={{
                                bg: "gray.200",
                                borderRadius: "50%",
                            }}
                        />
                    </Box>
                    <Flex alignItems="center" justifyContent="space-between" p={4} >
                        <VStack align="center" width={'100%'} spacing={6}>
                            <Text fontSize="1.18rem" fontWeight="bold">{t("You Uploaded a Scanned Document!")}</Text>
                            <Box w={'full'} h={'1px'} bg={'gray.300'} />
                            <VStack align="center" width={'100%'} spacing={3}>
                                <List spacing={2} textAlign="start">
                                    <ListItem key={1}>
                                        <Flex align="start">
                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />
                                            <Text fontSize={'sm'}>{t("By continuing, we'll convert your scanned document into a useable text format.")}</Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem key={2}>
                                        <Flex align="start">
                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />
                                            <Text fontSize={"sm"}>{t("Please continue if you want to chat and interact with your document normally.")}</Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem key={2}>
                                        <Flex align="start">
                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />
                                            <Text fontSize={"sm"}>{t("This will take about")} {scanEstimatedSeconds} {t("seconds.")}</Text>
                                        </Flex>
                                    </ListItem>
                                </List>
                            </VStack>
                        </VStack>
                    </Flex>
                    <ModalBody>
                        <HStack width={"100%"} bg={'white'} justifyContent={'space-between'} display={'flex'} my={-4}>
                            <Image
                                src='/scan.jpg'
                                alt="scanned document"
                                width="40%"
                                height="100%"
                                transform="scale(0.75)"
                                objectFit="cover"
                            />
                            <FcRight size={'15rem'}/>
                            <Image
                                src='/text.jpg'
                                alt="text document"
                                width="40%"
                                height="100%"
                                transform="scale(0.75)"
                                objectFit="cover"
                            />
                        </HStack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack spacing={10} justifyContent={'space-between'} width={'100%'}>
                            <Button
                                loadingText="Submitting"
                                size="lg"
                                bg={'gray.200'} color={'gray.800'}
                                width={'45%'}
                                onClick={() => {
                                    onCloseScanModal1();
                                }}
                                _hover={{
                                    boxShadow: 'xl',
                                }}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                loadingText="Submitting"
                                size="lg"
                                isLoading={isLoading}
                                width={'45%'}
                                onClick={startDocumentProcessing}
                                bgGradient="linear(to-r, red.400,pink.400)"
                                color={'white'}
                                _hover={{
                                    bgGradient: 'linear(to-r, red.400,pink.400)',
                                    boxShadow: 'xl',
                                }}>
                                {t('Continue')}
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <ScanModal2
                isOpen={isOpenScanModal2}
                onClose={() => setIsOpenScanModal2(false)}
                stopPolling={stopPolling}
            />
        </>
    );
};

export default ScanModal1;








