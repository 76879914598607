import { configureStore } from '@reduxjs/toolkit';
import reducers from "./reducers";

const store = configureStore({
    reducer: {
        settings: reducers.settingsSliceReducer,
        user: reducers.userSliceReducer,
        documents: reducers.documentSliceReducer,
        sidePanel: reducers.sidePanelSliceReducer,
    }
})
export default store
