import React from "react";
import { Stack } from "@chakra-ui/react";
import { Skeleton } from '@chakra-ui/react'

const MySkeletonText = ({ width, height, count }) => {
    return (
        <Stack>
            {
                Array.from({ length: count }, (_, index) => (
                    <Skeleton key={index} height={height} width={width} />
                ))
            }
        </Stack>
    );
}

export default MySkeletonText;
