import React from "react";
import {Spinner} from "@chakra-ui/react";


export const MySpinner = () => {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
        }}>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
            />
        </div>
    )
}

export const MySpinnerLarge = () => {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
        }}>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                boxSize={'5rem'}
            />
        </div>
    )
}
