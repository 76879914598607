
import axios from "axios";
import {SERVER_IP} from "./constants";

export const createFormID = async () => {
    let requestBody = {}
    console.log("/tubby/create-form-id body", requestBody)

    try {
        const response = await axios.post(SERVER_IP + '/tubby/create-form-id', requestBody);
        console.log("response for /tubby/create-form-id", response)

        if (response.data.status === 200) {
            return {
                "status" : 200,
                "form_completion_id" : response.data.message.form_completion_id,
            }
        }

    } catch (error) {
        console.log("/tubby/create-form-id error", error);
        return null;
    }
}


export const saveFormData = async (checkoutID, formData) => {
    let requestBody = {
        "checkout_id" : checkoutID,
        "form_data" : formData
    }
    console.log("/tubby/save-form-data body", requestBody)

    try {
        const response = await axios.post(SERVER_IP + '/tubby/save-form-data', requestBody);
        console.log("response for /tubby/save-form-data", response)

        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        }

    } catch (error) {
        console.log("/tubby/save-form-data error", error);
        return null;
    }
}

export const createCheckoutSessionPhoto = async (checkoutID, userEmail, planPurchasing) => {
    let requestBody = {
        "checkout_id" : checkoutID,
        "email" : userEmail,
        "plan_purchasing" : planPurchasing
    }
    console.log("/tubby/create-checkout-session body", requestBody)

    try {
        const response = await axios.post(SERVER_IP + '/tubby/create-checkout-session', requestBody);
        console.log("response for /tubby/create-checkout-session", response)
        if (response.data.status === 200) {
            return {
                "status" : 200,
                "url" : response.data.message.url,
            }
        }

    } catch (error) {
        console.log("/tubby/create-checkout-session error", error);
        return null;
    }
}


// formIDPhoto
// firstNamePhoto
// lastNamePhoto
// emailPhoto
// confirmEmailPhoto
// genderPhoto
// agePhoto
// eyeColorPhoto
// hairColorPhoto
// ethnicityPhoto
// photosPhoto
// selectedPlanPhoto

