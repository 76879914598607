import React, {useEffect, useState} from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box,
    HStack,
    InputGroup,
    InputRightElement,
    Link,
    Flex,
    VStack, useToast, Image, ListItem, ListIcon, List, ModalFooter, useBreakpointValue
} from "@chakra-ui/react";
import ScanModal2 from "./ScanModal2";
import {FaCheckCircle, FaCircle} from "react-icons/fa";
import {FcRight} from "react-icons/fc";
import {useDispatch, useSelector} from "react-redux";
import {setIsNewFeatureOpen} from "../../../redux/reducers";
import axios from "axios";
import {SERVER_IP} from "../../../utils/constants";
import {setFeatureFlag} from "../../../utils/api";
import affiliateAnimation from "../../../pages/Affiliate/affiliate-animation.json";
import Lottie from "lottie-react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const MinModal = ({ isOpenModal, onCloseModal, onOpenModal, fileInput }) => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch()
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const navigate = useNavigate();
    const {t} = useTranslation();

    const closeModal = async () => {
        onCloseModal();
    }



    return (
        <>
            <Modal isOpen={isOpenModal} onClose={closeModal} isCentered size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="1rem" p="0.75rem" h="19rem" w="34rem" overflowY="auto" position="relative">
                    <Box
                        position="absolute"
                        top="10px"
                        right="10px"
                        zIndex={999}
                    >
                        <CloseButton
                            size="md"
                            onClick={() => {
                                closeModal()
                            }}
                            bg="transparent"
                            borderRadius="50%"
                            _hover={{
                                bg: "gray.200",
                                borderRadius: "50%",
                            }}
                        />
                    </Box>
                    <Flex alignItems="center" justifyContent="space-between" p={4} >
                        <VStack align="center" width={'100%'} spacing={6}>
                            <Text fontSize="1.18rem" fontWeight="bold">You Uploaded Less Than 6 Photos</Text>
                            <Box w={'full'} h={'1px'} bg={'gray.300'} />
                            <VStack align="center" width={'100%'} spacing={3}>
                                <List spacing={3} textAlign="start">
                                    <ListItem key={1}>
                                        <Flex align="start">
                                            <ListIcon as={FaCircle} color="black" mt={2} mr={3.5} fontSize={7} />
                                            <Text fontSize={'md'}>{t("We can still process your order, but the quality may be worse")}</Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem key={2}>
                                        <Flex align="start">
                                            <ListIcon as={FaCircle} color="black" mt={2} mr={3.5} fontSize={7} />
                                            <Text fontSize={"md"}>
                                                {t("Upload")} <Text as="span" fontWeight="bold">{t("at least 6")}</Text> {t("photos for best results!")}
                                            </Text>
                                        </Flex>
                                    </ListItem>
                                </List>
                            </VStack>
                        </VStack>
                    </Flex>
                    <ModalBody>

                    </ModalBody>
                    <ModalFooter>
                        <HStack width="100%" justify="center" spacing={isMobile ? 5 : 12}>
                            <Button
                                loadingText="Submitting"
                                size="md"
                                isLoading={isLoading}
                                onClick={() => {
                                    closeModal()
                                    fileInput.current.click();
                                }}
                                bgGradient={'linear(to-r, red.400,pink.400)'}
                                color={'white'}
                                width={"55%"}
                                _hover={{
                                    boxShadow: 'xl',
                                }}
                            >
                                {t("Keep uploading")}
                            </Button>
                            <Button
                                loadingText="Submitting"
                                size="md"
                                isLoading={isLoading}
                                onClick={() => {
                                    navigate("?step=name");
                                    closeModal()
                                }}
                                bg={'gray.200'} color={'gray.800'}
                                width={"55%"}
                                _hover={{
                                    boxShadow: 'xl',
                                }}
                            >
                                {t("Continue anyway")}
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default MinModal;








