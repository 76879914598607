import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Button, CircularProgress, CircularProgressLabel, Divider,
    Flex, Grid, GridItem,
    HStack, IconButton, Image,
    Input, List, ListIcon, ListItem, Progress,
    Radio,
    RadioGroup,
    Stack,
    Text,
    useBreakpointValue,
    useToast, VStack
} from '@chakra-ui/react';
import {useNavigate} from "react-router-dom";
import {CloseIcon} from "@chakra-ui/icons";
import {IoMdCheckmark} from "react-icons/io";
import {createCheckoutSessionPhoto} from "../../utils/localStoragePhoto";
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from "uuid";
import axios from "axios";
import {SERVER_IP} from "../../utils/constants";

const FreeSample = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const columns = useBreakpointValue({ base: 1, sm: 2, md: 2 });
    const intervalRef = useRef();

    const [sampleImages, setSampleImages] = useState([
        { id: 1,
            url: "",
            progress: 0,
            status: 'uploading' }, // could be 'uploading', 'completed', 'error'
        { id: 2,
            url: "",
            progress: 0,
            status: 'uploading' },
        { id: 3,
            url: "",
            progress: 0,
            status: 'uploading' },
        { id: 4,
            url: "",
            progress: 0,
            status: 'uploading' },
    ]);

    const [ellipsis, setEllipsis] = useState(".");

    useEffect(() => {
        const ellipsisInterval = setInterval(() => {
            setEllipsis(prev => {
                if (prev === ".") return "..";
                if (prev === "..") return "...";
                return ".";
            });
        }, 500);

        return () => clearInterval(ellipsisInterval);
    }, []);

    const images = [
        "/1.png",
        "/3.png",
        "/20.png",
        "/10.png",
        "/14.png",
        "/12.png",
        "/18.png",
        "/6.png",
    ]

    const startImageProcessing = async () => {
        console.log("FORM ID INTO QUICK GENERATION", localStorage.getItem('formIDPhoto'));

        if (localStorage.getItem('isFreeSampleInProgress') === 'true') {
            intervalRef.current = setInterval(async () => {
                try {
                    const response = await axios.post(SERVER_IP + '/tubby/quick_image_generation_progress', {
                        form_completion_id: localStorage.getItem('formIDPhoto')
                    });
                    console.log("response from /tubby/quick_image_generation_progress", response);

                    if (response.data.status === 200) {
                        localStorage.setItem('isFreeSampleInProgress', 'false');
                        const urls = response.data.message.urls;
                        setSampleImages(prevImages =>
                            prevImages.map((image, index) => ({
                                ...image,
                                url: urls[index],
                                status: 'completed',
                                progress: 0
                            }))
                        );
                        localStorage.setItem('sampleImages', JSON.stringify(urls));
                        console.log('sample iamges local storage', JSON.stringify(urls));

                        // const end = new Date().getTime() / 1000;
                        // const duration = end - start;
                        // console.log("DURATION OF FREE SAMPLE", duration);

                        stopImageProcessing();
                    } else if (response.data.status === 205) {
                        console.log("still processing");

                        setSampleImages(prevImages =>
                            prevImages.map(f => ({
                                ...f,
                                progress: Math.min(f.progress + 6, 99)
                            }))
                        );
                    } else if (response.data.status === 400) {
                        localStorage.setItem('isFreeSampleInProgress', 'false');
                        setSampleImages(sampleImages.map(f => ({
                            ...f,
                            status: 'error',
                            progress: 0
                        })));
                        toast({
                            title: 'Oops, something went wrong. Free samples are unavailable at the moment.',
                            status: "error",
                            duration: null,
                            isClosable: true,
                        });
                        stopImageProcessing();
                    }
                } catch (error) {
                    localStorage.setItem('isFreeSampleInProgress', 'false');
                    setSampleImages(sampleImages.map(f => ({
                        ...f,
                        status: 'error',
                        progress: 0
                    })));
                    console.log("/tubby/quick_image_generation_progress error", error);
                    stopImageProcessing();
                }
            }, 5000);
        } else {
            try {
                const response = await axios.post(SERVER_IP + '/tubby/quick_image_generation', {
                    form_completion_id: localStorage.getItem('formIDPhoto')
                });
                console.log("response from /tubby/quick_image_generation", response);

                if (response.data.status === 200) {
                    localStorage.setItem('isFreeSampleInProgress', 'true');
                    const start = new Date().getTime() / 1000;

                    intervalRef.current = setInterval(async () => {
                        try {
                            const response = await axios.post(SERVER_IP + '/tubby/quick_image_generation_progress', {
                                form_completion_id: localStorage.getItem('formIDPhoto')
                            });
                            console.log("response from /tubby/quick_image_generation_progress", response);

                            if (response.data.status === 200) {
                                localStorage.setItem('isFreeSampleInProgress', 'false');
                                const urls = response.data.message.urls;
                                setSampleImages(prevImages =>
                                    prevImages.map((image, index) => ({
                                        ...image,
                                        url: urls[index],
                                        status: 'completed',
                                        progress: 0
                                    }))
                                );
                                localStorage.setItem('sampleImages', JSON.stringify(urls));
                                console.log('sample iamges local storage', JSON.stringify(urls));

                                const end = new Date().getTime() / 1000;
                                const duration = end - start;
                                console.log("DURATION OF FREE SAMPLE", duration);

                                stopImageProcessing();
                            } else if (response.data.status === 205) {
                                console.log("still processing");

                                setSampleImages(prevImages =>
                                    prevImages.map(f => ({
                                        ...f,
                                        progress: Math.min(f.progress + 6, 99)
                                    }))
                                );
                            } else if (response.data.status === 400) {
                                localStorage.setItem('isFreeSampleInProgress', 'false');
                                setSampleImages(sampleImages.map(f => ({
                                    ...f,
                                    status: 'error',
                                    progress: 0
                                })));
                                toast({
                                    title: 'Oops, something went wrong. Free samples are unavailable at the moment.',
                                    status: "error",
                                    duration: null,
                                    isClosable: true,
                                });
                                stopImageProcessing();
                            }
                        } catch (error) {
                            localStorage.setItem('isFreeSampleInProgress', 'false');
                            setSampleImages(sampleImages.map(f => ({
                                ...f,
                                status: 'error',
                                progress: 0
                            })));
                            console.log("/tubby/quick_image_generation_progress error", error);
                            stopImageProcessing();
                        }
                    }, 5000);
                }
            } catch (error) {
                localStorage.setItem('isFreeSampleInProgress', 'false');
                setSampleImages(sampleImages.map(f => ({
                    ...f,
                    status: 'error',
                    progress: 0
                })));
                stopImageProcessing();
                console.log("/tubby/quick_image_generation error", error);
            }
        }

    };

    const stopImageProcessing = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            // localStorage.setItem('isFreeSampleInProgress', 'false');
        }
    };

    useEffect(() => {
        // if the images are already finished with a url, don't start processing
        if (sampleImages.every(image => image.status === 'completed')) {
            return;
        } else if (JSON.parse(localStorage.getItem('sampleImages')) && JSON.parse(localStorage.getItem('sampleImages')).length > 0) {
            setSampleImages(JSON.parse(localStorage.getItem('sampleImages')));
            localStorage.setItem('hasSeen', "true");
        }
        else {
            startImageProcessing();
        }

        // Cleanup to stop polling when component unmounts
        return () => stopImageProcessing();
    }, []);

    // start reviewing here and below:
    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'space-between'} height={'100vh'}>
            <Box p={8} width={'100vw'} height={'87vh'} bg={'white'}>
                <Box position="relative" mb={8} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Button
                        size="md"
                        fontSize={14}
                        bg={'white'} color={'black'}
                        _hover={{
                            bg: 'gray.100',
                        }}
                        borderColor={'lightgray'} borderWidth={0.25}
                        shadow={`1.5px 1.8px 0px lightgray`}
                        onClick={() => {
                            if (JSON.parse(localStorage.getItem('sampleImages')) && JSON.parse(localStorage.getItem('sampleImages')).length > 0) {
                                setSampleImages(JSON.parse(localStorage.getItem('sampleImages')));
                                localStorage.setItem('hasSeen', "true");
                            }
                            stopImageProcessing();
                            navigate('/form?step=preview');
                        }}
                        variant="ghost"
                        position="absolute"
                        top="50%"
                        left="0"
                        transform="translateY(-50%)"
                    >
                        Back
                    </Button>
                    <HStack width={'70%'} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} spacing={6}>
                        <Progress value={100} size="sm" colorScheme="orange" width={isMobile ? '51%' : '100%'} borderRadius={15}/>
                        <Text fontWeight={550}>
                            100%
                        </Text>
                    </HStack>
                    <IconButton
                        icon={<CloseIcon />}
                        position="absolute"
                        top="50%"
                        right="0"
                        transform="translateY(-50%)"
                        onClick={() => {
                            if (JSON.parse(localStorage.getItem('sampleImages')) && JSON.parse(localStorage.getItem('sampleImages')).length > 0) {
                                setSampleImages(JSON.parse(localStorage.getItem('sampleImages')));
                                localStorage.setItem('hasSeen', "true");
                            }
                            stopImageProcessing();
                            navigate('/');
                        }}
                        variant="ghost"
                        aria-label="Close"
                    />
                </Box>








                <Box bg="white" width="100%" height="100%" display="flex" justifyContent="center">
                    {isMobile || breakpoint === 'md' ?
                        <VStack mt={0} bg="white" width={'100%'} spacing={10}>
                            <Box width={{ base: '100%', md: '65%' }} bg="white" borderRightWidth={1} borderRightColor={'lightgray'} pr={4}>
                                <VStack align="center" p={4} spacing={4} pt={0}>
                                    <Text fontSize="2xl" fontWeight="bold" mb={6}>
                                        Sample photos
                                    </Text>
                                    {localStorage.getItem('hasSeen') === 'true' ?
                                        <VStack spacing={3}>
                                            <Text textAlign={'center'} fontWeight={'bold'} fontSize={17}>
                                                You've already viewed the sample photos
                                            </Text>
                                            <Text textAlign={'center'}>
                                                Select a paid package for the full suite of most realistic photos.
                                            </Text>
                                        </VStack>
                                        :
                                        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={6}>
                                            {sampleImages.slice(0,2).map((image, index) => (
                                                <GridItem key={index}>
                                                    <Box position="relative" width={'12rem'} height={'12rem'} bg="gray.200" borderRadius="lg">
                                                        {image.status === 'uploading' ? (
                                                            <Box
                                                                position="absolute"
                                                                top="50%"
                                                                left="50%"
                                                                transform="translate(-50%, -50%)"
                                                                textAlign="center"
                                                            >
                                                                <CircularProgress
                                                                    value={image.progress}
                                                                    color="blue.600"
                                                                    size="100%"
                                                                    thickness="8px"
                                                                />
                                                                <Text mt={3} fontSize={14}>
                                                                    {image.progress < 99
                                                                        ? `${Math.floor(image.progress)}%`
                                                                        : `Almost there${ellipsis}`}
                                                                </Text>
                                                            </Box>
                                                        ) : (
                                                            <Box
                                                                position="absolute"
                                                                top="0"
                                                                left="0"
                                                                width="100%"
                                                                height="100%"
                                                                backgroundImage={`url(${image.url})`}
                                                                backgroundSize="cover"
                                                                backgroundPosition="center"
                                                                borderRadius="lg"
                                                            />
                                                        )}
                                                    </Box>
                                                </GridItem>
                                            ))}
                                        </Grid>}









                                </VStack>
                            </Box>
                            {localStorage.getItem('hasSeen') !== 'true' ?
                                <Box width={{ base: '100%', md: '45%' }} display={'flex'} flexDirection={'column'} bg={'white'}
                                     alignItems={'center'} justifyContent={'center'}>
                                    <VStack spacing={8} mt={-2}>
                                        <Text fontSize={21} color={'black'} fontWeight={500} textAlign={'center'}>
                                            Note: Sample photos are not full-quality
                                        </Text>
                                        <Text fontSize={17} fontWeight={'normal'} color={'black'} mt={-3} textAlign={'center'}>
                                            Select a paid package for the full suite of most realistic photos.
                                        </Text>
                                        <Button
                                            onClick={() => {
                                                if (JSON.parse(localStorage.getItem('sampleImages')) && JSON.parse(localStorage.getItem('sampleImages')).length > 0) {
                                                    setSampleImages(JSON.parse(localStorage.getItem('sampleImages')));
                                                    localStorage.setItem('hasSeen', "true");
                                                }
                                                stopImageProcessing();
                                                navigate("/form?step=preview");
                                            }}
                                            size="lg"
                                            borderRadius={50}
                                            px={7}
                                            py={7}
                                            bgGradient="linear(to-r, red.400,pink.400)"
                                            color="white"
                                            _hover={{
                                                bgGradient: 'linear(to-r, red.400,pink.400)',
                                                boxShadow: 'xl',
                                            }}
                                        >
                                            Select a Package
                                        </Button>
                                    </VStack>

                                </Box> : null}
                        </VStack>



                        :





                        <HStack mt={0} bg="white" width={['100%', '100%', '100%', '100%', '90%', '85%']} height="100%" spacing={10}>
                            <Box width={{ base: '100%', md: '65%' }} height="100%" bg="white" borderRightWidth={1} borderRightColor={'lightgray'} pr={4}>
                                <VStack align="center" p={4} spacing={4} pt={0}>
                                    <Text fontSize="2xl" fontWeight="bold" mb={6}>
                                        Sample photos
                                    </Text>
                                    {localStorage.getItem('hasSeen') === 'true' ?
                                        <VStack spacing={3}>
                                            <Text textAlign={'center'} fontWeight={'bold'} fontSize={17}>
                                                You've already viewed the sample photos
                                            </Text>
                                            <Text textAlign={'center'}>
                                                Select a paid package for the full suite of most realistic photos.
                                            </Text>
                                        </VStack>
                                        :
                                        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={6}>
                                            {sampleImages.slice(0,2).map((image, index) => (
                                                <GridItem key={index}>
                                                    <Box position="relative" width={'12rem'} height={'12rem'} bg="gray.200" borderRadius="lg">
                                                        {image.status === 'uploading' ? (
                                                            <Box
                                                                position="absolute"
                                                                top="50%"
                                                                left="50%"
                                                                transform="translate(-50%, -50%)"
                                                                textAlign="center"
                                                            >
                                                                <CircularProgress
                                                                    value={image.progress}
                                                                    color="blue.600"
                                                                    size="100%"
                                                                    thickness="8px"
                                                                />
                                                                <Text mt={3} fontSize={14}>
                                                                    {image.progress < 99
                                                                        ? `${Math.floor(image.progress)}%`
                                                                        : `Almost there${ellipsis}`}
                                                                </Text>
                                                            </Box>
                                                        ) : (
                                                            <Box
                                                                position="absolute"
                                                                top="0"
                                                                left="0"
                                                                width="100%"
                                                                height="100%"
                                                                backgroundImage={`url(${image.url})`}
                                                                backgroundSize="cover"
                                                                backgroundPosition="center"
                                                                borderRadius="lg"
                                                            />
                                                        )}
                                                    </Box>
                                                </GridItem>
                                            ))}
                                        </Grid>}









                                </VStack>
                            </Box>
                            <Box width={{ base: '100%', md: '45%' }} display={'flex'} flexDirection={'column'} bg={'white'}
                                 alignItems={'center'} justifyContent={'center'}>
                                <VStack spacing={8}>
                                    <Text fontSize={21} color={'black'} fontWeight={500} textAlign={'center'}>
                                        Note: Sample photos are not full-quality
                                    </Text>
                                    <Text fontSize={17} fontWeight={'normal'} color={'black'} mt={-3} textAlign={'center'}>
                                        Select a paid package for the full suite of most realistic photos.
                                    </Text>
                                    <Button
                                        onClick={() => {
                                            if (JSON.parse(localStorage.getItem('sampleImages')) && JSON.parse(localStorage.getItem('sampleImages')).length > 0) {
                                                setSampleImages(JSON.parse(localStorage.getItem('sampleImages')));
                                                localStorage.setItem('hasSeen', "true");
                                            }
                                            stopImageProcessing();
                                            navigate("/form?step=preview");
                                        }}
                                        size="lg"
                                        borderRadius={50}
                                        px={7}
                                        py={7}
                                        bgGradient="linear(to-r, red.400,pink.400)"
                                        color="white"
                                        _hover={{
                                            bgGradient: 'linear(to-r, red.400,pink.400)',
                                            boxShadow: 'xl',
                                        }}
                                    >
                                        Select a Package
                                    </Button>
                                </VStack>

                            </Box>
                        </HStack>}








                    <Box
                        position="fixed"
                        bottom={0}
                        width="100vw"
                        right={0}
                        height="13vh"
                        bg="white"
                        textAlign="center"
                        pt={8}
                        boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
                    >
                        <Button
                            onClick={() => {
                                if (JSON.parse(localStorage.getItem('sampleImages')) && JSON.parse(localStorage.getItem('sampleImages')).length > 0) {
                                    setSampleImages(JSON.parse(localStorage.getItem('sampleImages')));
                                    localStorage.setItem('hasSeen', "true");
                                }
                                stopImageProcessing();
                                navigate("/form?step=preview");
                            }}
                            size="lg"
                            width={isMobile ? '66%' : '25%'}
                            borderRadius={50}
                            bgGradient="linear(to-r, red.400,pink.400)"
                            color="white"
                            _hover={{
                                bgGradient: 'linear(to-r, red.400,pink.400)',
                                boxShadow: 'xl',
                            }}
                        >
                            Select a Package
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default FreeSample;














