import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import {useSelector} from "react-redux";
import Navbar from "../components/TopNavbar/Navbar";
import {useAuth} from "../redux/AuthProvider";
import SidePanelDrawer from "../components/TopNavbar/SidePanelDrawer";
import LargeWithNewsletter from "../components/Reuseable/LargeWithNewsletter";

const FullScreenTemplateHomePage = ({ children, navigateToTestimonials, navigateToFeatures, navigateToPricing }) => {
    const isFullScreen = useSelector(state => state.settings.isFullScreen)
    const sidebarWidth = useSelector(state => state.sidePanel.sidebarWidth)
    const isSidePanelOpen = useSelector(state => state.sidePanel.isSidePanelOpen)
    const auth = useAuth();

    return (
        <Box>
            {!isFullScreen && isSidePanelOpen && (
                <SidePanelDrawer />
            )}
            {!isFullScreen && (
                <Navbar
                    profileImage="https://bit.ly/sage-adebayo"
                    firstName={localStorage.getItem("firstName")}
                    lastName={localStorage.getItem("lastName")}
                    navigateToTestimonials={navigateToTestimonials}
                    navigateToFeatures={navigateToFeatures}
                    navigateToPricing={navigateToPricing}
                />
            )}
            <Box
                backgroundColor={"white"}
                ml={"0"}
                width={"100%"}
                height={"100%"}
            >
                <Flex direction="column" align="center" bg={'white'}
                      mt={isFullScreen ? "0rem" : "4rem"} overflowX={'hidden'}>
                    {children}
                </Flex>
            </Box>
        </Box>
    );
};

export default FullScreenTemplateHomePage;
