'use client'

import {
    Box,
    VStack,
    Image,
    useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Marquee from "react-fast-marquee";

export default function LogoCarouselSpin() {
    const { t } = useTranslation();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const photoDimensionWidth = isMobile ? "12rem" : "18rem";
    const photoDimensionHeight = isMobile ? "12rem" : "18rem";
    const overlayDimension = "6.4rem"; // Adjust the size of the overlay image as needed
    const overlayDimensionMobile = "4.9rem"; // Adjust the size of the overlay image as needed

    const logos = [
        { src: "/11.png", alt: "result photos", height: photoDimensionHeight, width: photoDimensionWidth, overlayImage: "/before_bl_1.png", direction: "right" },
        { src: "/12.png", alt: "result photos", height: photoDimensionHeight, width: photoDimensionWidth, overlayImage: "/before_sw_1.png", direction: "right" },
        { src: "/13.png", alt: "result photos", height: photoDimensionHeight, width: photoDimensionWidth, overlayImage: "/before_ind_1.png", direction: "right" },
        { src: "/14.png", alt: "result photos", height: photoDimensionHeight, width: photoDimensionWidth, overlayImage: "/brandon_before_1.jpg", direction: "right" },
        { src: "/15.png", alt: "result photos", height: photoDimensionHeight, width: photoDimensionWidth, overlayImage: "/before_red_1.png", direction: "left" },
        { src: "/16.png", alt: "result photos", height: photoDimensionHeight, width: photoDimensionWidth, overlayImage: "/before_sw_1.png", direction: "right" },
        { src: "/17.png", alt: "result photos", height: photoDimensionHeight, width: photoDimensionWidth, overlayImage: "/before_ind_1.png", direction: "right" },
        { src: "/18.png", alt: "result photos", height: photoDimensionHeight, width: photoDimensionWidth, overlayImage: "/before_sw_1.png", direction: "right" },
        { src: "/19.png", alt: "result photos", height: photoDimensionHeight, width: photoDimensionWidth, overlayImage: "/before_ind_1.png", direction: "right" },
    ];

    return (
        <Box
            width="100vw"
            mb="3.3rem"
            justify="center"
            align="center"
            flexDirection={isMobile ? 'column' : 'row'}
            mt={1}
            display="flex"
            justifyContent="center"
            alignItems={isMobile ? 'center' : 'flex-start'}
            py="0rem"
            overflow="hidden"
            position="relative"
        >
            <VStack spacing={10}>
                {isMobile ? (
                    <Box
                        display="flex"
                        overflow="hidden"
                        width="100vw"
                    >
                        <Marquee speed={55} autoFill={true} gradient={true} gradientWidth={150}>
                            {logos.map((logo, index) => (
                                <Box
                                    key={index}
                                    position="relative"
                                    display="inline-block"
                                    mr={4}
                                    width={logo.width}
                                    height={logo.height}
                                    borderRadius={22}
                                    overflow="hidden"
                                    _hover={{
                                        '.main-image': { opacity: 0 },
                                        '.overlay-image': { opacity: 1 },
                                        '.corner-image': { opacity: 0 },
                                        '.corner-overlay-image': { opacity: 1 }
                                    }}
                                >
                                    <Image
                                        className="main-image"
                                        src={logo.src.toString()}
                                        alt={logo.alt}
                                        height="100%"
                                        width="100%"
                                        borderRadius={22}
                                        transition="opacity 0.3s"
                                        position="absolute"
                                        top="0"
                                        left="0"
                                        objectFit="cover"
                                    />
                                    <Image
                                        className="overlay-image"
                                        src={logo.overlayImage}
                                        alt="overlay"
                                        height="100%"
                                        width="100%"
                                        borderRadius={22}
                                        transition="opacity 0.3s"
                                        position="absolute"
                                        top="0"
                                        left="0"
                                        objectFit="cover"
                                        opacity={0}
                                    />
                                    <Box
                                        position="absolute"
                                        bottom={logo.direction === "right" ? "0px" : "0px"}
                                        right={logo.direction === "right" ? "0px" : "auto"}
                                        left={logo.direction === "left" ? "0px" : "auto"}
                                        width={overlayDimensionMobile}
                                        height={overlayDimensionMobile}
                                        borderRadius={20}
                                        overflow="hidden"
                                        transition="opacity 0.3s"
                                    >
                                        <Image
                                            className="corner-image"
                                            src={logo.overlayImage}
                                            alt="corner-overlay"
                                            height="100%"
                                            width="100%"
                                            borderRadius={20}
                                            objectFit="cover"
                                            opacity={1}
                                            transition="opacity 0.3s"
                                        />
                                        <Image
                                            className="corner-overlay-image"
                                            src={logo.src.toString()}
                                            alt="corner-overlay-hover"
                                            height="100%"
                                            width="100%"
                                            borderRadius={20}
                                            objectFit="cover"
                                            opacity={0}
                                            transition="opacity 0.3s"
                                            position="absolute"
                                            top="0"
                                            left="0"
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Marquee>
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        overflow="hidden"
                        width="100vw"
                    >
                        <Marquee speed={55} autoFill={true} gradient={true} gradientWidth={150}>
                            {logos.map((logo, index) => (
                                <Box
                                    key={index}
                                    position="relative"
                                    display="inline-block"
                                    mr={4}
                                    width={logo.width}
                                    height={logo.height}
                                    borderRadius={22}
                                    overflow="hidden"
                                    _hover={{
                                        '.main-image': { opacity: 0 },
                                        '.overlay-image': { opacity: 1 },
                                        '.corner-image': { opacity: 0 },
                                        '.corner-overlay-image': { opacity: 1 }
                                    }}
                                >
                                    <Image
                                        className="main-image"
                                        src={logo.src.toString()}
                                        alt={logo.alt}
                                        height="100%"
                                        width="100%"
                                        borderRadius={22}
                                        transition="opacity 0.3s"
                                        position="absolute"
                                        top="0"
                                        left="0"
                                        objectFit="cover"
                                    />
                                    <Image
                                        className="overlay-image"
                                        src={logo.overlayImage}
                                        alt="overlay"
                                        height="100%"
                                        width="100%"
                                        borderRadius={20}
                                        transition="opacity 0.3s"
                                        position="absolute"
                                        top="0"
                                        left="0"
                                        objectFit="cover"
                                        opacity={0}
                                    />
                                    <Box
                                        position="absolute"
                                        bottom={logo.direction === "right" ? "0px" : "0px"}
                                        right={logo.direction === "right" ? "0px" : "auto"}
                                        left={logo.direction === "left" ? "0px" : "auto"}
                                        width={overlayDimension}
                                        height={overlayDimension}
                                        borderRadius={20}
                                        overflow="hidden"
                                        transition="opacity 0.3s"
                                    >
                                        <Image
                                            className="corner-image"
                                            src={logo.overlayImage}
                                            alt="corner-overlay"
                                            height="100%"
                                            width="100%"
                                            borderRadius={20}
                                            objectFit="cover"
                                            opacity={1}
                                            transition="opacity 0.3s"
                                        />
                                        <Image
                                            className="corner-overlay-image"
                                            src={logo.src.toString()}
                                            alt="corner-overlay-hover"
                                            height="100%"
                                            width="100%"
                                            borderRadius={20}
                                            objectFit="cover"
                                            opacity={0}
                                            transition="opacity 0.3s"
                                            position="absolute"
                                            top="0"
                                            left="0"
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Marquee>
                    </Box>
                )}
            </VStack>
        </Box>
    );
}
