// VerticalMenu.js
import React from 'react';
import { IconButton, Menu, MenuButton, MenuList, MenuItem, Box } from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';

const VerticalMenu = ({ menuOptions }) => (
    <Menu>
        <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FiMoreVertical />}
            size="md"
            variant="subtle"
            borderRadius="full"
            zIndex={998}
            bg={'none'}
            _hover={{ bg: 'none' }}
            onClick={(e) => e.stopPropagation()}
        />
        <MenuList
            bg="white"
            borderRadius="md"
            boxShadow="md"
            zIndex={999}
        >
            {menuOptions.map((option, index) => (
                <MenuItem
                    key={index}
                    onClick={(e) => {
                            e.stopPropagation(); // Also stop propagation when menu item is clicked
                            option.onClick(); // Then call the option's onClick
                    }}
                >
                    <Box color={option.color || 'black'}>
                        {option.label}
                    </Box>
                </MenuItem>
            ))}
        </MenuList>
    </Menu>
);

export default VerticalMenu;
