import {
    Box,
    Button,
    Flex,
    VStack,
    Icon,
    useDisclosure,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerBody,
    IconButton,
    Spacer, HStack, Text
} from '@chakra-ui/react';
import {useDispatch, useSelector} from "react-redux";
import {toggleSidePanel} from "../../redux/reducers";
import {ImFilePdf} from "react-icons/im";
import {useNavigate} from "react-router-dom";
import {HamburgerIcon} from "@chakra-ui/icons";
import React from "react";
import lightTheme from "../../utils/styles";
import {useTranslation} from "react-i18next";

const SideBarButton = ({ icon, label, document }) => {
    const navigate = useNavigate();

    return (
        <Button
            variant="ghost"
            w="100%"
            justifyContent="start"
            _hover={{ bg: lightTheme.colors.lightGray }}
            _active={{ bg: lightTheme.colors.lightGray }}
            py={7}
            onClick={() => navigate(`/pdf/${document.id}`)}
        >
            <a href={`/pdf/${document.id}`} style={{ width: '100%', display: 'block' }}>
                <HStack direction="row" width="100%" justifyContent={'flex-start'} spacing={4}>
                    <Icon as={icon} flexShrink={0} />
                    <Box
                        fontSize={14}
                        fontWeight="normal"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                    >
                        {label}
                    </Box>
                </HStack>
            </a>
        </Button>
    );
};



const SidePanelDrawer = () => {
    const isSidePanelOpen = useSelector(state => state.sidePanel.isSidePanelOpen)
    const documents = useSelector(state => state.documents.savedDocuments)
    const dispatch = useDispatch()
    const {t} = useTranslation();

    return (
        <>
            <Drawer
                placement="left"
                isOpen={isSidePanelOpen}
                onClose={() => {
                    dispatch(toggleSidePanel())
                }}
                size="xs"
            >
                <DrawerOverlay>
                    <DrawerContent
                        bg="white"
                        color="black"
                        borderRightWidth={1}
                        borderRightColor={'rgba(0,0,0,0.12)'}
                    >
                        <Flex justifyContent="space-between" p={4} pb={0}>
                            <IconButton
                                aria-label="Menu"
                                icon={<HamburgerIcon />}
                                variant="ghost"
                                sx={{
                                    _hover: {
                                        background: 'transparent', // Ensures no background on hover
                                    },
                                }}
                                onClick={() => {dispatch(toggleSidePanel())}}
                            />
                        </Flex>
                        <DrawerBody>
                            <VStack spacing={0} align="center" justify="center" width={'100%'} mt={'0.5rem'}>
                                {documents.map((document, index) => (
                                    <SideBarButton
                                        key={index}
                                        icon={ImFilePdf}
                                        label={document.name}
                                        document={document}
                                    />
                                ))}
                                {documents.length === 0 ?
                                <Text textAlign={'center'} mt={"1rem"}>
                                    {t("Uploaded documents will appear here.")}
                                </Text> : null}
                            </VStack>
                        </DrawerBody>

                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    );
};

export default SidePanelDrawer;
