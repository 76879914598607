import React, {useEffect} from 'react';
import {
    Box,
    Button,
    Flex,
    Radio,
    RadioGroup,
    Stack,
    Text,
    useBreakpointValue,
    useToast,
    Wrap,
    WrapItem
} from '@chakra-ui/react';
import {useNavigate} from "react-router-dom";

const AgeStep = ({ value, onChange }) => {
    const options = ["18-20", "21-24", "25-29", "30-40", "41-50", "51-65", "65+"];
    const [selectedOption, setSelectedOption] = React.useState(value);
    const navigate = useNavigate();
    const toast = useToast();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';

    useEffect(() => {
        // Initialize selectedOption state based on the value prop
        setSelectedOption(value);
    }, [value]);

    useEffect(() => {
        if (localStorage.getItem("agePhoto")) {
            setSelectedOption(localStorage.getItem("agePhoto"));
        }
    }, []);

    return (
        <Box textAlign="center" mt={8}>
            <Text fontSize={47} fontWeight="bold" mb={4} color={'black'}>
                How old are you?
            </Text>
            <Text fontSize={17} color="gray.800" mb={16}>
                Your responses will help us create the perfect photos for you
            </Text>
            <RadioGroup onChange={(option) => {
                onChange(option);
                setSelectedOption(option);
                localStorage.setItem('agePhoto', option);
                // navigate("?step=eyeColor");
            }} value={selectedOption}>
                <Wrap justify="center" spacing={4} mb={8}>
                    {options.map((option) => (
                        <WrapItem key={option}>
                            <Button
                                key={option}
                                shadow="base"
                                borderWidth="1px"
                                bg={'white'}
                                py={6}
                                borderColor={selectedOption === option ? 'orange.400' : 'gray.200'}
                                _hover={{
                                    shadow: 'md',
                                    borderColor: 'orange.400',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    onChange(option);
                                    setSelectedOption(option);
                                    localStorage.setItem('agePhoto', option);
                                    // navigate("?step=eyeColor");
                                }}
                            >
                                <Flex justifyContent="space-between" alignItems="center" width="100%">
                                    <Text mr={4}>{option}</Text>
                                    <Radio
                                        value={option}
                                    />
                                </Flex>
                            </Button>
                        </WrapItem>
                    ))}
                </Wrap>
            </RadioGroup>



            <Box
                position="absolute"
                bottom={0}
                width="100vw"
                right={0}
                height="13vh"
                bg="white"
                textAlign="center"
                pt={8}
                boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
            >
                <Button
                    onClick={() => {
                        if (selectedOption === '') {
                            toast({
                                title: "Please choose your age.",
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                            });
                            return;
                        }

                        localStorage.setItem('agePhoto', selectedOption);
                        navigate("?step=eyeColor");
                    }}
                    size="lg"
                    width={isMobile ? '66%' : '15%'}
                    borderRadius={50}
                    bgGradient="linear(to-r, red.400,pink.400)"
                    color="white"
                    _hover={{
                        bgGradient: 'linear(to-r, red.400,pink.400)',
                        boxShadow: 'xl',
                    }}
                >
                    Continue
                </Button>
            </Box>





        </Box>
    );
};

export default AgeStep;



