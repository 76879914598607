import {createSlice} from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
    name: 'settingsSlice',
    initialState: {
        currentGenerationSessionToken: "",
        currentPage: "Account",
        currentPageGrid: 1,
        currentPageGridGeneration: 1,
        isFullScreen: false,
        isChatVisible: true,
        isFollowUpVisible: false,
        isAskVisible: false,
        isAskImageVisible: false,
        followUpContent: null,
        askContent: null,
        askImageContent: null,
        isUploadLoading: false,
        currentChatInput: "",
        isUploadSourcesModalOpen: false,

        currentChatUserID: "",
        currentChatClaudeID: "",
        currentChatType: "",
        currentChatSelectionText: "",
        currentChatQuestion: "",
        currentChatResponse: "",

        isScanInProgress: true,
        isScanActuallyInProgress: false,
        isScanError: false,
        scanFileID: "",
        scanEstimatedSeconds: 0,
        celeryTaskID: "",

        isNewFeatureOpen: true,
        isPricingModalOpen: false,
        isProPlan: false,
        whichTab: 1,

        isFollowUpVisibleGeneration: false,
        isAskVisibleGeneration: false,
        followUpContentGeneration: null,
        askContentGeneration: null,
        currentChatInputGeneration: "",
        currentChatUserIDGeneration: "",
        currentChatClaudeIDGeneration: "",
        currentChatTypeGeneration: "",
        currentChatSelectionTextGeneration: "",
        currentChatQuestionGeneration: "",
        currentChatResponseGeneration: "",

        currentGenerationProgress: -1,
        currentGenerationWordCount: -1,
    },
    reducers: {
        setIsProPlan: (state, action) => {
            state.isProPlan = action.payload;
        },
        setIsPricingModalOpen: (state, action) => {
            state.isPricingModalOpen = action.payload;
        },
        setCurrentGenerationProgress: (state, action) => {
            state.currentGenerationProgress = action.payload;
        },
        setCurrentGenerationWordCount: (state, action) => {
            state.currentGenerationWordCount = action.payload;
        },
        setCurrentGenerationSessionToken: (state, action) => {
            state.currentGenerationSessionToken = action.payload;
        },
        setIsUploadSourcesModalOpen: (state, action) => {
            state.isUploadSourcesModalOpen = action.payload;
        },
        toggleIsFollowUpVisibleTrueGeneration: (state) => {
            state.isFollowUpVisibleGeneration = true;
        },
        toggleIsFollowUpVisibleFalseGeneration: (state) => {
            state.isFollowUpVisibleGeneration = false;
        },
        toggleIsAskVisibleTrueGeneration: (state) => {
            state.isAskVisibleGeneration = true;
        },
        toggleIsAskVisibleFalseGeneration: (state) => {
            state.isAskVisibleGeneration = false;
        },
        setFollowUpContentGeneration: (state, action) => {
            state.followUpContentGeneration = action.payload;
        },
        setAskContentGeneration: (state, action) => {
            state.askContentGeneration = action.payload;
        },
        setCurrentChatInputGeneration: (state, action) => {
            state.currentChatInputGeneration = action.payload;
        },
        setCurrentChatUserIDGeneration: (state, action) => {
            state.currentChatUserIDGeneration = action.payload;
        },
        setCurrentChatClaudeIDGeneration: (state, action) => {
            state.currentChatClaudeIDGeneration = action.payload;
        },
        setCurrentChatTypeGeneration: (state, action) => {
            state.currentChatTypeGeneration = action.payload;
        },
        setCurrentChatSelectionTextGeneration: (state, action) => {
            state.currentChatSelectionTextGeneration = action.payload;
        },
        setCurrentChatQuestionGeneration: (state, action) => {
            state.currentChatQuestionGeneration = action.payload;
        },
        addToCurrentChatResponseGeneration: (state, action) => {
            state.currentChatResponseGeneration = state.currentChatResponseGeneration + action.payload;
        },
        resetCurrentChatResponseGeneration: (state) => {
            state.currentChatResponseGeneration = "";
        },


        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setCurrentPageGrid: (state, action) => {
            state.currentPageGrid = action.payload;
        },
        setCurrentPageGridGeneration: (state, action) => {
            state.currentPageGridGeneration = action.payload;
        },
        toggleIsFullScreen: (state) => {
            state.isFullScreen = !state.isFullScreen;
        },
        toggleIsChatVisible: (state) => {
            state.isChatVisible = !state.isChatVisible;
        },
        toggleIsFollowUpVisibleTrue: (state) => {
            state.isFollowUpVisible = true;
        },
        toggleIsFollowUpVisibleFalse: (state) => {
            state.isFollowUpVisible = false;
        },
        toggleIsAskVisibleTrue: (state) => {
            state.isAskVisible = true;
        },
        toggleIsAskVisibleFalse: (state) => {
            state.isAskVisible = false;
        },
        toggleIsAskImageVisibleTrue: (state) => {
            state.isAskImageVisible = true;
        },
        toggleIsAskImageVisibleFalse: (state) => {
            state.isAskImageVisible = false;
        },
        setFollowUpContent: (state, action) => {
            state.followUpContent = action.payload;
        },
        setAskContent: (state, action) => {
            state.askContent = action.payload;
        },
        setAskImageContent: (state, action) => {
            state.askImageContent = action.payload;
        },
        setIsUploadLoading: (state, action) => {
            state.isUploadLoading = action.payload;
        },
        setCurrentChatInput: (state, action) => {
            state.currentChatInput = action.payload;
        },
        setCurrentChatUserID: (state, action) => {
            state.currentChatUserID = action.payload;
        },
        setCurrentChatClaudeID: (state, action) => {
            state.currentChatClaudeID = action.payload;
        },
        setCurrentChatType: (state, action) => {
            state.currentChatType = action.payload;
        },
        setCurrentChatSelectionText: (state, action) => {
            state.currentChatSelectionText = action.payload;
        },
        setCurrentChatQuestion: (state, action) => {
            state.currentChatQuestion = action.payload;
        },
        addToCurrentChatResponse: (state, action) => {
            state.currentChatResponse = state.currentChatResponse + action.payload;
        },
        resetCurrentChatResponse: (state) => {
            state.currentChatResponse = "";
        },
        setScanEstimatedSeconds: (state, action) => {
            state.scanEstimatedSeconds = action.payload;
        },
        setScanInProgress: (state, action) => {
            state.isScanInProgress = action.payload;
        },
        setScanError: (state, action) => {
            state.isScanError = action.payload;
        },
        setScanFileID: (state, action) => {
            state.scanFileID = action.payload;
        },
        setCeleryTaskID: (state, action) => {
            state.celeryTaskID = action.payload;
        },
        setIsNewFeatureOpen: (state, action) => {
            state.isNewFeatureOpen = action.payload;
        },
        setWhichTab: (state, action) => {
            state.whichTab = action.payload;
        },
        setIsScanActuallyInProgress: (state, action) => {
            state.isScanActuallyInProgress = action.payload;
        }
    },
});

export const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        user: null,
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        }
    },
});

export const documentSlice = createSlice({
    name: 'documentSlice',
    initialState: {
        savedDocuments: [],
        savedGenerations: [],
    },
    reducers: {
        addDocument: (state, action) => {
            state.savedDocuments.unshift(action.payload);
        },
        removeDocument: (state, action) => {
            state.savedDocuments = state.savedDocuments.filter((item) => item.id !== action.payload);
        },
        setDocuments: (state, action) => {
            state.savedDocuments = action.payload;
        },
        changeNameOfDocument: (state, action) => {
            const documentIndex = state.savedDocuments.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                state.savedDocuments[documentIndex].name = action.payload.newName;
            }
        },
        setHighlightsInDocument: (state, action) => {
            const documentIndex = state.savedDocuments.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                state.savedDocuments[documentIndex].highlights = [...action.payload.newHighlights];
            }
        },
        setMessagesInDocument: (state, action) => {
            const documentIndex = state.savedDocuments.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                state.savedDocuments[documentIndex].messages = [...action.payload.newMessages];
            }
        },
        addMessageInDocument: (state, action) => {
            const documentIndex = state.savedDocuments.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                state.savedDocuments[documentIndex].messages.push(action.payload.messageToAdd);
            }
        },
        removeMessageInDocument: (state, action) => {
            const documentIndex = state.savedDocuments.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                state.savedDocuments[documentIndex].messages =
                    state.savedDocuments[documentIndex].messages.filter(message => message.id !== action.payload.messageID);
            }
        },
        appendToMessageInDocument: (state, action) => {
            const documentIndex = state.savedDocuments.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                const messageIndex = state.savedDocuments[documentIndex].messages.findIndex(message => message.id === action.payload.messageID);
                if (messageIndex !== -1) {
                    state.savedDocuments[documentIndex].messages[messageIndex] = {
                        ...state.savedDocuments[documentIndex].messages[messageIndex],
                        response: state.savedDocuments[documentIndex].messages[messageIndex].response + action.payload.response,
                    };
                }
            }
        },
        updateMessageInDocument: (state, action) => {
            const documentIndex = state.savedDocuments.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                const messageIndex = state.savedDocuments[documentIndex].messages.findIndex(message => message.id === action.payload.messageID);
                if (messageIndex !== -1) {
                    state.savedDocuments[documentIndex].messages[messageIndex] = {
                        ...state.savedDocuments[documentIndex].messages[messageIndex],
                        response: action.payload.response,
                    };
                }
            }
        },


        addDocumentGeneration: (state, action) => {
            state.savedGenerations.unshift(action.payload);
        },
        removeDocumentGeneration: (state, action) => {
            state.savedGenerations = state.savedGenerations.filter((item) => item.id !== action.payload);
        },
        setDocumentsGeneration: (state, action) => {
            state.savedGenerations = action.payload;
        },
        changeNameOfDocumentGeneration: (state, action) => {
            const documentIndex = state.savedGenerations.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                state.savedGenerations[documentIndex].name = action.payload.newName;
            }
        },
        setMessagesInDocumentGeneration: (state, action) => {
            const documentIndex = state.savedGenerations.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                state.savedGenerations[documentIndex].messages = [...action.payload.newMessages];
            }
        },
        addMessageInDocumentGeneration: (state, action) => {
            const documentIndex = state.savedGenerations.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                state.savedGenerations[documentIndex].messages.push(action.payload.messageToAdd);
            }
        },
        removeMessageInDocumentGeneration: (state, action) => {
            const documentIndex = state.savedGenerations.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                state.savedGenerations[documentIndex].messages =
                    state.savedGenerations[documentIndex].messages.filter(message => message.id !== action.payload.messageID);
            }
        },
        appendToMessageInDocumentGeneration: (state, action) => {
            const documentIndex = state.savedGenerations.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                const messageIndex = state.savedGenerations[documentIndex].messages.findIndex(message => message.id === action.payload.messageID);
                if (messageIndex !== -1) {
                    state.savedGenerations[documentIndex].messages[messageIndex] = {
                        ...state.savedGenerations[documentIndex].messages[messageIndex],
                        response: state.savedGenerations[documentIndex].messages[messageIndex].response + action.payload.response,
                    };
                }
            }
        },
        updateMessageInDocumentGeneration: (state, action) => {
            const documentIndex = state.savedGenerations.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                const messageIndex = state.savedGenerations[documentIndex].messages.findIndex(message => message.id === action.payload.messageID);
                if (messageIndex !== -1) {
                    state.savedGenerations[documentIndex].messages[messageIndex] = {
                        ...state.savedGenerations[documentIndex].messages[messageIndex],
                        response: action.payload.response,
                    };
                }
            }
        },
        // set the files in a savedGeneration by doing something like this: setFiles(files => files.map((f, i) => i === fileIndex ? { ...f, status: 'error' } : f));
        setFilesInDocumentGeneration: (state, action) => {
            const documentIndex = state.savedGenerations.findIndex(doc => doc.id === action.payload.documentID);
            if (documentIndex !== -1) {
                state.savedGenerations[documentIndex].files = [...action.payload.newFiles];
            }
        },

    },
});

export const sidePanelSlice = createSlice({
    name: 'sidePanelSlice',
    initialState: {
        isSidePanelOpen: false,
        sidebarWidth: "83px",
    },
    reducers: {
        toggleSidePanel: (state) => {
            state.isSidePanelOpen = !state.isSidePanelOpen;
            state.sidebarWidth = state.isSidePanelOpen ? "240px" : "83px";
        }
    },
});

export const { setCurrentPage, setCurrentPageGrid } = settingsSlice.actions;
export const { setUser } = userSlice.actions;
export const { addDocument, removeDocument, setDocuments } = documentSlice.actions;
export const { setHighlightsInDocument, addMessageInDocument,
    removeMessageInDocument, appendToMessageInDocument, updateMessageInDocument,
setMessagesInDocument, changeNameOfDocument,
setMessagesInDocumentGeneration, removeMessageInDocumentGeneration,
    appendToMessageInDocumentGeneration, updateMessageInDocumentGeneration,
    addMessageInDocumentGeneration} = documentSlice.actions;

export const { toggleIsFullScreen, toggleIsChatVisible,
setAskContent, setFollowUpContent, toggleIsFollowUpVisibleTrue,
toggleIsFollowUpVisibleFalse, toggleIsAskVisibleFalse,
    toggleIsAskVisibleTrue, setIsUploadLoading, setCurrentChatInput,
setCurrentChatUserID, setCurrentChatClaudeID, setCurrentChatType,
    setCurrentChatQuestion, setCurrentChatSelectionText,
    addToCurrentChatResponse, resetCurrentChatResponse,
setScanEstimatedSeconds, setScanInProgress, setScanFileID,
setCeleryTaskID, setIsNewFeatureOpen, setIsScanActuallyInProgress,
setCurrentChatInputGeneration, addToCurrentChatResponseGeneration,
    resetCurrentChatResponseGeneration, setWhichTab,
setAskContentGeneration, setCurrentChatClaudeIDGeneration,
    setCurrentChatQuestionGeneration, setCurrentChatSelectionTextGeneration,
    setFollowUpContentGeneration, toggleIsAskVisibleFalseGeneration,
    toggleIsFollowUpVisibleTrueGeneration, setCurrentChatTypeGeneration,
    setCurrentChatUserIDGeneration, toggleIsAskVisibleTrueGeneration,
    toggleIsFollowUpVisibleFalseGeneration,
    setIsUploadSourcesModalOpen,
    setCurrentPageGridGeneration,
    setCurrentGenerationSessionToken,
    setCurrentGenerationProgress,
    setCurrentGenerationWordCount,
    setIsPricingModalOpen,
    setIsProPlan,
    setAskImageContent,
    toggleIsAskImageVisibleTrue,
    setScanError,
    toggleIsAskImageVisibleFalse} = settingsSlice.actions;
export const { toggleSidePanel } = sidePanelSlice.actions;
export const {setDocumentsGeneration, addDocumentGeneration
    , removeDocumentGeneration,
    changeNameOfDocumentGeneration, setFilesInDocumentGeneration, updateProgressInGenerationFile,
updateStatusInGenerationFile, specialUpdateProgressInGenerationFile,
    appendFilesInDocumentGeneration} = documentSlice.actions;

export default {
    "settingsSliceReducer": settingsSlice.reducer,
    "userSliceReducer": userSlice.reducer,
    "documentSliceReducer": documentSlice.reducer,
    "sidePanelSliceReducer": sidePanelSlice.reducer,
}
