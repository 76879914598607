import React, {useEffect, useState} from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box,
    Flex,
    VStack,
    HStack,
    Link,
    PinInput,
    PinInputField,
    useToast,
    Image,
    ModalFooter,
    Icon,
    ListItem,
    ListIcon,
    List,
} from "@chakra-ui/react";
import {useAuth} from "../../../redux/AuthProvider";
import Lottie from "lottie-react";
import animationData from './lottie-scan.json';
import animationDataCheckmark from './lottie-checkmark.json';
import {useDispatch, useSelector} from "react-redux";
import {setIsScanActuallyInProgress, setScanInProgress} from "../../../redux/reducers";
import {useNavigate} from "react-router-dom";
import {FcCancel} from "react-icons/fc";
import {FaCheckCircle} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const ScanModal2 = ({ isOpen, onClose, stopPolling }) => {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [dotCount, setDotCount] = useState(1);

    const isScanInProgress = useSelector(state => state.settings.isScanInProgress)
    const scanFileID = useSelector(state => state.settings.scanFileID)
    const scanEstimatedSeconds = useSelector(state => state.settings.scanEstimatedSeconds)
    const isScanActuallyInProgress = useSelector(state => state.settings.isScanActuallyInProgress)
    const isScanError = useSelector(state => state.settings.isScanError)
    const {t} = useTranslation();

    const dispatch = useDispatch()
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isScanActuallyInProgress) {
            setCountdown(scanEstimatedSeconds);
            const interval = setInterval(() => {
                setCountdown(prev => {
                    if (prev > 1) return prev - 1;
                    clearInterval(interval);
                    return 0;
                });
            }, 1000);

            const dotInterval = setInterval(() => {
                setDotCount(prev => (prev % 3) + 1);
            }, 500);

            return () => {
                clearInterval(interval);
                clearInterval(dotInterval);
            };
        }
    }, [isScanActuallyInProgress, scanEstimatedSeconds]);

    const renderDots = () => '.'.repeat(dotCount);

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
            <ModalOverlay />
            <ModalContent borderRadius="1rem" p="0.75rem" h="37rem" w="35rem" overflowY="auto" position="relative">
                <Box
                    position="absolute"
                    top="10px"
                    right="10px"
                    zIndex={999}
                >
                    <CloseButton
                        size="md"
                        onClick={() => {
                            onClose();
                            stopPolling();
                            dispatch(setScanInProgress(true))
                            dispatch(setIsScanActuallyInProgress(false))
                        }}
                        bg="transparent"
                        borderRadius="50%"
                        _hover={{
                            bg: "gray.200",
                            borderRadius: "50%",
                        }}
                    />
                </Box>
                <Flex alignItems="center" justifyContent="space-between" p={4} >
                    <VStack align="center" width={'100%'} spacing={4}>
                        <Text fontSize="xl" fontWeight="bold">
                            {isScanInProgress ? t("Preparing your document...") : isScanError ? t("Scan Failed") : t("Document is ready!")}
                        </Text>
                        <Box w={'full'} h={'1px'} bg={'gray.300'} />
                    </VStack>
                </Flex>
                <ModalBody>
                    {isScanInProgress ?
                        <Box bg={'white'} align={'center'}>
                            <Text fontSize={"sm"}>{t("Please do not refresh the page.")}</Text>
                            <Lottie
                                animationData={animationData}
                                loop={true}
                                autoplay={true}
                                style={{ width: '75%', height: '75%', backgroundColor:'white',
                                    marginTop: '-2rem', marginBottom:'-2rem'}}
                            />
                            <Text fontSize={"sm"}>
                                {countdown > 0
                                    ? t("Estimated time remaining: {{countdown}} seconds", { countdown })
                                    : t("Almost there! Just a bit more{{dots}}", { dots: renderDots() })}
                            </Text>
                        </Box> :
                        isScanError ?
                            <Box bg={'white'} align={'center'} mt={0}>
                                <Icon as={FcCancel} w={20} h={20} />
                                <VStack spacing={4} mt={4}>
                                    <Text fontSize={17} fontWeight={'bold'}>{t("Failed to convert this scanned PDF because it may be malformed or corrupted.")}</Text>
                                    <List spacing={3} textAlign="start" mt={4}>
                                        {/*<ListItem key={1}>*/}
                                        {/*    <Flex align="start">*/}
                                        {/*        <ListIcon as={FcCancel} color="green.500" mt={1} mr={3} />*/}
                                        {/*        <Text fontSize={'sm'}>The PDF is malformed</Text>*/}
                                        {/*    </Flex>*/}
                                        {/*</ListItem>*/}
                                        {/*<ListItem key={2}>*/}
                                        {/*    <Flex align="start">*/}
                                        {/*        <ListIcon as={FcCancel} color="green.500" mt={1} mr={3} />*/}
                                        {/*        <Text fontSize={"sm"}>The PDF is corrupted</Text>*/}
                                        {/*    </Flex>*/}
                                        {/*</ListItem>*/}
                                        {/*<ListItem key={2}>*/}
                                        {/*    <Flex align="start">*/}
                                        {/*        <ListIcon as={FcCancel} color="green.500" mt={1} mr={3} />*/}
                                        {/*        <Text fontSize={"sm"}>The PDF is too big</Text>*/}
                                        {/*    </Flex>*/}
                                        {/*</ListItem>*/}
                                        {/*<ListItem key={2}>*/}
                                        {/*    <Flex align="start">*/}
                                        {/*        <ListIcon as={FcCancel} color="green.500" mt={1} mr={3} />*/}
                                        {/*        <Text fontSize={"sm"}>You can try sizing down the PDF (to 10mb or less) using an online tool and resubmit it</Text>*/}
                                        {/*    </Flex>*/}
                                        {/*</ListItem>*/}
                                        <ListItem key={2}>
                                            <Flex align="start">
                                                <ListIcon as={FcCancel} color="green.500" mt={1} mr={3} />
                                                <Text fontSize={15}>{t("You can email your PDF to contact@honeybear.ai, and we'll get back to you about it!")}</Text>
                                            </Flex>
                                        </ListItem>
                                    </List>
                                </VStack>
                            </Box> :
                        <Box bg={'white'} align={'center'} mt={10}>
                            <Lottie
                                animationData={animationDataCheckmark}
                                loop={false}
                                autoplay={true}
                                style={{ width: '50%', height: '50%' }}
                            />
                        </Box>
                    }
                </ModalBody>
                <ModalFooter>
                    <Flex width="100%" justify="center">
                        <Button
                            loadingText="Submitting"
                            size="lg"
                            isLoading={isLoading}
                            onClick={() => {
                                if (isScanInProgress || isScanError) {
                                    setIsLoading(true)
                                    onClose()
                                    stopPolling()
                                    dispatch(setIsScanActuallyInProgress(false))
                                    setIsLoading(false)
                                } else {
                                    setIsLoading(true)
                                    navigate(`/pdf/${scanFileID}`)
                                    onClose()
                                    window.location.reload()
                                    setIsLoading(false)
                                }
                            }}
                            bgGradient={isScanInProgress || isScanError ? 'gray.200' : 'linear(to-r, red.400,pink.400)'}
                            color={isScanInProgress || isScanError ? 'gray.800' : 'white'}
                            width={'55%'}
                            _hover={{
                                boxShadow: 'xl',
                            }}
                        >
                            {isScanInProgress || isScanError ? t('Cancel') : t('Go to Document')}
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ScanModal2;
