import React, {useEffect, useState} from 'react';
import {Box, Button, Flex, Input, Radio, RadioGroup, Stack, Text, useBreakpointValue, useToast} from '@chakra-ui/react';
import {useNavigate} from "react-router-dom";

const NameStep = ({ value, onChange }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';

    useEffect(() => {
        if (localStorage.getItem("firstNamePhoto")) {
            setFirstName(localStorage.getItem("firstNamePhoto"));
        }
        if (localStorage.getItem("lastNamePhoto")) {
            setLastName(localStorage.getItem("lastNamePhoto"));
        }
    }, []);

    return (
        <Box textAlign="center" mt={8}>
            <Text fontSize={isMobile ? 28 : 46} fontWeight="bold" mb={16} color={'black'}>
                What's your name?
            </Text>
            <Flex justifyContent="center" mt={20}>
                <Stack spacing={4} width="22rem">
                    <Input
                        autoFocus={true}
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        size="lg"
                        borderRadius="md"
                    />
                    <Input
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        size="lg"
                        borderRadius="md"
                    />
                </Stack>
            </Flex>

            <Box
                position="absolute"
                bottom={0}
                width="100vw"
                right={0}
                height={'13vh'}
                bg="white"
                textAlign="center"
                pt={8}
                boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
            >
                <Button onClick={() => {
                    if (firstName === '') {
                        toast({
                            title: "First name is required.",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        })
                        return;
                    }
                    if (lastName === '') {
                        toast({
                            title: "Last name is required.",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        })
                        return;
                    }
                    localStorage.setItem('firstNamePhoto', firstName);
                    localStorage.setItem('lastNamePhoto', lastName);
                    navigate("?step=email");
                }}
                        size="lg"
                        width={isMobile ? '66%' : '15%'}
                        borderRadius={50}
                        bgGradient="linear(to-r, red.400,pink.400)"
                        color={'white'}
                        _hover={{
                            bgGradient: 'linear(to-r, red.400,pink.400)',
                            boxShadow: 'xl',
                        }}>
                    Continue
                </Button>
            </Box>
        </Box>
    );
};

export default NameStep;














