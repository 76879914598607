import React, {useEffect, useState} from 'react';
import {Box, Button, Flex, Input, Radio, RadioGroup, Stack, Text, useBreakpointValue, useToast} from '@chakra-ui/react';
import {useNavigate} from "react-router-dom";

const NameStep = ({ value, onChange }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [email, setEmail] = useState("");
    const [emailConfirm, setEmailConfirm] = useState("");
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';

    useEffect(() => {
        if (localStorage.getItem("emailPhoto")) {
            setEmail(localStorage.getItem("emailPhoto"));
        }
        if (localStorage.getItem("emailConfirmPhoto")) {
            setEmailConfirm(localStorage.getItem("emailConfirmPhoto"));
        }
    }, []);

    return (
        <Box textAlign="center" mt={8}>
            <Text fontSize={isMobile ? 28 : 46} fontWeight="bold" mb={4} color={'black'}>
                What's your email?
            </Text>
            <Text fontSize={17} color="gray.800" mb={16}>
                This is where your beautiful finished photos will be sent
            </Text>
            <Flex justifyContent="center" mt={20}>
                <Stack spacing={4} width="22rem">
                    <Input
                        autoFocus={true}
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        size="lg"
                        borderRadius="md"
                    />
                    <Input
                        placeholder="Confirm email address"
                        value={emailConfirm}
                        onChange={(e) => setEmailConfirm(e.target.value)}
                        size="lg"
                        borderRadius="md"
                    />
                </Stack>
            </Flex>




            <Box
                position="absolute"
                bottom={0}
                width="100vw"
                right={0}
                height={'13vh'}
                bg="white"
                textAlign="center"
                pt={8}
                boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
            >
                <Button onClick={() => {
                    if (email === '') {
                        toast({
                            title: "Please enter your email.",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        })
                        return;
                    }
                    if (!email.includes('@')) {
                        toast({
                            title: "Enter a valid email address with an @ symbol",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        })
                        return;
                    }
                    if (emailConfirm === '') {
                        toast({
                            title: "Please confirm your email.",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        })
                        return;
                    }
                    if (email.trim().toLowerCase() !== emailConfirm.trim().toLowerCase()) {
                        toast({
                            title: "Emails do not match.",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        })
                        return;
                    }
                    localStorage.setItem('emailPhoto', email.trim().toLowerCase());
                    localStorage.setItem('emailConfirmPhoto', emailConfirm.trim().toLowerCase());
                    navigate("?step=gender");
                }}
                        size="lg"
                        width={isMobile ? '66%' : '15%'}
                        borderRadius={50}
                        bgGradient="linear(to-r, red.400,pink.400)"
                        color={'white'}
                        _hover={{
                            bgGradient: 'linear(to-r, red.400,pink.400)',
                            boxShadow: 'xl',
                        }}>
                    Continue
                </Button>
            </Box>






        </Box>
    );
};

export default NameStep;














