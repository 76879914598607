import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    Box,
    HStack,
    Text,
    VStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Image,
    IconButton,
    Grid,
    Progress,
    Tooltip,
    Spinner, useToast, Button, useBreakpointValue, useDisclosure,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { DeleteIcon, InfoIcon } from '@chakra-ui/icons';
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import axios from 'axios';
import {SERVER_IP} from "../../utils/constants";
import {useNavigate} from "react-router-dom";
import {saveFormData} from "../../utils/localStoragePhoto";
import heic2any from 'heic2any';
import MinModal from "../../components/Modals/Scans/MinModal";

const UploadStep = () => {
    const [files, setFiles] = useState([]);
    const [mainButtonLoading, setMainButtonLoading] = useState(false);
    const fileInput = useRef();
    const toast = useToast();
    const navigate = useNavigate();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();

    const onDrop = useCallback(async (acceptedFiles) => {
        console.log('LENGTH', files.length);
        if (files.length + acceptedFiles.length > 10) {
            toast({
                title: 'You can upload a maximum of 10 photos.',
                status: "error",
                duration: 8000,
                isClosable: true,
            });
            return;
        }

        const newFilesPromises = acceptedFiles.map(async (file) => {
            if (file.type === 'image/heic') {
                try {
                    const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
                    const convertedFile = new File([convertedBlob], file.name.replace(/\.heic$/i, ".jpeg"), { type: "image/jpeg" });
                    return {
                        id: uuidv4(),
                        file: convertedFile,
                        name: convertedFile.name,
                        status: 'uploading', // 'uploading', 'success', 'error'
                        progress: 0
                    };
                } catch (error) {
                    // Check for specific error and ignore if the image is already readable
                    if (error.message.includes("ERR_USER Image is already browser readable")) {
                        return {
                            id: uuidv4(),
                            file: file,
                            name: file.name,
                            status: 'uploading', // 'uploading', 'success', 'error'
                            progress: 0
                        };
                    } else {
                        console.error('HEIC conversion error:', error);
                        toast({
                            title: 'Error converting HEIC file.',
                            status: "error",
                            duration: 8000,
                            isClosable: true,
                        });
                        return null;
                    }
                }
            } else {
                return {
                    id: uuidv4(),
                    file: file,
                    name: file.name,
                    status: 'uploading', // 'uploading', 'success', 'error'
                    progress: 0
                };
            }
        });

        const newFiles = (await Promise.all(newFilesPromises)).filter(Boolean);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);

        for (const file of newFiles) {
            console.log("FILE", file);
            uploadFile(file);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*, .heic',
        multiple: true,
    });

    const retrieveFormInformation = async () => {
        const requestBody = {
            checkout_id: localStorage.getItem('formIDPhoto'),
        }
        console.log("/tubby/retrieve-form-information body", requestBody);

        try {
            const response = await axios.post(SERVER_IP + '/tubby/retrieve-form-information', requestBody);
            console.log('Retrieve FORM Response:', response);
            if (response.data.status === 200) {
                return {
                    images: response.data.message.image_urls,
                };
                // setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
                // const storedFiles = JSON.parse(localStorage.getItem('uploadedImagesPhoto')) || [];
                // const updatedFiles = storedFiles.filter((file) => file.id !== id);
                // localStorage.setItem('uploadedImagesPhoto', JSON.stringify(updatedFiles));
                // return 200;
            }
        } catch (error) {
            toast({
                title: 'Oops, something went wrong. Please try again.',
                status: "error",
                duration: 8000,
                isClosable: true,
            })
            console.error('Error retrieving form info:', error);
        }
    }

    useEffect(() => {
        console.log("EFFECT FIRED");

        const storedFiles = JSON.parse(localStorage.getItem('uploadedImagesPhoto')) || [];
        console.log("storedFiles", storedFiles);

        if (storedFiles.length > 0 && localStorage.getItem("formIDPhoto")) {
            retrieveFormInformation().then(r => {
                // console.log("retrieveFormInformation", r);
                if (r) {
                    const images = r.images;
                    console.log("images", images)
                    const newFiles = images.map((image) => ({
                        id: uuidv4(),
                        file: image.image_url,
                        name: image.filename,
                        status: 'success', // 'uploading', 'success', 'error'
                        progress: 100
                    }));
                    console.log("files being set", newFiles)
                    setFiles(newFiles); // Make sure to update the state with the new files
                }
            });
        } else {
            setFiles([]);
        }
    }, []);

    const handleFileDelete = async (id, checkoutID, fileName) => {
        // console.log('Removing file:', id, checkoutID, fileName)

        console.log("requestBody for image delete", {
            checkout_id: checkoutID,
            image_filename: fileName,
        });

        try {
            const response = await axios.post(SERVER_IP + '/tubby/delete-image-from-form', {
                checkout_id: checkoutID,
                image_filename: fileName,
            });
            console.log('Remove Image Response:', response);
            if (response.data.status === 200) {
                setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
                const storedFiles = JSON.parse(localStorage.getItem('uploadedImagesPhoto')) || [];
                const updatedFiles = storedFiles.filter((file) => file.id !== id);
                localStorage.setItem('uploadedImagesPhoto', JSON.stringify(updatedFiles));
                return 200;
            }
        } catch (error) {
            toast({
                title: 'Oops, something went wrong. Please try again.',
                status: "error",
                duration: 8000,
                isClosable: true,
            })
            console.error('Error removing the image:', error);
        }
    }

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file.file);
        formData.append('checkout_id', localStorage.getItem('formIDPhoto'));

        try {
            const response = await axios.post(SERVER_IP + '/tubby/append-image-to-form', formData, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setFiles((prevFiles) =>
                        prevFiles.map((f) =>
                            f.id === file.id ? { ...f, progress } : f
                        )
                    );
                }
            });

            console.log('Upload PHOTO RESPONSE', response);
            const { filename, presigned_url } = response.data.message;

            setFiles((prevFiles) =>
                prevFiles.map((f) =>
                    f.id === file.id ? { ...f, status: 'success', file: presigned_url, name: filename } : f
                )
            );
            // Save to localStorage
            const storedFiles = JSON.parse(localStorage.getItem('uploadedImagesPhoto')) || [];
            const updatedFiles = storedFiles.concat([{ id: file.id, file: file.file, name: filename, status: 'success' }]);

            console.log("saving to local storage", updatedFiles);
            localStorage.setItem('uploadedImagesPhoto', JSON.stringify(updatedFiles));
        } catch (error) {
            setFiles((prevFiles) =>
                prevFiles.map((f) =>
                    f.id === file.id ? { ...f, status: 'error' } : f
                )
            );
        }
    };

    return (
        <Box bg="white" width="100%" height="100%" display="flex" justifyContent="center">
            {isMobile ?
                <VStack mt={8} bg="white" spacing={10}>
                    <Box width={{ base: '100%', md: '25%' }} height="100%" bg="white" borderRightWidth={1} borderRightColor="lightgray" pr={4}>
                        <VStack align="start" p={4} spacing={6}>
                            <Text fontSize="2xl" fontWeight="bold">
                                Upload photos
                            </Text>
                            <Text>
                                Now the fun begins! Select at least 6 of your best photos. Good photos will result in amazing headshots!
                            </Text>
                            <VStack spacing={4} height="12rem" width="100%">
                                <Box
                                    {...getRootProps()}
                                    border="2px"
                                    borderColor="orange.400"
                                    borderRadius="md"
                                    borderStyle="dashed"
                                    textAlign="center"
                                    p="2"
                                    cursor="pointer"
                                    _hover={{ bg: 'gray.100' }}
                                    transition={{ duration: 0.2 }}
                                    width="100%"
                                    height="100%"
                                    onClick={() => {
                                        fileInput.current.click();
                                    }}
                                >
                                    <input {...getInputProps({ accept: 'image/*, .heic' })} ref={fileInput} />
                                    <Box as="span" color="gray.400" fontSize="3xl">
                                        ⇧
                                    </Box>
                                    <Text fontWeight="bold" color="gray.400" fontSize="sm">
                                        Drag & Drop
                                    </Text>
                                    <Text color="gray.500" fontSize="sm">or click to browse</Text>
                                </Box>
                                <Text fontSize={14}>
                                    PNG, JPG, HEIC up to 140MB
                                </Text>
                            </VStack>
                        </VStack>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Box mb={8}>
                            <HStack display="flex" flexDirection="row" justifyContent="space-between">
                                <Text fontSize="lg" fontWeight="bold">Uploaded Images</Text>
                                <Text>{files.length} / 10</Text>
                            </HStack>
                            <HStack justifyContent="space-between" mt={4}>
                                <Progress value={(files.length / 10) * 100} size="sm" width="100%" colorScheme="yellow" />
                                <Box position="absolute" left="66%">
                                    <Text position="absolute" top="-30px" left="60%" transform="translateX(-60%)">
                                        6
                                    </Text>
                                    <Tooltip label="Please upload at least 6 photos to create your headshots">
                                        <InfoIcon position="absolute" top="-50px" left="60%" transform="translateX(-60%)" />
                                    </Tooltip>
                                    <Box height="8px" width="2px" bg="black" position="absolute" top="-1" left="60%" transform="translateX(-60%)"></Box>
                                </Box>
                            </HStack>
                        </Box>
                        <Box width={{ base: '100%', md: '100%' }} height="100%" overflowY="auto">
                            <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
                                {files.length > 0 && (
                                    <AccordionItem mb={4} borderRadius="md" borderWidth="1px" overflow="hidden" bg="rgba(245,245,245,1)">
                                        <AccordionButton px={6} py={4}>
                                            <Box flex="1" textAlign="left" fontSize="xl" fontWeight="600">
                                                Uploaded Photos
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel>
                                            <Grid templateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap={4} mt={4}>
                                                {files.map((file) => (
                                                    <Box key={file.id} position="relative">
                                                        {file.status === 'uploading' ? (
                                                            <Box
                                                                width="11rem"
                                                                height="15rem"
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                bg="gray.100"
                                                                borderRadius={15}
                                                                position="relative"
                                                            >
                                                                <Spinner size="xl" />
                                                                <Text position="absolute" bottom={2}>{file.progress}%</Text>
                                                            </Box>
                                                        ) : file.status === 'success' ? (
                                                            <Image
                                                                src={file.file}
                                                                alt={file.name}
                                                                width="11rem"
                                                                height="15rem"
                                                                objectFit="cover"
                                                                borderRadius={15}
                                                            />
                                                        ) : (
                                                            <Box
                                                                width="11rem"
                                                                height="15rem"
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                bg="red.100"
                                                                borderRadius={15}
                                                                position="relative"
                                                            >
                                                                <FaTimesCircle size="xl" color="red" />
                                                            </Box>
                                                        )}
                                                        <IconButton
                                                            icon={<DeleteIcon />}
                                                            size="sm"
                                                            bg="white"
                                                            borderRadius={100}
                                                            borderWidth={0.25}
                                                            borderColor="gray"
                                                            position="absolute"
                                                            top="-3"
                                                            right="-3"
                                                            onClick={() => {
                                                                handleFileDelete(file.id, localStorage.getItem('formIDPhoto'), file.name);
                                                            }}
                                                        />
                                                    </Box>
                                                ))}
                                            </Grid>
                                        </AccordionPanel>
                                    </AccordionItem>
                                )}
                                <AccordionItem mb={4} borderRadius="md" borderWidth="1px" overflow="hidden" bg="rgb(212,255,214)">
                                    <AccordionButton px={6} py={4}>
                                        <Box flex="1" textAlign="left" fontSize="lg" fontWeight="bold">
                                            Photo Requirements
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel>
                                        <Grid templateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap={4}>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/1181391/pexels-photo-1181391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                       alt="Good Photo 1" height={'14rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">Recent:</Text> Recent photos, ideally within the past year.
                                                </Text>
                                            </VStack>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                       alt="Good Photo 1" height={'14rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">Looking at the camera:</Text> Make eye contact with the camera!
                                                </Text>
                                            </VStack>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/898768/pexels-photo-898768.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                       alt="Good Photo 1" height={'14rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">Variety:</Text> Include photos in at least 2 different outfits and backgrounds.
                                                </Text>
                                            </VStack>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/1181686/pexels-photo-1181686.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                       alt="Good Photo 1" height={'14rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">Clear:</Text> Good lighting and not too close or far from the camera.
                                                </Text>
                                            </VStack>
                                        </Grid>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem mb={4} borderRadius="md" borderWidth="1px" overflow="hidden" bg="rgb(255,212,212)">
                                    <AccordionButton px={6} py={4}>
                                        <Box flex="1" textAlign="left" fontSize="lg" fontWeight="bold">
                                            Photo Restrictions
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel>
                                        <Grid templateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap={4}>
                                            <VStack>
                                                <Image src="https://cdn.pixabay.com/photo/2012/02/29/15/52/adult-19210_1280.jpg"
                                                       alt="Bad Photo 1" height={'13rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">No silly faces: </Text>
                                                    No unusual expressions (e.g. tongue out, duck faces).
                                                </Text>
                                            </VStack>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/10763173/pexels-photo-10763173.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                       alt="Bad Photo 2" height={'13rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">No accessories: </Text>
                                                    (e.g. backpacks, hats, headphones, sunglasses).
                                                </Text>
                                            </VStack>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/4708489/pexels-photo-4708489.jpeg"
                                                       alt="Bad Photo 3" height={'13rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">No revealing clothing: </Text>
                                                    (e.g. shirtless, tank tops, bikinis).
                                                </Text>
                                            </VStack>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/6310000/pexels-photo-6310000.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                       alt="Bad Photo 4" height={'13rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">No side views: </Text>
                                                    No side shots. Your entire face should be directly facing the camera.
                                                </Text>
                                            </VStack>
                                        </Grid>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                            <Box height="100px" />
                        </Box>
                    </Box>
                </VStack> :









                <HStack mt={8} bg="white" width="80%" height="100%" spacing={10}>
                    <Box width={{ base: '100%', md: '25%' }} height="100%" bg="white" borderRightWidth={1} borderRightColor="lightgray" pr={4}>
                        <VStack align="start" p={4} spacing={6}>
                            <Text fontSize="2xl" fontWeight="bold">
                                Upload photos
                            </Text>
                            <Text>
                                Now the fun begins! Select at least 6 of your best photos. Good photos will result in amazing headshots!
                            </Text>
                            <VStack spacing={4} height="12rem" width="100%">
                                <Box
                                    {...getRootProps()}
                                    border="2px"
                                    borderColor="orange.400"
                                    borderRadius="md"
                                    borderStyle="dashed"
                                    textAlign="center"
                                    p="2"
                                    cursor="pointer"
                                    _hover={{ bg: 'gray.100' }}
                                    transition={{ duration: 0.2 }}
                                    width="100%"
                                    height="100%"
                                    onClick={() => {
                                        fileInput.current.click();
                                    }}
                                >
                                    <input {...getInputProps({ accept: 'image/*, .heic' })} ref={fileInput} />
                                    <Box as="span" color="gray.400" fontSize="3xl">
                                        ⇧
                                    </Box>
                                    <Text fontWeight="bold" color="gray.400" fontSize="sm">
                                        Drag & Drop
                                    </Text>
                                    <Text color="gray.500" fontSize="sm">or click to browse</Text>
                                </Box>
                                <Text fontSize={14}>
                                    PNG, JPG, HEIC up to 140MB
                                </Text>
                            </VStack>
                        </VStack>
                    </Box>
                    <Box width={{ base: '100%', md: '75%' }} height="100%" display="flex" flexDirection="column">
                        <Box mb={8}>
                            <HStack display="flex" flexDirection="row" justifyContent="space-between">
                                <Text fontSize="lg" fontWeight="bold">Uploaded Images</Text>
                                <Text>{files.length} / 10</Text>
                            </HStack>
                            <HStack justifyContent="space-between" mt={4}>
                                <Progress value={(files.length / 10) * 100} size="sm" width="100%" colorScheme="yellow" />
                                <Box position="absolute" left="66%">
                                    <Text position="absolute" top="-30px" left="60%" transform="translateX(-60%)">
                                        6
                                    </Text>
                                    <Tooltip label="Please upload at least 6 photos to create your headshots">
                                        <InfoIcon position="absolute" top="-50px" left="60%" transform="translateX(-60%)" />
                                    </Tooltip>
                                    <Box height="8px" width="2px" bg="black" position="absolute" top="-1" left="60%" transform="translateX(-60%)"></Box>
                                </Box>
                            </HStack>
                        </Box>
                        <Box width={{ base: '100%', md: '100%' }} height="100%" overflowY="auto">
                            <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
                                {files.length > 0 && (
                                    <AccordionItem mb={4} borderRadius="md" borderWidth="1px" overflow="hidden" bg="rgba(245,245,245,1)">
                                        <AccordionButton px={6} py={4}>
                                            <Box flex="1" textAlign="left" fontSize="xl" fontWeight="600">
                                                Uploaded Photos
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel>
                                            <Grid templateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap={4} mt={4}>
                                                {files.map((file) => (
                                                    <Box key={file.id} position="relative">
                                                        {file.status === 'uploading' ? (
                                                            <Box
                                                                width="11rem"
                                                                height="15rem"
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                bg="gray.100"
                                                                borderRadius={15}
                                                                position="relative"
                                                            >
                                                                <Spinner size="xl" />
                                                                <Text position="absolute" bottom={2}>{file.progress}%</Text>
                                                            </Box>
                                                        ) : file.status === 'success' ? (
                                                            <Image
                                                                src={file.file}
                                                                alt={file.name}
                                                                width="11rem"
                                                                height="15rem"
                                                                objectFit="cover"
                                                                borderRadius={15}
                                                            />
                                                        ) : (
                                                            <Box
                                                                width="11rem"
                                                                height="15rem"
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                bg="red.100"
                                                                borderRadius={15}
                                                                position="relative"
                                                            >
                                                                <FaTimesCircle size="xl" color="red" />
                                                            </Box>
                                                        )}
                                                        <IconButton
                                                            icon={<DeleteIcon />}
                                                            size="sm"
                                                            bg="white"
                                                            borderRadius={100}
                                                            borderWidth={0.25}
                                                            borderColor="gray"
                                                            position="absolute"
                                                            top="-3"
                                                            right="-3"
                                                            onClick={() => {
                                                                handleFileDelete(file.id, localStorage.getItem('formIDPhoto'), file.name);
                                                            }}
                                                        />
                                                    </Box>
                                                ))}
                                            </Grid>
                                        </AccordionPanel>
                                    </AccordionItem>
                                )}
                                <AccordionItem mb={4} borderRadius="md" borderWidth="1px" overflow="hidden" bg="rgb(212,255,214)">
                                    <AccordionButton px={6} py={4}>
                                        <Box flex="1" textAlign="left" fontSize="lg" fontWeight="bold">
                                            Photo Requirements
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel>
                                        <Grid templateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap={4}>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/1181391/pexels-photo-1181391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                       alt="Good Photo 1" height={'14rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">Recent:</Text> Recent photos, ideally within the past year.
                                                </Text>
                                            </VStack>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                       alt="Good Photo 1" height={'14rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">Looking at the camera:</Text> Make eye contact with the camera!
                                                </Text>
                                            </VStack>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/898768/pexels-photo-898768.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                       alt="Good Photo 1" height={'14rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">Variety:</Text> Include photos in at least 2 different outfits and backgrounds.
                                                </Text>
                                            </VStack>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/1181686/pexels-photo-1181686.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                       alt="Good Photo 1" height={'14rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">Clear:</Text> Good lighting and not too close or far from the camera.
                                                </Text>
                                            </VStack>
                                        </Grid>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem mb={4} borderRadius="md" borderWidth="1px" overflow="hidden" bg="rgb(255,212,212)">
                                    <AccordionButton px={6} py={4}>
                                        <Box flex="1" textAlign="left" fontSize="lg" fontWeight="bold">
                                            Photo Restrictions
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel>
                                        <Grid templateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap={4}>
                                            <VStack>
                                                <Image src="https://cdn.pixabay.com/photo/2012/02/29/15/52/adult-19210_1280.jpg"
                                                       alt="Bad Photo 1" height={'13rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">No silly faces: </Text>
                                                    No unusual expressions (e.g. tongue out, duck faces).
                                                </Text>
                                            </VStack>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/10763173/pexels-photo-10763173.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                       alt="Bad Photo 2" height={'13rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">No accessories: </Text>
                                                    (e.g. backpacks, hats, headphones, sunglasses).
                                                </Text>
                                            </VStack>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/4708489/pexels-photo-4708489.jpeg"
                                                       alt="Bad Photo 3" height={'13rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">No revealing clothing: </Text>
                                                    (e.g. shirtless, tank tops, bikinis).
                                                </Text>
                                            </VStack>
                                            <VStack>
                                                <Image src="https://images.pexels.com/photos/6310000/pexels-photo-6310000.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                       alt="Bad Photo 4" height={'13rem'} width={'9.5rem'} borderRadius={15}/>
                                                <Text fontSize={14} color="black">
                                                    <Text as="span" fontWeight="bold">No side views: </Text>
                                                    No side shots. Your entire face should be directly facing the camera.
                                                </Text>
                                            </VStack>
                                        </Grid>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                            <Box height="100px" />
                        </Box>
                    </Box>
                </HStack>}











            <Box
                position="fixed"
                bottom={0}
                width="100vw"
                right={0}
                height="13vh"
                bg="white"
                textAlign="center"
                pt={8}
                boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
            >
                <Button
                    isLoading={mainButtonLoading}
                    onClick={() => {
                        if (files.length >= 3 && files.length < 6) {
                            onOpenModal();
                            return;
                        }
                        else if (files.length === 2) {
                            toast({
                                title: "Please upload at least 1 more photo!",
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                            });
                            return;
                        }
                        else if (files.length === 1) {
                            toast({
                                title: "Please upload at least 2 more photos!",
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                            });
                            return;
                        }
                        else if (files.length === 0) {
                            toast({
                                title: "Please upload at least 3 photos",
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                            });
                            return;
                        }

                        navigate("?step=name");

                        // setMainButtonLoading(true);
                        // saveFormData(localStorage.getItem("formIDPhoto"), {
                        //     gender: localStorage.getItem("genderPhoto"),
                        //     first_name: localStorage.getItem("firstNamePhoto"),
                        //     last_name: localStorage.getItem("lastNamePhoto"),
                        //     email: localStorage.getItem("emailPhoto"),
                        // }).then(r => {
                        //     setMainButtonLoading(false);
                        //     navigate("?step=name");
                        //     console.log("saveFormData", r)
                        // })
                    }}
                    size="lg"
                    width={isMobile ? '66%' : '15%'}
                    borderRadius={50}
                    bgGradient="linear(to-r, red.400,pink.400)"
                    color="white"
                    _hover={{
                        bgGradient: 'linear(to-r, red.400,pink.400)',
                        boxShadow: 'xl',
                    }}
                >
                    Continue
                </Button>
            </Box>
            <MinModal isOpenModal={isOpenModal} onCloseModal={onCloseModal}
                             onOpenModal={onOpenModal} fileInput={fileInput}/>
        </Box>
    );
};

export default UploadStep;
