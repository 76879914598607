import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {Box, Button, Stack, Progress, IconButton, Text, HStack, useBreakpointValue} from '@chakra-ui/react';
import { ArrowBackIcon, CloseIcon } from '@chakra-ui/icons';
import AgeStep from './AgeStep';
import GenderStep from './GenderStep';
import EyeColorStep from "./EyeColorStep";
import HairColorStep from "./HairColorStep";
import EthnicityStep from "./EthnicityStep";
import UploadStep from "./UploadStep";
import EmailStep from "./EmailStep";
import PreviewStep from "./PreviewStep";
import NameStep from "./NameStep";
import {createFormID} from "../../utils/localStoragePhoto";

const steps = [
    {
        key: 'upload',
        component: UploadStep,
    },
    {
        key: 'name',
        component: NameStep,
    },
    {
        key: 'email',
        component: EmailStep,
    },
    {
        key: 'gender',
        component: GenderStep,
    },
    {
        key: 'preview',
        component: PreviewStep,
    },
    // {
    //     key: 'age',
    //     component: AgeStep,
    // },
    // {
    //     key: 'eyeColor',
    //     component: EyeColorStep,
    // },
    // {
    //     key: 'hairColor',
    //     component: HairColorStep,
    // },
    // {
    //     key: 'ethnicity',
    //     component: EthnicityStep,
    // },
    // Add more steps as needed
];

const MultiStepForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const stepKey = queryParams.get('step') || steps[0].key;

    const currentStepIndex = steps.findIndex(step => step.key === stepKey);
    const [formData, setFormData] = useState({});
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';

    useEffect(() => {
        if (localStorage.getItem("formIDPhoto") !== null) {

        } else {
            createFormID().then(r => {
                localStorage.setItem("formIDPhoto", r.form_completion_id)
            });
        }
    }, []);

    useEffect(() => {
        if (currentStepIndex === -1) {
            navigate(`?step=${steps[0].key}`, { replace: true });
        }
    }, [currentStepIndex, navigate]);

    const handleNext = () => {
        if (currentStepIndex < steps.length - 1) {
            navigate(`?step=${steps[currentStepIndex + 1].key}`);
        }
    };

    const handleBack = () => {
        if (currentStepIndex > 0) {
            navigate(`?step=${steps[currentStepIndex - 1].key}`);
        } else if (currentStepIndex === 0) {
            navigate('/');
        }
    };

    const handleOptionChange = (value) => {
        setFormData({
            ...formData,
            [stepKey]: value,
        });
    };

    if (currentStepIndex === -1) return null;

    const StepComponent = steps[currentStepIndex].component;
    const progress = ((currentStepIndex + 1) / steps.length) * 100;

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'space-between'} height={'100vh'}>
            <Box p={8} width={'100vw'} height={'87vh'} bg={'white'}>
                <Box position="relative" mb={8} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Button
                        size="md"
                        fontSize={14}
                        bg={'white'} color={'black'}
                        _hover={{
                            bg: 'gray.100',
                        }}
                        borderColor={'lightgray'} borderWidth={0.25}
                        shadow={`1.5px 1.8px 0px lightgray`}
                        onClick={handleBack}
                        variant="ghost"
                        position="absolute"
                        top="50%"
                        left="0"
                        transform="translateY(-50%)"
                    >
                        Back
                    </Button>
                    <HStack width={'70%'} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} spacing={6}>
                        <Progress value={progress} size="sm" colorScheme="orange" width={isMobile ? '51%' : '100%'} borderRadius={15}/>
                        <Text fontWeight={550}>
                            {Math.round(progress)}%
                        </Text>
                    </HStack>
                    <IconButton
                        icon={<CloseIcon />}
                        position="absolute"
                        top="50%"
                        right="0"
                        transform="translateY(-50%)"
                        onClick={() => navigate('/')}
                        variant="ghost"
                        aria-label="Close"
                    />
                </Box>
                <StepComponent
                    value={formData[stepKey] || ''}
                    onChange={handleOptionChange}
                />
            </Box>
            {/*<Box*/}
            {/*    position="relative"*/}
            {/*    width="100%"*/}
            {/*    height={'13vh'}*/}
            {/*    bg="white"*/}
            {/*    textAlign="center"*/}
            {/*    pt={8}*/}
            {/*    boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"*/}
            {/*>*/}
            {/*    <Button onClick={handleNext}*/}
            {/*            size="lg"*/}
            {/*            width={'15%'}*/}
            {/*            borderRadius={50}*/}
            {/*            bgGradient="linear(to-r, red.400,pink.400)"*/}
            {/*            color={'white'}*/}
            {/*            _hover={{*/}
            {/*                bgGradient: 'linear(to-r, red.400,pink.400)',*/}
            {/*                boxShadow: 'xl',*/}
            {/*            }}>*/}
            {/*        Continue*/}
            {/*    </Button>*/}
            {/*</Box>*/}
        </Box>
    );
};

export default MultiStepForm;


