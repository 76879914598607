
export const initializeUser = (userID) => {
    localStorage.setItem('userID', userID);
}

export const getUserID = () => {
    return localStorage.getItem('userID');
}

export const removeUserID = () => {
    localStorage.removeItem('userID');
}

export const printLocalStorage = () => {
    console.log("Printing local storage");
    Object.keys(localStorage).forEach((key) => {
        console.log(`${key}: ${localStorage.getItem(key)}`);
    });
    console.log("End of local storage");
}

export const addDocumentInStorage = (document) => {
    const documents = JSON.parse(localStorage.getItem("documents"));
    documents.savedDocuments.push(document);
    localStorage.setItem("documents", JSON.stringify(documents));
};

// export const removeDocumentInStorage = (id) => {
//     const documents = JSON.parse(localStorage.getItem("documents"));
//     if (documents.savedDocuments.length !== 0) {
//         const documents = JSON.parse(localStorage.getItem("documents"));
//         const updatedDocuments = documents.savedDocuments.filter((doc) => doc.id !== id);
//         documents.savedDocuments = updatedDocuments;
//         localStorage.setItem("documents", JSON.stringify(documents));
//     } else {
//         console.log("No documents to remove.");
//     }
// };

export const getDocumentInStorage = (id) => {
    const documents = JSON.parse(localStorage.getItem("documents"));
    const document = documents.savedDocuments.filter((doc) => doc.id === id)[0];
    return document;
}

export const getDocumentsInStorage = () => {
    const documents = JSON.parse(localStorage.getItem("documents"));
    return documents.savedDocuments;
}

export const getDocumentsInStorageGeneration = () => {
    const documents = JSON.parse(localStorage.getItem("generations"));
    return documents.savedGenerations;
}

// export const setHighlightsInStorage = (documentID, newHighlights) => {
//     const documents = JSON.parse(localStorage.getItem("documents"));
//     const documentIndex = documents.savedDocuments.findIndex(doc => doc.id === documentID);
//     if (documentIndex !== -1) {
//         documents.savedDocuments[documentIndex].highlights = [...newHighlights];
//     }
//     localStorage.setItem("documents", JSON.stringify(documents));
// }
//
// export const setMessagesInStorage = (documentID, newMessages) => {
//     const documents = JSON.parse(localStorage.getItem("documents"));
//     const documentIndex = documents.savedDocuments.findIndex(doc => doc.id === documentID);
//     if (documentIndex !== -1) {
//         documents.savedDocuments[documentIndex].messages = [...newMessages];
//     }
//     localStorage.setItem("documents", JSON.stringify(documents));
// }












